import {
    Row,
    Col,
    Divider
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faCar, 
    faBath, faVectorSquare,
    faBuilding,
    faMountain,
} from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image'
// import _ from 'lodash';

import styles from './css/recommend.module.scss';
import React from 'react';


const getIcon = (iconName, value, t, locale, id) => {
    if(iconName === null) {
        return undefined;
    }

    let iconCom = undefined;
    let unit = undefined;
    let prefix = undefined;
    let transValue = value !== null? value: '-';
    let span = 8;
    
    if(iconName === 'Bedroom') {
        iconCom = faBed
        unit = t('bedroom', { ns: 'common' });
    } else if(iconName === 'Bathroom') {
        iconCom = faBath;
        unit = t('bathroom', { ns: 'common' });
    } else if(iconName === 'storeys') {
        iconCom = faBuilding;
        unit = t('floor', { ns: 'common' });
    } else if(iconName === 'property_Size') {
        iconCom = faVectorSquare;
        // prefix = `${t('P_size')}`;
        unit = t('square meter', { ns: 'common' });
    } else if(iconName === 'Land_Size') {
        iconCom = faMountain;
        unit = t('square wa', { ns: 'common' });
    } else if(iconName === 'property_Parking') {
        iconCom = faCar;
        unit = t('Parking', { ns: 'common' }); 
    } else if(iconName === 'Land_Rai,Land_Nga,Land_Sqw') {
        iconCom = undefined; //faVectorSquare;
        const valueList = transValue.split(',');
        // prefix = `${t('ไร่ งาน ตร.ว.')}`;
        transValue = [
            <span className={styles.cardDetailNumber} >{`${valueList[0] !== null? valueList[0]: '-'}`}</span>,
            <span>{`${t('rai', { ns: 'common' })}, `}</span>,
            <span className={styles.cardDetailNumber} >{`${valueList[1] !== null? valueList[1]: '-'}`}</span>,
            <span>{`${t('ngan', { ns: 'common' })}, `}</span>,
            <span className={styles.cardDetailNumber} >{`${valueList[2] !== null? valueList[2]: '-'}`}</span>,
            <span>{t('square wa', { ns: 'common' })}</span>  
        ]
        // `${valueList[0]} ${t('ไร่')}, ${valueList[1]} ${t('งาน')}, ${valueList[2]} ${t('ตร.ว.')}`
        span = 16;
    } else if(iconName === 'Depth') {
        iconCom = undefined; // faVectorSquare;
        prefix = `${t('depth', { ns: 'common' })} `;
        unit = t('m.');
    } else if(iconName === 'vacant land') {
        span=0; // Remove because no data on our DB
        iconCom = undefined; 
        prefix = `${t('vacant', { ns: 'common' })} `;
    } else if(iconName === 'Frontage') {
        iconCom = undefined; 
        prefix = `${t('width', { ns: 'common' })} `;
        unit = t('m.');
    }  else if(iconName === 'color') {
        span=0;
        iconCom = undefined; //faVectorSquare;
        prefix = `${t('color', { ns: 'common' })} `;
    } else if(iconName === 'foreignerQuota') {
        span=0;
        iconCom = undefined; //faVectorSquare;
        prefix = `${t('quota', { ns: 'common' })} `;
    } else if(iconName === 'property_Floor') {
        console.log("locale: ", locale);
        if(locale === 'en'){
            iconCom = faBuilding;
            unit = `${t('floor-unit', { ns: 'common' })}`;
        } else {
            iconCom = faBuilding;
            prefix = `${t('floor-unit', { ns: 'common' }) }`;
            unit = ' ';
        }
    } 
    return (
        <Col span={span} style={{fontSize: '12px'}} key={`card-detail-${id}-${iconName}`}>
            {
                // iconCom ? <FontAwesomeIcon icon={iconCom} className={styles.detailIcon}/> : undefined
            }
            { 
                unit ? [
                    <span style={{paddingRight: '4px'}}>{`${prefix ?? ''}`}</span>,
                    <span className={styles.cardDetailNumber} >{`${value ? value: '-'}`}</span>,
                    <span>{`${unit}`}</span>
                ]
                : [
                    <span style={{paddingRight: '4px'}}>{`${prefix ?? ''}`}</span>,
                    ...transValue
                ]
            }
        </Col>  
    )
}

const getManualIcon = (iconName, value, t) => {
    if(iconName === null) {
        return undefined;
    }

    let iconCom = undefined;
    let unit = undefined;
    let prefix = undefined;
    
    if(iconName === 'Bedroom') {
        iconCom = <div className={styles.iconsStyle}>
                <Image src="/assets/cardDetails/1_Bed.jpg" 
                width={20} height={20} priority />
            </div>
        unit = t('นอน');
    } else if(iconName === 'Bathroom') {
        iconCom = <div style={{top: '10px'}}>
                <Image src="/assets/cardDetails/2_bath.jpg" 
                width={20} height={20} priority />
            </div>;
        unit = t('น้ำ');
    } else if(iconName === 'storeys') {
        iconCom = <Image src="/assets/cardDetails/4_Building.jpg" 
        width={20} height={20} priority className={styles.iconsStyle}/>;
        unit = t('ชั้น');
    } else if(iconName === 'property_Size') {
        iconCom = <Image src="/assets/cardDetails/3_Size1.jpg" 
            width={20} height={20} priority className={styles.iconsStyle}/>;
        // prefix = `${t('P_size')}`;
        unit = t('ตร.ม');
    } else if(iconName === 'Land_Size') {
        iconCom = undefined;
        unit = t('ตร.ว.');
    } else if(iconName === 'property_Parking') {
        iconCom = <Image src="/assets/cardDetails/5_Car.jpg" 
            width={20} height={20} priority className={styles.iconsStyle}/>;
        unit = t('คัน'); 
    } else if(iconName === 'Land_Rai,Land_Nga,Land_Sqw') {
        iconCom = undefined;
        prefix = `${t('ไร่ งาน ตรม.')}`;
    } else if(iconName === 'Depth') {
        iconCom = undefined;
        prefix = `${t('ลึก')} `;
    } else if(iconName === 'vacant land') {
        iconCom = undefined;
        prefix = `${t('vacant')}`;
    } else if(iconName === 'Frontage') {
        iconCom = undefined;
        prefix = `${t('กว้าง')}`;
    }  else if(iconName === 'color') {
        iconCom = undefined;
        prefix = `${t('color')}`;
    } else if(iconName === 'foreignerQuota') {
        iconCom = undefined;
    } else if(iconName === 'property_Floor') {
        // iconCom = <Image src="/assets/cardDetails/4_Building.jpg" 
        //     width={20} height={20} priority />;
        iconCom =    <FontAwesomeIcon icon={faBuilding} className={styles.detailIcon}/>
        prefix = `${t('ชั้น') }`;
    } 
    return (
        <Col span={8}>
            <Row>
                {/* <Col>{ iconCom }</Col> */}
                <Col>
                { 
                    unit ? [
                        <span>{`${prefix ?? ''}`}</span>,
                        <span>{`${value !== null? value: '-'}`}</span>,
                        <span>{`${unit}`}</span>
                    ]
                    : `${prefix ?? ''}${value !== null? value: '-'}`
                }
                </Col>
            </Row>
        </Col>  
    )
}

export const CardDetail = (props) => {

    const { 
        action,
        property,
        carddetail, t,
        locale,
        project
    } = props;

    const {
        bedroom,
        bathroom,
        property_size,
        floor,
        parking,
    } = property;
    
    const isSell =  action === t('ขาย');

    if(carddetail === null) {
        return <Row></Row>;
    }

    const detail = carddetail[isSell? 'sell': 'rent'];
    // console.log('CardDetail: ', detail);

    const uniqIcon = [];
    detail.columns.map((a) => {
        uniqIcon.push(a);
    })
    // console.log('uniq: ', _.uniq(uniqIcon));

    const detail_id = `${project.id}-${property.id}`

    return (
        <Row>
        <Col span={24} >
            <Divider className={styles.divider} />
        </Col>
        {
            detail.columns.map((column, index) => {
                const value = detail.values[index];
                if(column !== 'storeys') {
                    return getIcon(column, value, t, locale, detail_id);
                }
            })
        }
    </Row>
    )
}