import React, { useEffect, useState } from 'react';
import { Button, List, Skeleton, Typography } from 'antd';
import { formatDistance } from '../../utils/utils'
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface INearbyLocationTabsProps {
    data: any
    locale: string
}
const nearbyLocationTabs = ({
    data,
    locale,
}: INearbyLocationTabsProps) => {
    const { t } = useTranslation();
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>([]);
    const [count, setCount] = useState(data.length > 8 ? 8 : data.length);

    useEffect(() => {
        const newData = []
        for (let index = 0; index < count; index++) {
            newData.push(data[index]);
        }
        setInitLoading(false);
        setList(newData);
    }, []);

    const loadMoreData = (count) => {
        setList(
            data.concat([...new Array(count)].map(() => ({ loading: true }))),
        );
        const newData = []
        for (let index = 0; index < count; index++) {
            newData.push(data[index]);
        }
        setCount(count);
        setList(newData);
        setLoading(false);
    }

    const onLoadMore = () => {
        setLoading(true);
        loadMoreData(data.length)
    };
    

    const loadMore =
    !initLoading && !loading ? (
        <div
            style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
            }}
        >
            <Button onClick={onLoadMore}>{t('nearbyLocation.loadMore')}</Button>
        </div>
    ) : null;

    return (
        <List
            size="small"
            dataSource={list}
            loading={initLoading}
            loadMore={count < data.length ? loadMore : <></>}
            renderItem={(item) => (
                <List.Item style={{
                    columnGap: 24,
                    flexWrap: 'nowrap',
                }}>
                    <Skeleton avatar title={false} loading={item['loading']} active>
                        <Text>
                            {`${item[`Name_${locale.toUpperCase()}`]} ${item[`branch_Name_${locale.toUpperCase()}`] ? item[`branch_Name_${locale.toUpperCase()}`] : ''}`}
                        </Text>
                        <Text style={{ whiteSpace: 'nowrap'}}>
                            {`${item[`distance`] ? formatDistance(Number(item[`distance`]), locale) : ''}`}
                        </Text>
                    </Skeleton>
                </List.Item>
            )}
        />
    )
}

export default nearbyLocationTabs