import {
    Row,
    Col
} from 'antd';
import Link from 'next/link';

import styles from './linkRecommended.module.scss';

export enum LINKHITS_TYPES {
    airport, 
    hit, 
    garden, 
    hospital,
    mrt, 
    mall,
    university,
    road,
    province
}

export interface IGenerateLinkHitsProps {
    header: any;
    t: any;
    locale: string;
    list: any;
    action: string;
    category: string;
    type: LINKHITS_TYPES;
}

const genLink = (t, action, category, type, d, locale) => {
   if(type === LINKHITS_TYPES.province) {
       return `/${action}/${category}/${d[`PROVINCE_NAME_${locale}`]}/${d[`Name_${locale}`]}`;
   } else {

        const branchName = d[`location_branch_Name_${locale}`];
        if(branchName) {
            return `/${action}/${category}/${d[`location_type_Name_${locale}`]}/${d[`location_Name_${locale}`]}/${d[`location_branch_Name_${locale}`]}`; 
        } else {
            return `/${action}/${category}/${d[`location_type_Name_${locale}`]}/${d[`location_Name_${locale}`]}`; 
        }
   } 
}

const prefixThai = {
    [LINKHITS_TYPES.province]: 'เขต',
    [LINKHITS_TYPES.mrt]: '',
    [LINKHITS_TYPES.mall]: 'ใกล้',
    [LINKHITS_TYPES.university]: 'ใกล้ ม.',
    [LINKHITS_TYPES.road]: '',
    [LINKHITS_TYPES.hospital]: 'ใกล้ รพ.',
    [LINKHITS_TYPES.garden]: 'ใกล้สวน',
    [LINKHITS_TYPES.hit]: 'ใกล้'
}

const replaceTextToshort = (rtext: string) => {
    let text = rtext;
    text = text.replace('ถนน', 'ถ.')
    text = text.replace('มหาวิทยาลัย', '')
    text = text.replace('ห้างสรรพสินค้า', 'ห้าง')
    text = text.replace('โรงพยาบาล', '')
    text = text.replace('สวนสาธารณะ', '')
    text = text.replace('สถานที่ยอดนิยม', '')
    text = text.replace('Popular Area', '')
    return text;
}

const formatText = (d, type, locale, category) => {
    const Language = locale.toUpperCase(); 
    const spaceText = locale === "en" ? " " : "";
    let text = "";
    if(locale === 'en') {
        if(type === LINKHITS_TYPES.university){
            text = `${category}${spaceText}`
            text += `near`
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`
        } else if(type === LINKHITS_TYPES.mrt) {
            text = `${category}${spaceText}`
            text += `near`
            text += `${spaceText}${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
        } else if(type === LINKHITS_TYPES.road && d[`location_branch_Name_${Language}`]) {
            text = `${category}${spaceText}`
            const location_Name = d[`location_Name_${Language}`];
            const location_branch_Name = d[`location_branch_Name_${Language}`];

            if(location_Name && !location_branch_Name) {
                text += `${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            } else {
                text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`
                text += `${spaceText}Alley`;
            }
        } else if(type === LINKHITS_TYPES.hospital && d[`location_branch_Name_${Language}`]) {
            text = `${category}${spaceText}`
            text += `near`;
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`
        } else if(type === LINKHITS_TYPES.province) {
            text = `${category}${spaceText}`
            text += `${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
        } else if(type === LINKHITS_TYPES.hit) {
            text = `${category}${spaceText}`
            text += `near`
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`
        } else {
            text = `${category}${spaceText}`
            text += `near`
            text += `${spaceText}${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
        }
    } else if(locale === 'cn') {
        if(type === LINKHITS_TYPES.province) {
            text = `${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
            text += `${category}`
        } else if(type === LINKHITS_TYPES.road) {
            text = `${category}`
            text += '近'
            // text += `${spaceText}${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
            // text += `${spaceText}${d[`location_branch_Name_${Language}`] ? '巷' : d[`location_type_Name_${Language}`]}`

            const location_Name = d[`location_Name_${Language}`];
            const location_branch_Name = d[`location_branch_Name_${Language}`];
            if(location_Name && !location_branch_Name) {
                text += `${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            } else {
                text += `${spaceText}${d[`location_branch_Name_${Language}`] ? '巷' + d[`location_branch_Name_${Language}`] : ''}`
            }

        } else if(type === LINKHITS_TYPES.mrt){
            text = `${category}`
            text += '近'
            text += `${spaceText}${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
            text += `${spaceText}站`
        } else if(type === LINKHITS_TYPES.university) {
            text = `${category}`
            text += '近'
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            text += `${spaceText}大学`
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`
        } else if(type === LINKHITS_TYPES.hit) {
            text = `${category}`
            text += '近'
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`
        } else if(type === LINKHITS_TYPES.mall) {
            text = `${category}`
            text += '近'
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
        } else if(type === LINKHITS_TYPES.hospital) {
            text = `${category}`
            text += '近'
            text += `${spaceText}${d[`location_branch_Name_${Language}`] ? d[`location_branch_Name_${Language}`]: ''}`
            text += `${spaceText}${d[`location_Name_${Language}`] ? d[`location_Name_${Language}`]: ''}`;
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
        } else {
            text = `${category}`
            text += '近'
            text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
            text += `${spaceText}${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
        }
    } else {
        // Thai
        if(type === LINKHITS_TYPES.road) {
            text = `${category}${locale === 'th' ? prefixThai[type] ? prefixThai[type]: '' : ''}`
           
            const location_Name = d[`location_Name_${Language}`];
            const location_branch_Name = d[`location_branch_Name_${Language}`];

            if(location_Name && !location_branch_Name) {
                text += `${d[`location_type_Name_${Language}`] ? ` ${d[`location_type_Name_${Language}`]}`: ''}`
                text += `${location_Name ? `${location_Name}`: ''}`;
            } else {
                text += `${location_branch_Name ? ` ซ.${location_branch_Name}`: ''}`
            }
            
            text = replaceTextToshort(text); 
        } else {
            text = `${category}${locale === 'th' ? prefixThai[type] ? prefixThai[type]: '' : ''}`
            text += `${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
            text += `${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
            
            text = replaceTextToshort(text);
        }
    }

    return text;
}

const GenerateLinkHits = ({header, t, locale, list, action, category, type}: IGenerateLinkHitsProps ) => {
    const Language = locale.toUpperCase(); 
    const spaceText = locale === "en" ? " " : "";
    // console.log('GenerateLinkHits: ', header , list)
    // console.log('type: ', type)
    return (
        <Row>
            {
                list && list.length > 0 ?
                <Col span={24} key={`${header}`}>
                    <Row>
                        <Col span={24} className={styles.itemHeader}>
                            {header}
                        </Col>      
                    </Row>
                    <Row>
                        <Col span={24}>
                            {
                                list && list.map((d) => {
                                    const mlink = genLink(t, action, category, type, d, Language); 
                                    // let text = `${category}${spaceText}${locale === 'th' ? prefixThai[type] ? prefixThai[type]: '' : ''}`
                                    // text += `${spaceText}${d[`location_type_Name_${Language}`] ? d[`location_type_Name_${Language}`]: ''}`
                                    // text += `${spaceText}${d[`Name_${Language}`] ? d[`Name_${Language}`]: ''}`;
                                    let text = formatText(d, type, locale, category);
                                    if(type === LINKHITS_TYPES.road){
                                        // console.log('xxxx2: ', d, locale)
                                    }
                                    text = replaceTextToshort(text);

                                    return (
                                        <span className={styles.item} key={mlink} >
                                            <Link legacyBehavior href={mlink}>{`${text}`}</Link>
                                        </span>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Col>
                : undefined
            }
        </Row>
    )
} 
export const LinkRecommended = (props) => {

    const { t, 
        locale,  
        recommendList,
        action,
        category
    } = props;


    const {
        airport, 
        hit, 
        garden, 
        hospital,
        mrt, 
        mall,
        university,
        road,
        province
    } = recommendList || {};

    // console.log('LinkRecommended: ', province, mrt)
    
    return (
        <Row className={styles.container}>
            {
                (province !== null &&  province !== undefined 
                && province.status !== 200)
                && mrt.status !== 200
                && mall.status !== 200
                && university && university.status !== 200
                && road && road.status !== 200
                && hospital && hospital.status !== 200
                && garden && garden.status !== 200
                && airport && airport.status !== 200
                && hit && hit.status !== 200 ? undefined
                :
                <Col span={24}>
                    <Row className={styles.header} >{`${t('ลิ้งแนะนำ')}`}</Row>
                </Col>
            }
            <Col span={24}>
                <GenerateLinkHits 
                    header={t('District nearby')} 
                    t={t} 
                    locale={locale}
                    list={province === null? undefined: province ? province.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.province }
                />
                 <GenerateLinkHits 
                    header={t('Near Train Station')} 
                    t={t} 
                    locale={locale}
                    list={mrt ? mrt.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.mrt }
                /> 
                <GenerateLinkHits 
                    header={t('Near Mall')} 
                    t={t} 
                    locale={locale}
                    list={mall ? mall.data : undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.mall }
                /> 
                <GenerateLinkHits 
                    header={t('Near University')} 
                    t={t} 
                    locale={locale}
                    list={university? university.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.university }
                />
                <GenerateLinkHits 
                    header={t('Near Road')} 
                    t={t} 
                    locale={locale}
                    list={road? road.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.road }
                /> 
                <GenerateLinkHits 
                    header={t('Near Hospital')} 
                    t={t} 
                    locale={locale}
                    list={hospital ? hospital.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.hospital }
                />
                <GenerateLinkHits 
                    header={t('Near Park')} 
                    t={t} 
                    locale={locale}
                    list={garden ? garden.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.garden }
                />
                <GenerateLinkHits 
                    header={t('Near airport')} 
                    t={t} 
                    locale={locale}
                    list={airport ? airport.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.airport }
                /> 
                <GenerateLinkHits 
                    header={t('Near Popular area')} 
                    t={t} 
                    locale={locale}
                    list={hit? hit.data: undefined}
                    action={action}
                    category={category}
                    type= { LINKHITS_TYPES.hit }
                />   
            </Col>
        </Row>
    )
}