import {
    Row,
    Col,
    Typography,
    Tabs
} from 'antd';
// import { useTranslation } from 'react-i18next';
import { 
    getFieldWithlocale,
    formatPrice,
    getImageUrl,
    getAddressFromProperty,
    getUser
} from '../../utils/utils'
// import ImageGallery from 'react-image-gallery';
// import { Parser as HtmlToReactParser} from 'html-to-react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LineIcon, LineShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import {
    Header2
} from '../myTheme';
import {
    Information,
    OwnerInformation
} from '../detail/sections'

// import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

import {
    GallaryView
} from '../gallaryview';
import { useEffect, useMemo, useState } from 'react';
import { getSearchList, handleViewActivity } from '../../utils/requests';

import styles from './scss/propertydetailsection.module.scss';

import dynamic from 'next/dynamic'
import { DetailPageLayout } from '../detailPageLayout';
import YouTube, { YouTubeProps } from 'react-youtube';
import moment from 'moment';
// import { CheckSquareOutlined } from '@ant-design/icons';
import NearbyLocationList from '../nearbyLocationList';
import getVideoId from 'get-video-id';

const LeafletMap: any = dynamic(() => import('../LeafletMap'), { ssr: false });
const { Text } = Typography;

type PropertyDetailData = {
   property_Name: string;
   property_Sell: string;
   images: any[];
   property_PriceSq: string; 
   Additional: string;
   Price_Rent: string;
   Provision: any[];
   House_Facilities: any[];
   Apartment_Service: any[];
   Specail: any[];
   detail_ID: any[];
   first_name_TH: string;
   last_name_TH: string;
   mobile: string;
   agent: string;
   property_ID: string;
   property_Date: string;
    type: string;
    price_lease: string;
    price_mortgage: string;
    price_redemption: string;
    unit: any;
    Youtube: string;
    property_Update: string;
    Nearby_Location: any[];
    youtube_url: string;
}
type PropertyDetailProps = {
    locale: string;
    data: PropertyDetailData;
    t: any;
    isMobileFilterOpen: any;
    onMobileSearchOk: any
    onMobileSearchClose: any;
    breadcrumb: any;
    action: string;
    provinceTable: any;
    actionsTable: any;
    categoryTable: any;
    locationTypeTable: any;
}

const Option = (header: string, data: any[], locale: any, isIcon?: boolean) => {
    if(data
        && data.length > 0) {
        return (
            <Row>
                <Col span={24}>
                    <Row >
                        <Header2>{header}</Header2>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        {
                            data?.map((p) => {
                                return (
                                    <Col xs={24} md={12} style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
                                        {isIcon && (<div className={styles.facilitieIcon} ></div>)}
                                        { 
                                            getFieldWithlocale(p,'Name', locale) 
                                            || getFieldWithlocale(p,'name', locale)
                                            || getFieldWithlocale(p,'specail_Name', locale)
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Col>
            </Row>
        )
    } else {
        return <></>
    }

}

const opts: YouTubeProps['opts'] = {
    width: '100%',
    height: '100%',
    // playerVars: {
    //     autoplay: 1
    // }
}

const propertyUnitDetail = (category: string, data: any[], locale: any, t) => {
    console.log("🚀 ~ file: index.tsx:109 ~ propertyUnitDetail ~ locale:", locale)
    return (
        <> 
            {data?.length > 0 && (
                <Col span={24}>
                    <Header2>{t('propertyUnitType')}</Header2>
                </Col>
            )}
            {data?.map((item, index) => {
                // if(!['HOthers', 'Otherspro'].includes(item.unit_type)){
                    return (
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                {item.unit_image && (
                                    <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 1 }}>
                                        <img src={`${process.env.NEXT_PUBLIC_SELLER_SERVICE_API_BASE}/properties/${item.property_id}/unit/${item.id}/${item.unit_image}`} width="100%" />
                                    </Col>
                                )}
                                <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 2 }}>
                                    <Row>
                                        <Col xs={24} md={8}>
                                        {item.unit_name ? <p>{t('propertyUnitName')} <span className='highlight-text'>{item.unit_name}</span> {' '}</p> : null}
                                        {item.unit_room_type ? <p>{t('propertyUnitType')} <span className='highlight-text'>{item.unit_room_type}</span> {' '}</p> : null}
                                        {item.unit_size ? <p>{t('propertyUnitSize')} <span className='highlight-text'>{item.unit_size}</span> {t('square meter')} {' '}</p> : null}
                                        </Col>
                                        <Col xs={24} md={8}>
                                        {item.unit_price_per_month ? <p>{t('propertyUnitPricePerMonth')} <span className='highlight-text'>{formatPrice(item.unit_price_per_month, locale.toLowerCase())}</span> {' '}</p> : null}
                                        {item.unit_price_per_day ? <p>{t('propertyUnitPricePerYear')} <span className='highlight-text'>{formatPrice(item.unit_price_per_day, locale.toLowerCase())}</span> {' '}</p> : null}
                                        {item.unit_price_temp ? <p>{t('propertyUnitPriceTemp')} <span className='highlight-text'>{formatPrice(item.unit_price_temp, locale.toLowerCase())}</span> {' '}</p> : null}
                                        </Col>
                                        <Col xs={24} md={8}>
                                        {item.unit_no_of_room ? <p>{t('propertyUnitCount')} <span className='highlight-text'>{item.unit_no_of_room}</span> {t('unit')} {' '}</p> : null}
                                        {item.unit_available ? <p>{t('propertyUnitStatus')} <span className='highlight-text'>
                                            {item.unit_available === '0' ? 
                                                    t('full') 
                                                : 
                                                        `${locale.toLowerCase() !== 'en' ? t('propertyUnitAvailable') : ''} ${item.unit_available} ${locale.toLowerCase() === 'en' ? t('propertyUnitAvailable') : ''}${locale.toLowerCase() === 'cn' ? t('propertyUnitAvailableUnit') : ''}` }
                                                        </span></p> 
                                                    : 
                                                    null
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    )
                // }
            })}
            <Col span={24}>
                {data?.filter((item) => ['HOthers', 'Otherspro'].includes(item.unit_Type)).map((item, index) => {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: item[`unit_Others_${locale}`] ? item[`unit_Others_${locale}`] : item[`unit_Others_EN`]}} />
                    )
                })}
            </Col>
        </>
    )
}
export const PropertyDetailSection = ({ data, locale, t, 
    isMobileFilterOpen,
    onMobileSearchOk,
    onMobileSearchClose,
    breadcrumb,
    action,
    actionsTable,
    categoryTable,
    provinceTable,
    locationTypeTable,
}: PropertyDetailProps) => {
    
    const images = data.images?.map((m) => {
        const urls = getImageUrl(m.property_ID, m.gallery_Images);
        
        return {
            original: urls.full,
            thumbnail: urls.thumb,
            originalClass: 'full-image-class'
        }
    }) || [];
    const address =  getAddressFromProperty(data, locale);
    // console.log('Provision: ', data.Provision)
    console.log('Property data: ', data, locale)

    // const nearbyPropertyList = nearbyProperty 
    //     && nearbyProperty.data 
    //     && nearbyProperty.data.items.length > 0 
    //     && nearbyProperty.data.items
    
    const property_lat = (data as any).project_Latitude;
    const property_lng = (data as any).project_Longitude;
    const [nearbyPropertyList, setNearbyPropertyList] = useState([]);

    useEffect(()=> {
        const fetchNearbyData = async() => {
            let sell, rent;
            if(action === 'เช่า' || action === 'ให้เช่า') {
                sell = false;
                rent = true;
            } else {
                sell = true;
                rent = false;
            } 

            const searchParams = {
                "page": 1,
                "limit": 5,
                "sell": sell,
                "rent": rent,
                "propertystatus": true,
                "categoryid": (data as any).category_ID, // _.get(categoryObj, 'data[0].category_ID'),
                "lat": (data as any).project_Latitude,
                "lng": (data as any).project_Longitude,
            }
            const nearbyProperty = await getSearchList(searchParams)
            const nearbyPropertyList = nearbyProperty 
                && nearbyProperty.data 
                && nearbyProperty.data.items.length > 0 
                && nearbyProperty.data.items || [];

            setNearbyPropertyList(nearbyPropertyList)
        }

        fetchNearbyData();

    }, [])

    useEffect(() => {
        if(!data.property_ID) return
        const addToViewActivity = async() => {
            const user = await getUser();
            if(user) {
                handleViewActivity("property", Number(data.property_ID), user.id);
            }
        }
        addToViewActivity();
    }, [data.property_ID])  

    const nearbyList = useMemo(() => {
        if(data?.Nearby_Location) { 
            return Object.entries(data?.Nearby_Location).map(([key, value], index) => {
                return {
                    key: index.toString(),
                    label: value[0][`location_type_name_${locale.toUpperCase()}`],
                    children: <NearbyLocationList data={value} locale={locale} />
                }
            })
        } else {
           return [];
        }
    }, [data])

    return (
        <DetailPageLayout
            t = {t}
            breadcrumb={breadcrumb}
            nearbyPropertyList={nearbyPropertyList}
            isMobileFilterOpen={isMobileFilterOpen}
            onMobileSearchOk={onMobileSearchOk}
            onMobileSearchClose={onMobileSearchOk}
            gallerySection={ <GallaryView items={images} /> }
            header= {[
                <Row justify="space-between" align="top">
                    <Col xs={24} sm={24}  md={24}>
                        <h1>{locale === 'th' ? data.property_Name : data[`property_name_${locale}`]}</h1>
                    </Col>
                </Row>,
                <Row justify="space-between" align="top">
                    <Col xs={24} sm={24}  md={24}>
                        <h2>
                            {address || getFieldWithlocale(data,'PROVINCE_NAME', locale)}
                        </h2>
                    </Col>
                </Row>,
                <Row>
                    <Col xs={24} sm={24}  md={24}>
                        <Row gutter={[8, 8]}>
                            {data.property_Sell && data.property_Sell !== '0' && data.property_Sell !== '0.00' && (
                            <Col xs={24} className={styles['col-title']}>
                                <div className={[styles.titlePrice, styles['titlePrice-pricePerMeter']].join(' ')} style={{ background: '#f4762a'}}>
                                    <h1 style={{ color: 'white', marginBottom: 0 }}>
                                        {`${t('sell')} ${formatPrice(data.property_Sell, locale)}`}
                                    </h1>
                                    {['Condominium', 'Land'].includes(data.type) && (
                                        <h1 className={styles.pricePerMeter} style={{ color: 'white', marginBottom: 0}}>
                                            {data.type === 'Condominium' && `${formatPrice(data.property_PriceSq, locale)} / ${t('square meter')}`}
                                            {data.type === 'Land' && `${formatPrice(data.property_PriceSq, locale)} / ${t('square wa')}`}
                                        </h1>
                                    )}
                                </div>
                            </Col>)}
                            {data.Price_Rent && data.Price_Rent !== '0' && data.Price_Rent !== '0.00' && (
                            <Col xs={24} className={styles['col-title']}>
                                <div className={styles.titlePrice} style={{ background: '#77933c'}}>
                                    <h1 style={{ color: 'white', marginBottom: 0}}>
                                        {`${t('rent')} ${formatPrice(data.Price_Rent, locale)}`}
                                    </h1>
                                </div>
                            </Col>)}
                            {data.price_lease && data.price_lease !== '0' && data.price_lease !== '0.00' && (
                            <Col xs={24} className={styles['col-title']}>
                                <div className={styles.titlePrice} style={{ background: '#93ccdd'}}>
                                    <h1 style={{ color: 'white', marginBottom: 0}}>
                                        {`${t('lease')} ${formatPrice(data.price_lease, locale)}`}
                                    </h1>
                                </div>
                            </Col>)}
                            {data.price_mortgage && data.price_mortgage !== '0' && data.price_mortgage !== '0.00' && (
                            <Col xs={24} className={styles['col-title']}>
                                <div className={styles.titlePrice} style={{ background: '#c3d79c'}}>
                                    <h1 style={{ color: 'white', marginBottom: 0}}>
                                        {`${t('mortgage')} ${formatPrice(data.price_mortgage, locale)}`}
                                    </h1>
                                </div>
                            </Col>)}
                            {data.price_redemption && data.price_redemption !== '0' && data.price_redemption !== '0.00' && (
                            <Col xs={24} className={styles['col-title']}>
                                <div className={styles.titlePrice} style={{ background: '#95b3d7'}}>
                                    <h1 style={{ color: 'white', marginBottom: 0 }}>
                                        {`${t('redemption')} ${formatPrice(data.price_redemption, locale)}`}
                                    </h1>
                                </div>
                            </Col>)}
                        </Row>
                    </Col>
                </Row>,
                <Row justify="start" style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Col xs={24} sm={2}>
                        <Text style={{ fontSize: 12, marginRight: 15, }}>Update: {moment(data?.property_Update).format('DD.MM.YYYY')}</Text>
                    </Col>
                    <Col>
						<div>
							<Text style={{ fontSize: 12, marginRight: 5 }}>Share:</Text>
							<FacebookShareButton url={window.location.href}>
								<FacebookIcon size={28} round />
							</FacebookShareButton>
							<FacebookMessengerShareButton url={window.location.href} appId={'2852141398421242'} style={{ marginLeft: 5 }}>
								<FacebookMessengerIcon size={28} round />
							</FacebookMessengerShareButton>
							<LineShareButton url={window.location.href} style={{ marginLeft: 5 }}>
								<LineIcon size={28} round />
							</LineShareButton>
							<WhatsappShareButton url={window.location.href} style={{ marginLeft: 5 }}>
								<WhatsappIcon size={28} round />
							</WhatsappShareButton>
							<TwitterShareButton url={window.location.href} style={{ marginLeft: 5 }}>
								<TwitterIcon size={28} round />
							</TwitterShareButton>
							<EmailShareButton
								url={window.location.href}
								subject={locale === 'th' ? data.property_Name : data[`property_name_${locale}`]}
								style={{ marginLeft: 5 }}
							>
								<EmailIcon size={28} round />
							</EmailShareButton>
						</div>
                    </Col>
                </Row>
            ]}
            detail= {[
                <Row>
                    <Information data={data} locale={locale} />
                </Row>,
                <>{
                    Option(t('Unit Provision'), data?.Provision, locale, true)
                }</>,
                <>
                    {
                        Option(t('Status'), data?.Specail, locale, true)
                    }
                </>,
                <>
                    {
                       Option(t('project-facilities'), data?.House_Facilities ||  data?.Apartment_Service, locale, true)
                    }
                </>,
                <Row gutter={[16, 24]}>
                    {propertyUnitDetail(data[`category_Name_${locale}`], data.unit, locale, t)}
                </Row>,
                <Row>
                    {data?.Youtube && 
                        <YouTube videoId={getVideoId(data.Youtube).id || data.Youtube} opts={opts} style={{ width: '100%', aspectRatio: '16/9', margin: '16px 0' }}/>
                    }
                </Row>,
                <Row>
                    {data?.youtube_url && 
                        <YouTube videoId={getVideoId(data.youtube_url).id || data.youtube_url} opts={opts} style={{ width: '100%', aspectRatio: '16/9', margin: '16px 0' }}/>
                    }
                </Row>,
                <Row>
                    <Header2>{t('description')}</Header2>
                </Row>,
                <Row>
                    <Col span={24}>
                        {locale.toUpperCase() === 'TH' ? 
                            <div style={{ contain: 'content'}} dangerouslySetInnerHTML={{ __html: data.Additional?.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            :
                            <div style={{ contain: 'content'}} dangerouslySetInnerHTML={{ __html: data[`additional_${locale.toLowerCase()}`]?.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                        }
                    </Col>
                </Row>,
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Row>
                            <Col span={24}> 
                                <Header2>{t('map')}</Header2>
                            </Col>
                            <Col span={24}> 
                                <LeafletMap lat={property_lat} lng={property_lng} label="" />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Row>
                            <Col span={24}>
                                <Header2>{t('nearbyLocation.location')}</Header2>
                            </Col>
                            <Col span={24}>
                                <Tabs defaultActiveKey={'0'} items={nearbyList} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ]}
            ownerInfo={
                <OwnerInformation 
                    firstName={data[`first_name_${locale.toLowerCase()}`]
                        || data[`first_name_${locale.toUpperCase()}`]}
                    lastName={data[`last_name_${locale.toLowerCase()}`]
                        || data[`last_name_${locale.toUpperCase()}`]}
                    agent={data.agent}
                    mobile={data.mobile}
                    // assetId={data.property_ID}
                    userid={(data as any).user_No}
                    lastUpdatedDate={data.property_Date}
                />
            }
            provinceTable = {provinceTable}
            actionsTable ={actionsTable}
            categoryTable ={categoryTable}
            locationTypeTable={locationTypeTable}
            data={data}
        />
    )
}