import { useTranslation } from 'next-i18next'
import styles from './leaveyourrequirement.module.scss'
import { Button, Col, Modal, Row } from 'antd'

export interface SuccessModalProps {
  email?: string
  emailVerified?: boolean
  successId: string
  onToNextClicked?: () => void
  onStayClicked?: () => void
  language: string
}

export const SuccessModal = (props: SuccessModalProps) => {
  const { t } = useTranslation('common')
  const { successId, language } = props

  return (
    <Modal
      centered
      open={!!props.successId}
      className={styles.modalSuccess2}
      footer={
        <Row
          style={{
            marginTop: '1rem',
          }}
          align="middle"
        >
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              type="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => props.onToNextClicked?.()}
            >
              Go to Buyer center
            </Button>
          </Col>
        </Row>
      }
      onClose={() => props.onStayClicked?.()}
      onCancel={() => props.onStayClicked?.()}

      destroyOnClose
    >
      <div className={styles.successCard}>
        {language === 'en' ? (
          <span className={styles.txtYour}>Your</span>
        ) : (
          ''
        )}
        <span className={styles.txtRequirement}>
          "{t('titleRequirement')}"
        </span>
        <span className={styles.txtRecorded}>
          {t('confirmation_1')}.
        </span>
        <span className={styles.txtRequirementId}>
          {t('confirmation_2')} <br />
          <span>{successId}</span>
        </span>
        <span className={styles.txtDetail}>
          {t('confirmation_3')}
        </span>
      </div>
    </Modal>
  )
}
