import {  Row, Col,
    Tag
} from 'antd';

// import { 
//     UserOutlined,
//     DownOutlined
//   } from '@ant-design/icons'

// import Link from 'next/link';
import { useTranslation , i18n} from 'next-i18next'

export const NearlyLinksComponent = () => {

    const { t } = useTranslation("common");

    const list = [
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('ขายคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
      t('โครงการคอนโด'),
    ]
    return (
      <Row style={{paddingTop: '20px', paddingBottom: '20px'}} gutter={[4, 8]}>
        {
          list.map((l, index) => {
            return <Col>
              <Tag color="blue">{l}</Tag>
            </Col>
          })
        }
     </Row>
    )
}