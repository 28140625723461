import { Col, Row } from "antd";
import dynamic from "next/dynamic";
// import { memo } from "react";
import Image from 'next/image';
import { RightOutlined } from '@ant-design/icons'
import Link from "next/link";
import Layout from "antd/lib/layout";
import { isMobile } from "react-device-detect";

const { Footer } = Layout;

const DynamicLinkHits = dynamic(() => import('../components')
  .then(module => module.LinkHits), { ssr: false })
  
  
const MyFooter = ({ t, locale }) => {
    return <Footer className= { isMobile ? "footer-mobile" : "footer" }>
      {
        isMobile? undefined: 
          <DynamicLinkHits t={t} locale={locale} />
      }
      <Row justify="center" gutter={[16,24]} className="container footer-container">
        <Col xs={24} sm={24} md={24} lg={8} xl={8} key={'footer-logo'}> 
          <Row>
            <Image src="/assets/new-logo_s.png" width={160} height={80} alt={'9asset-logo'}/>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} key={'footer-detail'}>
          <Row>
            <Col span={12}>
              <RightOutlined />{t("About us")}
            </Col>
            <Col span={12}><RightOutlined /><Link legacyBehavior href="/web-sitemap">{t("Sitemap")}</Link></Col>
          </Row>
          <Row>
            <Col span={12}><RightOutlined /><Link legacyBehavior href="/privacy-policy">{t("Privacy policy")}</Link></Col>
            <Col span={12}><RightOutlined />{t("Help")}</Col>
          </Row>
          <Row>
            <Col span={12}><RightOutlined /><Link legacyBehavior href="/terms">{t("Term & Condition")}</Link></Col>
            <Col span={12}><RightOutlined />{t("Rate")}</Col>
          </Row>
          <Row>
            <Col span={12}><RightOutlined />{t("Advertise with us")}</Col>
            <Col span={12}><RightOutlined />{t("Contact Us")}</Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} key={'footer-company'}>
          <Row>
            {t("My Address")}
          </Row>
          <Row>
            {t("Tel")}
          </Row>
          <Row>
            {t("Email")}
          </Row>
          <Row>
            {t("Office Hours")}
          </Row>
          <Row>
            <div className='social-icon-container'>
              <a href='https://www.linkedin.com/in/9asset-com/'>
                <svg className="svg-social-icon MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" 
                  focusable="false" aria-hidden="true" viewBox="0 0 24 24" 
                  data-testid="LinkedInIcon"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg>
              </a>
              <a href='https://www.facebook.com/9asset/'>
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-social-icon fa-facebook-f " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
              </a>
              <a href='https://www.youtube.com/@9asset'>
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" className="svg-social-icon fa-youtube " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
              </a>
              <a href='https://twitter.com/9assetC'>
                <svg className="svg-social-icon " focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="XIcon" 
                    tabIndex='-1' title="X"><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></svg>
              </a>
            </div>
          </Row>
        </Col>
        
      </Row>
      <Row className="container copyright"> { `${t("CopyRight")} © 2024 ${t("by")} 9asset.com v${process.env.NEXT_PUBLIC_BUILD_NUMBER || '3.0.0'}` }</Row>
    </Footer>
  }
  
export const MemoizedFooter = MyFooter;
  