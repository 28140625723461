import { Button,  Row, Col,
    Menu,
    Dropdown
} from 'antd';

// import Button from 'antd/es/button';
// import Col from 'antd/es/col';
// import Row from 'antd/es/row';
// import Menu from 'antd/es/menu';
// import Dropdown from 'antd/es/dropdown';

import { 
    UserOutlined,
    DownOutlined
  } from '@ant-design/icons'

import { useTranslation , i18n} from 'next-i18next'

export const FilterComponent = () => {

    const { t } = useTranslation("common");

    const menu = (
        <Menu onClick={() => {}}>
            <Menu.Item key="1" icon={<UserOutlined />}>
                {t('แบบห้อง')}
            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
                {t('ขนาด')}
            </Menu.Item>
            <Menu.Item key="3" icon={<UserOutlined />}>
                {t('ตำแหน่ง')}
            </Menu.Item>
        </Menu>
    )

    return (
      <Row>
        <Col>
          <Dropdown overlay={menu}>
            <Button>
                {t('แบบห้อง')} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col>
          <Dropdown overlay={menu}>
            <Button>
                {t('ขนาด')} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col>
          <Dropdown overlay={menu}>
            <Button>
                {t('ตำแหน่ง')} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
     </Row>
    )
}