import {
    Row,
    Col
} from 'antd';
import Link from 'next/link';
import { useCallback } from 'react';

import styles from './linkHits.module.scss';

const GenerateLinkHits = ({category, t, locale}) => {
    const spaceText = locale === "en" ? " " : "";
    const formatText = useCallback((text1, text2, options?) => {
        if(locale === "cn") {
            const textfilter = ['New Project', 'Second Hand', 'Cheap Price', 'Lumpini', 'Sukhumvit', 'Ratchada', 'Lat Phrao', 'Pattaya', 'Chiang Mai', 'Hua Hin'];
            if(textfilter.includes(text2)){
                return `${t(text2)}${t(text1)}`
            }

            return `${t(text1)}${t(text2)}`
        } else {
            if(options) return `${t(text1)}${t(options)}${t(text2)}`

            return `${t(text1)}${t(text2)}`
        }
    }, [])
    let list = [
        { text: formatText(t('ขาย'), category, spaceText), link: `/${t('ขาย')}/${category}` },
        { text: formatText(t('เช่า'), category, spaceText), link: `/${t('เช่า')}/${category}` },
        { text: formatText(category, 'New Project', spaceText), link: `/${t('โครงการ')}/${category}` },
        // { text: formatText(category, 'Second Hand', spaceText), link: `/${t('ทั้งหมด')}/${category}` },
        // { text: formatText(category, 'Cheap Price', spaceText), link: `/${t('ทั้งหมด')}/${category}` },
        { text: formatText(category, 'near Mass Transit', spaceText), link: `/${t('ขาย')}/${category}/${t('Mass Transit')}` },
        { text: formatText(category, 'Lumpini', spaceText), link: `/${t('ขาย')}/${category}/${t('Public Park')}/${t('Lumpini')}` },
        { text: formatText(category, 'Sukhumvit', spaceText), link: `/${t('ขาย')}/${category}/${t('Road')}/${t('Sukhumvit')}` },
        { text: formatText(category, 'Ratchada', spaceText), link: `/${t('ขาย')}/${category}/${t('Road')}/${t('Ratchada')}` },
        { text: formatText(category, 'Lat Phrao', spaceText), link: `/${t('ขาย')}/${category}/${t('Bangkok')}/${t('Lat Phrao')}` },
        { text: formatText(category, 'Pattaya', spaceText), link: `/${t('ขาย')}/${category}/${t('Beach')}/${t('Pattaya')}` },
        { text: formatText(category, 'Chiang Mai', spaceText), link: `/${t('ขาย')}/${category}/${t('Chiang Mai')}` },
        { text: formatText(category, 'Hua Hin', spaceText), link: `/${t('ขาย')}/${category}/${t('Prachuap Khiri Khan')}/${t('Hua Hin')}` }
    ]

    if([t('land'), t('apartment')].includes(category)) {
        list.splice(2, 1);
    }

    const categoryTitle = locale === 'cn' ? `${t('popular', { ns: 'common' })}${category}`: `${category}${t('popular', { ns: 'common' })}`;
    let linkHitsKey = 0;

    return (
        <Row>
            <Col span={24} className={styles.linkHitsHeader}>{categoryTitle}</Col>
            {
                list.map( l => {
                    return <Col key={linkHitsKey++} span={24} className={styles.linkHitsItem}>
                        <Link legacyBehavior href={l.link} locale={`${locale}`}>
                            {l.text}
                        </Link>
                    </Col>
                })
            }
        </Row>
    )
} 
export const LinkHits = (props) => {

    const { t, locale } = props;

    return (
        <Row className={styles.linkHitsContainer} gutter={[8,16]}>
            <Col xs={12} sm={12} md={4}>
                <GenerateLinkHits t={t} category={t('condo')} locale={locale} />
            </Col>
            <Col xs={12} sm={12} md={4}>
                <GenerateLinkHits t={t} category={t('house')} locale={locale}/>
            </Col>
            <Col xs={12} sm={12} md={4}>
                <GenerateLinkHits t={t} category={t('homeoffice')} locale={locale}/>
            </Col>
            <Col xs={12} sm={12} md={4}>
                <GenerateLinkHits t={t} category={t('townhouse')} locale={locale}/>
            </Col>
            <Col xs={12} sm={12} md={4}>
                <GenerateLinkHits t={t} category={t('land')} locale={locale}/>
            </Col>
            <Col xs={12} sm={12} md={4}>
                <GenerateLinkHits t={t} category={t('apartment')} locale={locale}/>
            </Col>
        </Row>
    )
}