import {
    Row,
    Col,
} from 'antd';
import styles from './detail.module.scss'
import {formatNumber} from '../../utils/utils'

const isHouse = (category_type) => {
    // type is House
    return category_type === 'House';
}

const  RenderDetail = ({data, address, t, locale}) => {
    const RenderLandDetail = () => {
        return (
            <>
                {data && data.type === 'Land' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>
                                        <span>{data.Land_Rai ? `${formatNumber(data.Land_Rai)} ${t('rai')}` : null}</span>
                                        <span>{data.Land_Nga ? `${formatNumber(data.Land_Nga)} ${t('ngan')}` : null}</span>
                                        <span>{data.Land_Sqw ? `${formatNumber(data.Land_Sqw)} ${t('square wa')}` : null}</span>
                                        {/* {data.Land_Size ? `${data.Land_Size} ${t('square wa')}` : '-'} */}
                                    </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Frontage')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Frontage ? `${formatNumber(data.Frontage)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Depth')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Depth ? `${formatNumber(data.Depth)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Color')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data[`land_color_name_${locale.toUpperCase()}`]  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }
    
    const RenderCondoDetail = () => {
        return (
            <>
                {data && data.type === 'Condominium' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('floor-unit')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.located_on_floor ? `${formatNumber(data.located_on_floor)} ${t('floor-unit')}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bedroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bedroom ? `${data.Bedroom} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bathroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bathroom ? `${data.Bathroom} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('livingroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Living_Room ? `${data.Living_Room} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('kitchenroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Kitchen ? `${data.Kitchen} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('storageroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Storage_room ? `${data.Storage_room} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('balcony')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Balcony ? `${data.Balcony}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Facing')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('View')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.View[`name_${locale.toUpperCase()}`] ? `${data.View[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Quota')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_quota ? t(`quota_${data.property_quota}`) : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }
    
    const RenderHouseDetail = () => {
        return (
            <>
                {data && isHouse(data.type) && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Land_Size ? `${formatNumber(data.Land_Size)} ${t('square wa')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bedroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bedroom ? `${data.Bedroom} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bathroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bathroom ? `${data.Bathroom} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('livingroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Living_Room ? `${data.Living_Room} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('kitchenroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Kitchen ? `${data.Kitchen} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('maidroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.Maidroom ? `${data.Maidroom} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('storageroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.Storage_room ? `${data.Storage_room} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('balcony')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Balcony ? `${data.Balcony} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Frontage')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Frontage ? `${formatNumber(data.Frontage)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Depth')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Depth ? `${formatNumber(data.Depth)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Direction')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('View')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.View[`name_${locale.toUpperCase()}`] ? `${data.View[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }
    
    const RenderApartmentDetail = () => {
        return (
            <>
                {data && data.type === 'Apartment' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>
                                        <span>{data.Land_Rai ? `${formatNumber(data.Land_Rai)} ${t('rai')}` : null}</span>
                                        <span>{data.Land_Nga ? `${formatNumber(data.Land_Nga)} ${t('ngan')}` : null}</span>
                                        <span>{data.Land_Sqw ? `${formatNumber(data.Land_Sqw)} ${t('square wa')}` : null}</span>
                                        {/* {data.Land_Size ? `${data.Land_Size} ${t('square wa')}` : '-'} */}
                                    </Col>
                                </Row>
                                {/* <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Size ? `${data.property_Size} ${t('square meter')}` : '-'} </Col>
                                </Row> */}
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('number-of-floor')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Floor ? `${formatNumber(data.property_Floor)} ${t('floor-unit')}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('number-of-room')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bedroom ? `${data.Bedroom} ${t('unit')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('No. of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Buildings ?  `${data.Buildings}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Age of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.ageOfBuilding ?  `${data.ageOfBuilding}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Frontage')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Frontage ? `${formatNumber(data.Frontage)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Depth')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Depth ? `${formatNumber(data.Depth)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Direction')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bathroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bathroom ? `${data.Bathroom} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }
    
    const RenderCommercailDetail = () => {
        return (
            <>
                {data && data.type === 'Commercail' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('commerial-number-of-unit')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bathroom ? `${data.Bathroom} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>
                                        <span>{data.Land_Rai ? `${formatNumber(data.Land_Rai)} ${t('rai')}` : null}</span>
                                        <span>{data.Land_Nga ? `${formatNumber(data.Land_Nga)} ${t('ngan')}` : null}</span>
                                        <span>{data.Land_Sqw ? `${formatNumber(data.Land_Sqw)} ${t('square wa')}` : null}</span>
                                        {/* {data.Land_Size ? `${data.Land_Size} ${t('square wa')}` : '-'} */}
                                    </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bedroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bedroom ? `${data.Bedroom} ${t('room')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('bathroom')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Bathroom ? `${data.Bathroom} ${t('room')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Age of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.ageOfBuilding ?  `${data.ageOfBuilding}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }
    
    const RenderOfficeDetail = () => {
        return (
            <>
                {data && data.type === 'Office' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('number-of-storey')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Floor ? `${formatNumber(data.property_Floor)} ${t('floor-unit')}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('No. of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Buildings ?  `${data.Buildings}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>
                                        <span>{data.Land_Rai ? `${formatNumber(data.Land_Rai)} ${t('rai')}` : null}</span>
                                        <span>{data.Land_Nga ? `${formatNumber(data.Land_Nga)} ${t('ngan')}` : null}</span>
                                        <span>{data.Land_Sqw ? `${formatNumber(data.Land_Sqw)} ${t('square wa')}` : null}</span>
                                        {/* {data.Land_Size ? `${data.Land_Size} ${t('square wa')}` : '-'} */}
                                    </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('front-width')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Frontage ? `${formatNumber(data.Frontage)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Depth')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Depth ? `${formatNumber(data.Depth)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Direction')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('View')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.View[`name_${locale.toUpperCase()}`] ? `${data.View[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }
    
    const RenderShopDetail = () => {
        return (
            <>
                {data && data.type === 'Shop' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('floor-unit')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Floor ? `${formatNumber(data.property_Floor)} ${t('floor-unit')}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Direction')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }

    const RenderWarehouseDetail = () => {
        return (
            <>
                {data && data.type === 'Warehouse' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information} style={{ alignSelf: 'center' }}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('No. of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Buildings ?  `${data.Buildings}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('floor-unit')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Floor ? `${formatNumber(data.property_Floor)} ${t('floor-unit')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Age of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.ageOfBuilding ?  `${data.ageOfBuilding}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>
                                        <span>{data.Land_Rai ? `${formatNumber(data.Land_Rai)} ${t('rai')}` : null}</span>
                                        {` `}
                                        <span>{data.Land_Nga ? `${formatNumber(data.Land_Nga)} ${t('ngan')}` : null}</span>
                                        {` `}
                                        <span>{data.Land_Sqw ? `${formatNumber(data.Land_Sqw)} ${t('square wa')}` : null}</span>
                                    </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Frontage')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Frontage ? `${formatNumber(data.Frontage)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Depth')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Depth ? `${formatNumber(data.Depth)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Direction')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }

    const RenderHotelDetail = () => {
        return (
            <>
                {data && data.type === 'Hotel' && (
                    <Col span={24}>
                        <Row>
                            <Col xs={24} sm={24}  md={24} style={{paddingTop: '20px'}}>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('property_id')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Code || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('category')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data[`category_Name_${locale.toUpperCase()}`] || '-'}</Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('type')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ `${data.sub_category_lv1_name  || '-'}` || '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('hotel-number-of-unit')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Unit ?  `${formatNumber(data.Unit)}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('No. of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Buildings ?  `${data.Buildings}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('number-of-storey')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Floor ? `${formatNumber(data.property_Floor)} ${t('floor-unit')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6}  className={styles.titleInformation}>{`${t('Size')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Size ? `${formatNumber(data.property_Size)} ${t('square meter')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Parking')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.property_Parking ?  `${formatNumber(data.property_Parking)} ${t('car-unit')}` : '-'} </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Land Area')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>
                                        <span>{data.Land_Rai ? `${formatNumber(data.Land_Rai)} ${t('rai')}` : null}</span>
                                        {` `}
                                        <span>{data.Land_Nga ? `${formatNumber(data.Land_Nga)} ${t('ngan')}` : null}</span>
                                        {` `}
                                        <span>{data.Land_Sqw ? `${formatNumber(data.Land_Sqw)} ${t('square wa')}` : null}</span>
                                    </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Frontage')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Frontage ? `${formatNumber(data.Frontage)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Depth')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Depth ? `${formatNumber(data.Depth)} ${t('meter')}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Direction')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.Direction[`name_${locale.toUpperCase()}`] ? `${data.Direction[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row>
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('View')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.View[`name_${locale.toUpperCase()}`] ? `${data.View[`name_${locale.toUpperCase()}`]}` : '-' } </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('Age of Building')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{data.ageOfBuilding ?  `${data.ageOfBuilding}` : '-'} </Col>
                                </Row> 
                                <Row className={styles.tableRow}>
                                    <Col span={6} className={styles.titleInformation}>{`${t('address')}:`}</Col>
                                    <Col xs={{ span: 16, offset: 2}} md={{ span: 18, offset: 0 }} className={styles.information}>{ address } </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                )}
            </>
        )
    }

    return (
        <>
            {RenderLandDetail()}
            {RenderCondoDetail()}
            {RenderShopDetail()}
            {RenderHouseDetail()}
            {RenderApartmentDetail()}
            {RenderOfficeDetail()}
            {RenderWarehouseDetail()}
            {RenderCommercailDetail()}
            {RenderHotelDetail()}
        </>
    )
}

export default  RenderDetail;