// import { CallActivity } from './CallActivity'
// import { InquiryActivity } from './InquiryActivity'

import { Dispatch, SetStateAction } from 'react'
// import { AppointmentActivity } from './AppointmentActivity'
// import { FavoriteActivity } from './FavoriteActivity'
import React from 'react'
import dynamic from 'next/dynamic'

export interface PopOverState {
  visible: boolean
  id: number
  firstName: string
  lastName: string
  mobile: string
  email: string
  developer: string
  salesMobile: string
  salesEmail: string
  photo: string
}

export const popOverDefaultState = {
  visible: false,
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  id: 0,
  developer: '',
  salesEmail: '',
  salesMobile: '',
  photo: '',
}

export interface InquiryState {
  visible: boolean
  firstName: string
  lastName: string
  mobile: string
  mobileCountryCode: string
  email: string
  message: string
  visibleSucceed: boolean
  buyerId: number
  inquiryId: number
  type: string // project or property
  id: number
}

export const inquiryDefaultState = {
  visible: false,
  firstName: '',
  lastName: '',
  mobile: '',
  mobileCountryCode: '',
  email: '',
  message: '',
  visibleSucceed: false,
  buyerId: 0,
  inquiryId: 0,
  type: '',
  id: 0,
}

export interface appointedSlots {
  start: string
  end: string
  isAvailable: boolean
}

export interface AppointmentState {
  visible: boolean
  venue: string
  latitude: number
  longitude: number
  visibleSucceed: boolean
  appointmentId: number
  appointedSlots: appointedSlots[] | []
  type: 'project' | 'property' | ''
  id: number
  visibleMap: boolean
  project_Latitude: number | null
  project_Longitude: number | null
  project_Zoom: number | null
  project_Name_TH: string | null
  project_Name_EN: string | null
  project_Name_CN: string | null
  initialX: number
  initialY: number
  appointedXLon: number
  appointedYLat: number
  appointedVenue: string
}

export const defaultAppointmentState: AppointmentState = {
  visible: false,
  venue: '',
  latitude: 0,
  longitude: 0,
  visibleSucceed: false,
  appointmentId: 0,
  appointedSlots: [],
  type: '',
  id: 0,
  visibleMap: false,
  project_Latitude: 0,
  project_Longitude: 0,
  project_Zoom: 0,
  project_Name_TH: '',
  project_Name_EN: '',
  project_Name_CN: '',
  initialX: 0,
  initialY: 0,
  appointedXLon: 0,
  appointedYLat: 0,
  appointedVenue: '',
}

const DynamicCallActivity = dynamic(() => import('./CallActivity')
  .then(module => module.CallActivity), { ssr: false })

const DynamicInquiryActivity = dynamic(() => import('./InquiryActivity')
  .then(module => module.InquiryActivity), { ssr: false })

const DynamicAppointmentActivity = dynamic(() => import('./AppointmentActivity')
  .then(module => module.AppointmentActivity), { ssr: false })

const DynamicFavoriteActivity = dynamic(() => import('./FavoriteActivity')
  .then(module => module.FavoriteActivity), { ssr: false })


export function CardActions({
  id,
  type,
  locale,
  popOverState,
  setPopOverState,
  inquiryState,
  setInquiryState,
  appointmentState,
  setAppointmentState,
  imageURL,
  name,
  details,
}: {
  id: number
  type: string
  locale: string
  popOverState: PopOverState
  setPopOverState: Dispatch<SetStateAction<PopOverState>>
  inquiryState: InquiryState
  setInquiryState: Dispatch<SetStateAction<InquiryState>>
  appointmentState: AppointmentState
  setAppointmentState: Dispatch<SetStateAction<AppointmentState>>
  imageURL?: string
  name?: string
  details: any
}) {
  const _type = type === 'project' ? 'project' : 'property'

  return [
    <DynamicCallActivity
      key={'call'}
      popOverState={popOverState}
      setPopOverState={setPopOverState}
      type={type}
      id={id}
      locale={locale}
      details={details}
    />,
    <DynamicInquiryActivity
      key={'inquiry'}
      locale={locale}
      setInquiryState={setInquiryState}
      inquiryState={inquiryState}
      type={_type}
      id={id}
      details={details}
    />,
    <DynamicAppointmentActivity
      key={'appointment'}
      setAppointmentState={setAppointmentState}
      appointmentState={appointmentState}
      type={_type}
      id={id}
      locale={locale}
      imageURL={imageURL}
      name={name}
      details={details}
    />,
    <DynamicFavoriteActivity
      key={'favorite'}
      type={_type}
      id={id}
      details={details}
    />,
  ]
}
