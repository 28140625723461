import {
    Row,
    Col,
    InputNumber,
    List
} from 'antd'

import {
    formatPriceSearchNo00,
} from '../../utils/utils'

import {
    useState
} from 'react';

// const stepPrice = 1000000;
const marks = {
    0: '',
    25: '',
    50: '',
    75: ''
  };


const MinList = ({onClick}) => {
    let list = [0, 100000, 200000, 500000, 1000000, 2000000]
    
    const data = list.map( l => {
        return {
            value: l,
            text: formatPriceSearchNo00(l)
        }
    })

    return <List
        size="small"
        style={{width: '100%'}}
        bordered
        dataSource={data}
        renderItem={item => <List.Item onClick={ () => onClick(item) } >{item.text}</List.Item>}
    />
}

const MaxList = ({onClick}) => {
    let list = [
        1000000,
        5000000,
        8000000,
        10 * 1000000,
        20 * 1000000,
        30 * 1000000,
    ]
    
    const data = list.map( l => {
        return {
            value: l,
            text: formatPriceSearchNo00(l)
        }
    })

    return <List
        size="small"
        style={{width: '100%'}}
        bordered
        dataSource={data}
        renderItem={item => <List.Item onClick={ () => onClick(item) } >{item.text}</List.Item>}
        rowKey={ item => item.value }
    />
}

export const PriceSelect = ({
    menu, 
    onPriceAfterChange,
    onMaxListClickGo
}) => {
    const [ priceMin, setPriceMin ] = useState(0);
    const [ priceMax, setPriceMax ] = useState(1000000);

    const onMaxListClick = (e) => {
        setPriceMax(e.value);
        onPriceAfterChange([priceMin, e.value])
        if(onMaxListClickGo) {
            onMaxListClickGo();
        }
    }

    const onMinListClick = (e) => {
        setPriceMin(e.value);
        onPriceAfterChange([e.value, priceMax])
    }

    const onMaxChange = (value) => {
        setPriceMax(value)
        onPriceAfterChange([priceMin, value])
    }

    const onMinChange = (value) => {
        setPriceMin(value)
        onPriceAfterChange([value, priceMax])
    }
    
    const  onPanelFocus = () => {
        console.log('onPanelFocus')
    }
    return  (
        <div style={{margin:"20px", width: "300px"}} onFocus={onPanelFocus} >
            <Row gutter={16} justify="center">
                <Col span={12}>
                    <Row>
                        <InputNumber
                            prefix="$"
                            defaultValue={priceMin}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={ onMinChange }
                            style={{ width: '100%' }}
                            value={priceMin}
                        />
                    </Row>
                    <Row>
                        {
                            <MinList onClick={onMinListClick}/>
                        }
                    </Row>
            </Col>
                <Col span={12}>
                    <Row>
                        <InputNumber
                            prefix="$"
                            defaultValue={priceMax}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={ onMaxChange }
                            style={{ width: '100%' }}
                            value={priceMax}
                        />
                    </Row>
                    <Row>
                        {
                            <MaxList onClick={onMaxListClick} />
                        }
                    </Row>
                </Col>
            </Row>
          {/* <Row >
            <Col span={4} style={{textAlign: 'center'}}>
              {formatPriceSearch(priceMin  * stepPrice)}
            </Col>
            <Col span={16}>
              <Slider range 
                defaultValue={[priceMin, priceMax]} 
                marks={marks}
                tooltipVisible={false} 
                step={1}
                onChange={onPriceAfterChange} />
            </Col>
            <Col span={4} style={{textAlign: 'center'}}>
              {formatPriceSearch(priceMax  * stepPrice)}
            </Col>
          </Row> */}
          {menu}
        </div>
    )
}