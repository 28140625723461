import {
    Row,
    Col,
    Carousel 
} from 'antd';


// import {
//     MyImage
// } from '../myImage';
import Image from 'next/image'
import Link from 'next/link';
import {
    useRef,
    useEffect,
    useState
} from 'react';

import styles from './mobileCategory.module.scss';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
export interface MobileCategoryProps {
    // items: any[];
    categoryTable: any;
    actionsTable: any;
}

// const { Header, Footer, Sider, Content } = Layout;
const imageWidth = 32;
const imageHeight = 32;
const imageMapper = [
    {
        image: '/assets/categoryIcons/webp/1 Condominium.webp', 
        width: imageWidth, 
        height: imageHeight,
        categoryId: 1,
        alt: 'menu-icon-condominium'
    },
    {
        image: '/assets/categoryIcons/webp/2 Detached House.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 3,
        alt: 'menu-icon-detached-house'
    },
    {
        image: '/assets/categoryIcons/webp/3 Townhouse.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 2,
        alt: 'menu-icon-townhouse'
    },
    {
        image: '/assets/categoryIcons/webp/4 Twinhouse.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 24,
        alt: 'menu-icon-twinhouse'
    },
    {
        image: '/assets/categoryIcons/webp/6 Homeoffice.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 4,
        alt: 'menu-icon-homeoffice'
    },
    {
        image: '/assets/categoryIcons/webp/7 Apartment.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 7,
        alt: 'menu-icon-apartment'
    },
    {
        image: '/assets/categoryIcons/webp/5 Shophouse.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 9,
        alt: 'menu-icon-shophouse'
    },
    {
        image: '/assets/categoryIcons/webp/8 Land.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 5,
        alt: 'menu-icon-land'
    },
    {
        image: '/assets/categoryIcons/webp/13 office.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 6,
        alt: 'menu-icon-office'
    },
    {
        image: '/assets/categoryIcons/webp/14 Hotel.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 11,
        alt: 'menu-icon-hotel'
    },
    {
        image: '/assets/categoryIcons/webp/15 Resort.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 17,
        alt: 'menu-icon-resort'
    },
    {
        image: '/assets/categoryIcons/webp/16 Villa.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 25,
        alt: 'menu-icon-villa'
    },
    {
        image: '/assets/categoryIcons/webp/9 Factory.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 16,
        alt: 'menu-icon-factory'
    },
    {
        image: '/assets/categoryIcons/webp/10 Warehouse.webp', 
        width: imageWidth, height: imageHeight,
        categoryId: 8,
        alt: 'menu-icon-factory'
    },
    {
        image: '/assets/categoryIcons/webp/11 Retail Space.webp', width: imageWidth, height: imageHeight,
        categoryId: 10,
        alt: 'menu-icon-retail-space'
    },
    {
        image: '/assets/categoryIcons/webp/12 Showroom.webp', width: imageWidth, height: imageHeight,
        categoryId: 10,
        alt: 'menu-icon-showroom'
    }
]

export const MobileCategory = ({categoryTable, actionsTable}: MobileCategoryProps) => {
   
    const router  = useRouter();

    const { i18n, t } = useTranslation();

    let id = 0;
    let selectedId = 0;
    const inputEl = useRef(null);
    const [elRefs, setElRefs] = useState([]);
    const categoryTableMapper =  keyBy(categoryTable.data, 'category_ID');

    const actionName = actionsTable.data?.find((d) => d.id === 1)?.action;
   
    const sortedcategoryTable = sortBy(categoryTable.data, 'category_Sort')
        .map((d) => {
            const imageProps = imageMapper.find((image) => image.categoryId === d.category_ID);
            return {
                categoryId: d.category_ID,
                ...imageProps
            }
        });

    const itemsPage1 = sortedcategoryTable.slice(0, 8);
    const itemsPage2 = sortedcategoryTable.slice(8);

    useEffect(() => {
        
    }, []);


    const renderItem = (item) => {
        const categoryName = categoryTableMapper[item.categoryId][`category_Name_${i18n.language.toUpperCase()}`];

        return  <Col span={6}>
                    <Link  legacyBehavior href={`${router.basePath}/${actionName}/${categoryName}`} >
                        <a>
                            <Row className={styles.categoryimage}>
                                {
                                    item && item.image ? 
                                        <Image src={`${item.image}`} width={item?.width || 20} height={item?.height || 20}  
                                            alt={`${item.alt}`}
                                        /> : undefined
                                }
                            </Row>
                            <Row className={styles.categoryname}>
                                {`${categoryName}`}
                            </Row>
                        </a>
                    </Link>
                </Col> 
    }
    
    return (
        // <div className={styles.container}>
        <>
            <Carousel
                dots={false}
                arrows
                prevArrow={<IconButton size='large'><ChevronLeft sx={{ color: '#f4762a' }} /></IconButton>}
                nextArrow={<IconButton size='large' onClick={() => alert('a')}><ChevronRight sx={{ color: '#f4762a' }} /></IconButton>}
            >
                <div>
                    <Row>
                        <Col flex="auto">
                            <Row gutter={[16,16]} >
                                {
                                    itemsPage1.map((item) => {
                                        return renderItem(item);
                                    })
                                } 
                            </Row>
                        </Col>
                        {/* <Col flex="50px">
                            <div>{`>`}</div>
                        </Col> */}
                    </Row>
                </div>

                <div>
                    <Row>
                        <Col flex="auto">
                            <Row gutter={[16,16]} >
                                {
                                    itemsPage2.map((item) => {
                                        return renderItem(item);
                                    })
                                } 
                            </Row>
                        </Col>
                        {/* <Col flex="50px">
                            <div>{`>`}</div>
                        </Col> */}
                    </Row>
                </div>
            </Carousel >
        </>
        // </div>
    )
}