// import 'reflect-metadata'

// Create a key to store the metadata under. This should be a symbol.
// const lastCallElapsedKey = Symbol('lastCallElapsedKey')

// export function CreateTimestamps(message?: string) {
//   return function (target: any, name: string, descriptor: PropertyDescriptor) {
//     const method = descriptor.value;

//     descriptor.value = async function () {
//       const startTime = new Date(Date.now());      
//       const result = await method.apply(this);
//       const endTime = new Date(Date.now());
//       const elpased = endTime.getTime() - startTime.getTime()

//       // Write the elpased time to the new function's metadata.
//       // @ts-ignore
//       // Reflect.defineMetadata(lastCallElapsedKey, elpased, descriptor.value)


//       console.log(`PERF: ${message}: elpased:${elpased}`)
      
//       return result
//     };
//   };
// }
const getFunctionName = () => {
  try {
    throw new Error();
  } catch (err) {
    return err;
  }
}

export const PrintElpased = async (fnname: string, fn: any) => {
    const startTime = performance.now();
    const result = await fn();
    const endTime = performance.now();
    const executionTime = endTime - startTime;


    console.log(`PERF: ${fnname} - ${executionTime} ms`);

    if(result)
      result.profiling = { [fnname] : executionTime };

    return result;
}

// // Get the elapsed time from the metadata.
// export function getElapsed(fn: (...args: unknown[]) => unknown): number | undefined {
//     // @ts-ignore
//     return Reflect.getMetadata(lastCallElapsedKey, fn)
// }