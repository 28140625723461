import {
    MyImageImg
} from '../myImage';

import {
    useRef,
    useEffect,
    useState
} from 'react';

import styles from './gallaryview.module.scss';
import { createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/'
// import { 
//     faCircleLeft,
//     faCircleRight
// } from '@fortawesome/free-regular-svg-icons';

import {
    faCircleChevronLeft,
    faCircleChevronRight
} from '@fortawesome/free-solid-svg-icons'

export interface GallaryViewProps {
    items: any[];
}

export const GallaryView = ({items}: GallaryViewProps) => {
    let id = 0;
    let selectedId = 0;
    const inputEl = useRef(null);
    const totalImages = items.length;
    const [elRefs, setElRefs] = useState([]);

    useEffect(() => {
        // add or remove refs
        setElRefs((elRefs) =>
          Array(totalImages)
            // .fill()
            .map((_, i) => elRefs[i] || createRef()),
        );
      }, [totalImages]);

    let isMouseDown = false;

    const moveImages = (direction, xcomplete = 100) => {
        let scrollCompleted = 0;
        var slideVar = setInterval(function(){
            if(direction == 'left'){
                inputEl.current.scrollLeft  -= 10;
            } else {
                inputEl.current.scrollLeft  += 10;
            }
            scrollCompleted += 10;
            if(scrollCompleted >= xcomplete){
                window.clearInterval(slideVar);
            }
        }, 10);
    }
    const leftOnClick = () => {
        if(selectedId > 0) {
            selectedId--;
        }
        moveImages('left', 500);
        console.log('leftOnClick: ', selectedId)
    }

    const rightOnClick = () => {
        if(selectedId < items.length -1) {
            selectedId++;
        } 
        moveImages('right', 500);
        console.log('rightOnClick: ', selectedId)
    }

    const onMouseDown = (e) => {
        isMouseDown = true;
        console.log('MouseDown: ', e)
    }

    const onMouseMove = (e) => {
        if(isMouseDown) {
            // console.log('MouseMove: ', e.movementX)
            inputEl.current.scrollLeft  -= e.movementX;
        }
    }

    const onMouseUp = (e) => {
        console.log('MouseUp: ', e)
        isMouseDown = false;
        
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.leftArrow} onClick={leftOnClick}>
                <FontAwesomeIcon icon={faCircleChevronLeft} size="2x" className={styles.detailIcon}/>
            </div>
            <div className={styles.imageItemsContainer} ref={inputEl} 
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}>
                {
                    items.map((i) => {
                        const idNo = id++;
                        return (
                            <MyImageImg myref={elRefs[idNo]} key={`image-${idNo}`} 
                                id={`image-${idNo}`} src={i.original} imagestyle={styles.imageItemStyle}/>
                        )
                    })
                }
            </div>
            <div className={styles.rightArrow} onClick={rightOnClick}>
                <FontAwesomeIcon icon={faCircleChevronRight} size="2x" className={styles.detailIcon}/>
            </div>
        </div>
    )
}