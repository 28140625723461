
// import { Col, Row,
//   Select,
//   Cascader,
//   Button,
// } from 'antd';

import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Select from 'antd/es/select';
import Cascader from 'antd/es/cascader';
import Button from 'antd/es/button';

// import { 
//     UserOutlined,
//     DownOutlined,
//     FormOutlined,
// } from '@ant-design/icons';

// import { 
//   LocationOn,
//   TextFields
// } from '@mui/icons-material';
import {
  MobileView,
  isBrowser,
} from "react-device-detect";


import {
  AdBannerTop
} from '../../components'

import {
  // getProvinces,
  // getProvinceWithAmphur,
  // getAmphurByProvicneID,
  getLocationByLocationTypeID,
  // getLocationBranchByLocationID,
  // getActions,
  // getCateogry,
  getSearchList
} from '../../utils/requests';

import {
  formatPriceSearch
} from '../../utils/utils'
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
// import Image from 'next/image';

import { useState, useEffect } from 'react';
// import tb_category from './tb_category.json';
// import tb_location_type from './tb_location_type.json';
// import tb_actions from './tb_actions.json';

import { PriceSelect } from './price-select';
import { MobileSearch } from './mobile-search';
import { MobileCategory } from '../mobileCategory';

// const BuyTab = (props) => {
//   return (
//     <Tabs.TabPane {...props}>
//     </Tabs.TabPane>
//   )
// }

// const SellTab = (props) => {
//   return (
//     <Tabs.TabPane {...props}>
//     </Tabs.TabPane>
//   )
// }

// const RentTab = (props) => {

//   return (
//     <Tabs.TabPane {...props}>
//     </Tabs.TabPane>
//   )
// }

// const LocationSvg = () => (
//   // <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
//   //   <path d="M0 0h24v24H0V0z" fill="none"/>
//   //   <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
//   //   <circle cx="12" cy="9" r="2.5"/>
//   //   </svg>
//   <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/><circle cx="12" cy="9" r="2.5"/></svg>
// )

// const LocationIcon = props => <Icon component={LocationSvg} {...props} />
// const createCascaders = (locationTypeid: string) => {
//   let list;
//   if(locationTypeid === '0') {
//     const province = await getProvinces()
//     console.log(province);
//     list = [...province.data.map((d) => {
//       return {
//         value: d['PROVINCE_ID'],
//         label: d['PROVINCE_NAME_TH'],
//         isLeaf: false,
//       }
//     })];
//   } else {
//     const locationType = await getLocationByLocationTypeID(locationTypeid)
//     console.log(locationType);
//     list = [...locationType.data];

//   } 
  
//   return  <Cascader style={{ width: '65%' }} 
//     options={list}   
//     // loadData={loadDataLevel2}
//     size="large" placeholder="เลือกจากจังหวัด" 
//     // onChange={onChange}
//     changeOnSelect />
// }

export interface IPathIds {
  action_id: number;
  cateogry_ID: number;
  province_id: number;
  amphur_id: number;
  distinct_id: number;
  location_type_id: number;
  location_id: number;
  location_branch_id: number;
  searchByProjectName?:  boolean;
  location?: string;
  project_id?: string;
  project_name?: string;
}
export interface ISearchByOptions {
  nologo: any;
  searchbgClass?: any;
  isMobileFilterOpen: any;
  onMobileSearchOk: any;
  onMobileSearchClose: any;
  provinceTable: any;
  actionsTable: any;
  categoryTable: any;
  locationTypeTable: any;

  pathIds: IPathIds;
  // action_id: number;
  // cateogry_ID: number;
  // province_id: number;
  // amphur_id: number;
  // distinct_id: number;

  // location_type_id: number;
  // location_id: number;
  // location_branch_id: number;
  actionCateLocationType: any;
  loading: boolean;
}

export const SearchByOptions = (props: ISearchByOptions) => {
    const router = useRouter()
    const { locale, locales, defaultLocale, pathname } = router;
    const { action: routeAction, ...queryParamters } = router.query;

    const { t } = useTranslation();
    const [ typeOrLocaiton, settypeOrLocation ] = useState(true);
    const [ isProvince, setIsProvince] = useState(true);
    const [ isProjectName, setIsProjectName] = useState(false);
    const [ level1List, setlevel1List ] = useState([]);
    const [ cascaderValue, setCascaderValue ] = useState([]);
    const [ cascaderPlaceholder, setCascaderPlaceholder ] = useState('เลือกจังหวัด');

    const [ selectedProviceIDOrLocationTypeID, setSelectedProviceIDOrLocationTypeID ] = useState(0);
    const [ selectedLocationType, setSelectedLocationType ] = useState(undefined);
    const [ selectedCascaderValue, setSelectedCascaderValue ] = useState([]);

    const [ actionList, setActionList ] = useState(props.actionsTable?.data);
    const [ tb_category, setTb_category] = useState(props.categoryTable?.data);
    const [ tb_location_type, setTb_location_type ] = useState(props.locationTypeTable?.data)
    const [ provinceTable, setProvinceTable ] = useState(props.provinceTable?.data);

    // const maxPrice = 100000000;
    // // const minPrice = 0;
    // const stepPrice = 1000000;
    
    const [ cateogry, setCategory ] = useState(undefined);
    const [ action, setAction ] = useState(actionList ? actionList[0] : []);
    const [ priceMin, setPriceMin ] = useState(0);
    const [ priceMax, setPriceMax ] = useState(1000000000);
   
    

    const [ currentProjectName, setCurrentProjectName ] = useState(undefined);
    const [ projectNameList, setProjectNameList ] = useState([]);

    // const projectNameAbortCon = new AbortController();
    const [ projectNameRequestCount, setProjectNameRequestCount ] = useState(Array<AbortController>());

    const { actionCateLocationType } = props;


    // Dynamic search list
    const [ tb_categoryNew, setTb_categoryNew] = useState(props.categoryTable?.data);
    const [ tb_location_typeNew, setTb_location_typeNew ] = useState(props.locationTypeTable?.data)

    useEffect(() => {
      
      console.log('SearchByOptions: ', props); // routeAction, queryParamters, actionList, tb_category, provinceTable);
     
      
      // loadProvinceData();
      loadDataFromURL();
    }, []);

    // useEffect(()=> {

    //   console.log('yyyyxxxzzz -- search by options', props)
    //   setActionList(props.actionsTable.data);

    // //   // setTb_location_typeNew(props.locationTypeTable.data)
    //   setProvinceTable(props.provinceTable.data)
    // //   loadDataFromURL()
    // //   // setTb_location_type(props.locationTypeTable.data)
    //   // onProvinceOrLocationTypeChange(`${props.pathIds.location_type_id}`);
    //   setSelectedProviceIDOrLocationTypeID(props.pathIds.location_type_id);
    // }, [
    //   props.actionsTable.data,
    //   // props.locationTypeTable.data,
    //   props.provinceTable.data,
    //   props.pathIds
    // ])

    const checkIsProvince =(pathIds) => {
      if(pathIds.province_id === null && pathIds.location_type_id === null) {
        return true;
      }

      return pathIds ? pathIds.province_id !== null : true;
    }

    const loadDataFromURL = async () => {
      let currentCategory;
      let currentActionObj
      if(routeAction && routeAction.length >=  0) { 
        const actionName = routeAction[0];
        // actionsTable && setActionList(actionsTable.data)
        currentActionObj = actionList.find( a => a.action === actionName);
        currentActionObj && setAction(currentActionObj);
        
        
        const categoryName = routeAction[1];
        // categoryTable && setTb_category(categoryTable.data);
        currentCategory = tb_category.find( a => a[`category_Name_${locale.toUpperCase()}`] === categoryName);
        currentCategory && setCategory(currentCategory);

        console.log('loadProvinceData: ', tb_category, currentCategory ? currentCategory.category_ID: undefined);
      }

      const isProvince = checkIsProvince(props.pathIds);

      const searchByProjectName = props?.pathIds?.searchByProjectName;
      if(searchByProjectName) {
        const currentProjectName = props?.pathIds?.location;
        
        setCurrentProjectName(currentProjectName);
        // url search by project name
        console.log('searchByProjectName', props.pathIds, currentProjectName)
        await loadProjectName(currentCategory?.category_ID, currentProjectName, currentActionObj?.id)
        setIsProjectName(true);
        const project = projectNameList.find( p => p.value === currentProjectName)

        console.log('searchByProjectName xxxxx', project)

      } else {
        setIsProjectName(false); 
        // Normal
        console.log('loadDataFromURL', props.pathIds, isProvince)
        setIsProvince(isProvince);
        if(isProvince) { 
          await loadProvinceData();
          const cascadervaluse = [props.pathIds.province_id,
            props.pathIds.amphur_id].filter((id) => id !== null);

          let selectedLocations = [];
          if(props.pathIds.province_id){
            const selectedProvince = provinceTable?.find((t) => {
              return t.value === props.pathIds.province_id;
            });

            selectedLocations = [selectedProvince];
          }

          if(props.pathIds.amphur_id){
            const selectedAmphur = provinceTable?.find((t) => {
              return t.value === props.pathIds.province_id;
            })?.children.find((t) => {
              return t.value === props.pathIds.amphur_id; 
            });

            selectedLocations = [...selectedLocations, selectedAmphur];
          }

          setCascaderValue(cascadervaluse);
          setSelectedCascaderValue(selectedLocations);
        } else {
          // is location_type
          await onProvinceOrLocationTypeChange(`${props.pathIds.location_type_id}`);
          setSelectedProviceIDOrLocationTypeID(props.pathIds.location_type_id);
        
          const cascadervaluse = [props.pathIds.location_id,
            props.pathIds.location_branch_id].filter((id) => id !== null);

          const { data: locationType } = await getLocationByLocationTypeID(`${props.pathIds.location_type_id}`, locale);

          let selectedLocations = [];
          if(props.pathIds.location_id){
            const selectedLocation = locationType.find((t) => {
              return t.value === props.pathIds.location_id;
            });

            selectedLocations = [selectedLocation];
          }

          if(props.pathIds.location_branch_id){
            const selectedLocationBranch = locationType.find((t) => {
              return t.value === props.pathIds.location_id;
            }).children.find((t) => {
              return t.value === props.pathIds.location_branch_id; 
            });

            selectedLocations = [...selectedLocations, selectedLocationBranch];
          }
          setCascaderValue(cascadervaluse);
          setSelectedCascaderValue(selectedLocations);
        }
      }
    }

    const onCascaderChange = (value, selectedOptions) => {
      console.log('onChange: ', value, selectedOptions, value === '0');
      setCascaderValue(value);
      setSelectedCascaderValue(selectedOptions);
    };

    function filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    
    const loadProvinceData = async () => {
      if(provinceTable && provinceTable) {
        const list = provinceTable || [];
        setlevel1List([...list]);
      } else {
        setlevel1List([])
      }

      setCascaderPlaceholder(`${t('select')}${locale === 'en' ? ' ' : ''}${t('province')}`) // `${t('เลือก')}${t('จังหวัด')}`);
      setIsProvince(true); 
      setSelectedProviceIDOrLocationTypeID(0);
    }

    const loadProjectName = async (categoryid, value, action_id) => {

      let query: { projectname?: string, projectonly?: boolean, 
          categoryid?: string, limit: number,  projectnameonly: boolean, actionid: string} = value ? {
        projectname: `${value}*`,
        projectonly: true,
        limit: 10,
        projectnameonly: true,
        // actionid: action_id
      } : {
        projectonly: true,
        limit: 10,
        projectnameonly: true,
        // actionid: action_id
      }

      if(action_id && action_id !== 2) {
        // case 2: search from action that are not the project
        console.log('loadProjectName: case 2: search from action that are not the project')
        query['created_sources'] = [0, 1, 2]
      } else {
        query['created_sources'] = [0, 1] 
        query['actionid'] = action_id;
      }

      if(categoryid) {
        query.categoryid = categoryid;
      }

      console.log('loadProjectName: ', projectNameRequestCount, action, action_id)

      while(projectNameRequestCount.length > 1) { 
        const a = projectNameRequestCount.pop();
        console.log('about load project');
       
        a.abort();
        // projectNameRequestCount.slice(0, -2).map( (r) => {
        //   console.log('loadProjectName: cancel')
        //   r?.abort();
        // })
      }

      const projectNameAbortCon = new AbortController();
      const projects = await getSearchList(query, projectNameAbortCon)
      projectNameRequestCount.push(projectNameAbortCon);
      // setProjectNameRequestCount(projectNameRequestCount);
    
      console.log('onProvinceOrLocationTypeChange: ', projects);
      
      setCascaderPlaceholder(`${t('select')}${locale === 'en' ? ' ' : ''}${t('project-name')}`) // `${t('เลือก')}${t('จังหวัด')}`);
      setSelectedProviceIDOrLocationTypeID(-1);
      

      if(projects?.data?.items?.length > 0) {
        const projectsName = projects.data.items.map((p) => {
          const label = p.project.name[`${locale.toUpperCase()}`];
          const reg = new RegExp(value, 'gi');
          const final_str = label.replace(reg, function(str) {return '<span style="color: #f4762a">'+str+'</span>'});

          return {
            value: p.project.id,
            originalLabel: label,
            label: <div dangerouslySetInnerHTML={{ __html: final_str }} />
             // p.project.name[`${locale.toUpperCase()}`]
          }
        })
        // setlevel1List(projectsName)
        setProjectNameList(projectsName)
      } else {
        setProjectNameList([]) 
      }
    }
    // when user select location_type or province
    const onProvinceOrLocationTypeChange = async (e) => {
      console.log('onProvinceOrLocationTypeChange: ', e, action);
      setIsProjectName(false);
      setSelectedLocationType(undefined); 
      setSelectedCascaderValue([])

      if(`${e}` === 'null' || `${e}` === '0') {
        loadProvinceData();
      } else if (`${e}` === '-1') {
        // Search by project name
        setIsProjectName(true);
        loadProjectName(cateogry?.category_ID, undefined, action.id);
      } else {
        const locationType = await getLocationByLocationTypeID(e, locale);
        setlevel1List([...locationType.data]); 
        setIsProvince(false); 

        let placeholderText = '';
        const selectedLocation = tb_location_type.find((t) => {
          return t.location_type_ID === parseInt(e);
        });
        placeholderText += `${t('select')}${locale === 'en' ? ' ' : ''}${selectedLocation && selectedLocation[`location_type_name_${locale.toUpperCase()}`]}`;
        setCascaderPlaceholder(placeholderText);
        setSelectedLocationType(selectedLocation);
        setSelectedProviceIDOrLocationTypeID(e);
      }

      setCascaderValue(undefined);
    }

    const onSearch = (e) => {
      console.log(e);
      console.log('onSearch click: ', {
        action: action,
        cateogry: cateogry,
        location: isProvince ? undefined: selectedLocationType,
        searchValues: selectedCascaderValue,
        locale,
        priceMin,
        priceMax,
        isProjectName,
        currentProjectName,
        projectNameList
      })

      let searchURL = '';
      const queryParams = [];

      if(action) {
        searchURL += `/${action.action}`;
      } else {
        searchURL += `/${t('all')}`; 
      }

      if(cateogry) {
        searchURL += `/${cateogry['category_Name_' + locale.toUpperCase()]}`;
      } else {
        searchURL += `/${t('estate')}`;
      } 

      if(isProjectName) {
        searchURL += `/${t('project-name')}`;
        if(currentProjectName) {
          const project = projectNameList.find( p => p.value === currentProjectName)
          if(project?.originalLabel) {
            searchURL += `/${project.originalLabel}`;
            queryParams.push(`projectid=${currentProjectName}`);
          } else {
            searchURL += `/${currentProjectName}`;
            const project_id = props?.pathIds?.project_id;
            queryParams.push(`projectid=${project_id}`);
          }
        }
      } else {
        if(!isProvince) {
          searchURL += `/${selectedLocationType['location_type_name_' + locale.toUpperCase()]}`;
        }

        if(selectedCascaderValue) {
          selectedCascaderValue.map((cascader) => {
            searchURL += `/${cascader.label}`;
          }) 
        }
      }
      
      
      console.log('xxx isProjectName: ', isProjectName, searchURL, currentProjectName)

     
      if(priceMin) {
        queryParams.push(`startPrice=${priceMin}`); 
      }
      if(priceMax) {
        queryParams.push(`endPrice=${priceMax}`); 
      }

      if(queryParams.length > 0) {
        searchURL += `?${queryParams.join('&')}`
      }

      console.log('onSearch: ', searchURL);
      if (typeof window !== "undefined") {
        // Client-side-only code
        // window.open(searchURL, '__blank');
        // window.open(searchURL);
        router.push(searchURL, '', { shallow: false });
      }
    }

    const onCategoryChange = (e) => {
      console.log('onCategoryChange: ', e);
      if(e === 0)  {
        setCategory(undefined);
      } else {
        const category = tb_category.find((cate) => {
          return cate.category_ID == e;
        })
        setCategory(category);

        // const validLocationTypeId: any[] = actionCateLocationType.data.filter( r => {
        //   return (r.action_id === action.id) 
        //     && (r.category_id === category.category_ID)
        //     && (r.status === 1)
        //   ;
        // }).map( r => {
        //   return r.location_type_id;
        // })
        // const newLocationTypeId = tb_location_type.filter((cat) => {
        //   return validLocationTypeId.indexOf(cat?.location_type_ID) > -1;
        // });
        
        // console.log('onCategoryChange: ',action, 
        // category,
        //   tb_location_type, validLocationTypeId, newLocationTypeId);
        
        // setTb_location_typeNew(newLocationTypeId);
      }
      onProvinceOrLocationTypeChange(null);
      setCurrentProjectName(undefined)
    }

    const onActionChange = (e) => {
      console.log('onActionChange: ', e);
      const actionObj = actionList.find( a => a.id === e);

      setAction(actionObj);

      // const validCategoryIds: any[] = actionCateLocationType.data.filter( r => {
      //   return (r.action_id === actionObj.id) && (r.location_type_id === null)
      //     && (r.status === 1)
      //   ;
      // }).map( r => {
      //   return r.category_id;
      // })

      // const newcategoryList = tb_category.filter((cat) => {
      //   return validCategoryIds.indexOf(cat?.category_ID) > -1;
      // });

      // console.log('onActionChange: ',tb_category, newcategoryList);

      // setTb_categoryNew(newcategoryList); 
    }

    const onPriceAfterChange = (value) => {
      setPriceMin(value[0])
      setPriceMax(value[1])
    }


    const onMobileSearchClose = (e) => {
      props.onMobileSearchClose && props.onMobileSearchClose(e);
    }
    const onMobileSearchOk = (e) => {
      onSearch(e);
      console.log('search by option called: ', e)
      props.onMobileSearchOk && props.onMobileSearchOk(e);
    }

    const onMaxListClickGo = () => {

    }

    const projectNameOnChange = (e) => {
      console.log('projectNameOnChange: ', e)
      setCurrentProjectName(e || '')
    }

    const projectNameOnSearch = (e) => {
      console.log('projectNameOnSearch: ', e) 
      if(e.length >= 3 || e.length === 0) {
        loadProjectName(cateogry?.category_ID, e, action?.id)
      }
    }


    const getProvinceAndLocationItems = (tb_location_typeNew, cateogry) => {
      const result = [];

      result.push(
        <Select.Option value="0"  style={{display: 'flex'}}>
          {/* <LocationOn style={{color: "#f4762a", marginTop: '6px'}} /> */}
          <span style={{display: 'float', paddingTop: '-6px'}} >{t('province')}</span>
        </Select.Option>
      )

      if(cateogry !== undefined) {
        result.push(
          ...tb_location_typeNew?.sort(d => Number(d.location_type_order)).map((cate) => {
            return <Select.Option value={`${cate.location_type_ID}`}  style={{display: 'flex'}}>
              {`${cate[`location_type_name_${locale.toUpperCase()}`]}`}
            </Select.Option> 
          })
        )

        result.push(
          (`${cateogry?.category_project}` === '1' || 
            !cateogry)?  // อสังหา
              <Select.Option value={'-1'} >{t('project-name')}</Select.Option>
            : undefined 
        )
      }
      
      return result;
    }

    return (
        <div style={{width: '100%'}}>
          <div className="show-on-desktop-only" 
            style={{paddingTop: '30px', paddingBottom: '40px', ...props.searchbgClass }}>
            { 
              isBrowser? 
                  <AdBannerTop style={{margins: 'auto', height: '90px', width: '990px'}}/>
              : undefined
            }
            {/* <Row justify="center">
              <Col xs={0} sm={20} style={{paddingTop: '0px'}}>   */}
            {
              isBrowser? 
              <div style={{width: '83%', margin: 'auto'}}>
                { 
                  !props.loading ? 
                  <Row justify="center" >
                  {/* <Input.Group compact style={{display: 'flex', justifyContent: 'center'}}> */}
                      {/* <Row> */}

                      <Col xs={24} sm={12} md={2} lg={2} >
                        <Select size="large"  style={{width: '100%'}}
                            className='search-by-options-selector'
                            value={action ? action.id: 1}
                            onChange={onActionChange}
                          >
                              {
                                actionList && actionList.length && actionList.map((action) => {
                                  return <Select.Option value={action.id} data={action}>{action.action}</Select.Option>
                                })
                              }
                        </Select>
                      </Col>
                      <Col xs={24} sm={12} md={3} lg={3} >
                        <Select size="large"  style={{width: '100%' }}  
                          className='search-by-options-selector'
                          onChange={onCategoryChange}
                          value={cateogry? cateogry.category_ID: 0}
                        >
                            <Select.Option value={0} >{t('estate')}</Select.Option>
                            {
                              tb_categoryNew && tb_categoryNew.length && tb_categoryNew.filter(d => d.category_Status === 1)
                                .map((cate) => { 
                                  return <Select.Option value={cate.category_ID}>
                                    {cate[`category_Name_${locale.toUpperCase()}`]}
                                  </Select.Option> 
                                })
                            }
                        </Select>
                      </Col>
                      <Col xs={24} sm={7} md={4} lg={4} >
                        <Select size="large" className='search-by-options-selector'
                          value = {`${selectedProviceIDOrLocationTypeID}`}
                          style={{ width: '100%' }} onChange={onProvinceOrLocationTypeChange} >
                          {
                            getProvinceAndLocationItems(tb_location_typeNew, cateogry)
                          }
                        </Select>
                      </Col>
                      <Col xs={24} sm={17} md={7} lg={7} >
                        { 
                          isProjectName ?
                          <Select
                            showSearch
                            value={currentProjectName}
                            placeholder={cascaderPlaceholder}
                            className='search-by-options-selector'
                            style={{width: '100%'}} 
                            // style={props.style}
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            size="large" 
                            onSearch={projectNameOnSearch}
                            onChange={projectNameOnChange}
                            notFoundContent={null}
                            options={(projectNameList || [])}
                        />:
                          <Cascader style={{width: '100%'}} 
                            className='search-by-options-selector'
                            options={level1List || []}   
                            // loadData={loadDataLevel2}
                            size="large" 
                            placeholder={cascaderPlaceholder}
                            onChange={onCascaderChange}
                            value={cascaderValue}
                            showSearch={{ filter }}
                            changeOnSelect
                            />
                        }
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={5} >
                        <Select defaultValue="price" bordered={true} style={{width: '100%'}}  
                          className='search-by-options-selector'
                          size="large"
                          placement={'bottomRight'}
                          dropdownMatchSelectWidth={false}
                          dropdownRender={ menu => {
                            console.log("Menu: ", menu);
                            return <PriceSelect 
                              menu={menu}
                              onPriceAfterChange={onPriceAfterChange}
                              onMaxListClickGo={onMaxListClickGo}
                            /> 
                          }}
                        >
                          <Select.Option value="price">
                            <div style={{textAlign: 'center'}}>
                              {`${t('price')} ${formatPriceSearch(priceMin)} - ${formatPriceSearch(priceMax, locale)}`}
                            </div>
                          </Select.Option>
                        </Select>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={3} >
                        <Button type="primary" size="large" style={{ width: '100%', borderRadius: 0 }} onClick={onSearch}>{ `${t('search')}`}</Button>
                      </Col>
                      {/* </Row> */}
                    {/* </Input.Group> */}
                  </Row>
                  : undefined
                }
              </div>
              : undefined
            }
              {/* </Col>
            </Row> */}
          </div>
          <MobileView>
            <MobileCategory categoryTable={props.categoryTable}
              actionsTable={props.actionsTable}
            />
            {/* { 
              isMobile? 
              <Row justify="center" style={{ width: '100%', marginTop: '20px', marginBottom: '-20px'}}>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <AdBannerTop style={{margins: 'auto'}}/>
                </Col>
              </Row>: undefined
            } */}
            {/* <MobileSearch t={t} 
              openModal={props.isMobileFilterOpen} 
              onMobileSearchClose={onMobileSearchClose} 
              onMobileSearchOk={onMobileSearchOk} 
              level1List={level1List} 
              cascaderPlaceholder={cascaderPlaceholder} 
              onCascaderChange={onCascaderChange}
              cascaderValue={cascaderValue}
              filter={filter}
              onActionChange={onActionChange}
              onCategoryChange={onCategoryChange} 
              onPriceAfterChange={onPriceAfterChange}
              priceMin={priceMin}
              priceMax={priceMax}
              handleChange={onProvinceOrLocationTypeChange}
              /> */}
          </MobileView>
        </div>
    )
}