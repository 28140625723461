// import PropTypes from 'prop-types';
// import { 
//     // Row, Col,
//     Tag,
//     //  Divider, Image, Avatar, Modal
// } from 'antd';

// import { UserOutlined,
//     EditOutlined, EllipsisOutlined, SettingOutlined,
//     HeartOutlined,
//     WechatOutlined
// } from '@ant-design/icons';
// import { 
//     // Card, CardContent, 
//     Paper, 
//     // CardActions as MUICardActions 
// } from '@mui/material';
// import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
// import moment from 'moment';

//@ts-ignore
import styles from './css/recommend.module.scss'

import { 
    // unSerializePHP, 
    // getFieldWithlocale, 
    convertUnitTypeToString,
    // addFacilitiesName,
    formatPrice
} from '../../utils/utils';

import Link from 'next/link';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    // faClock,
    // faBed, faCar, 
    // faBath, faVectorSquare,
    // faBuilding,
    // faMountain,
    // faHeart,
    faPhone,
    // faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import { 
    // faClock,
    faHeart as faHeartRegular,
    // faCalendar,
    faComment
} from '@fortawesome/free-regular-svg-icons';
// import { MyImage } from '../myImage';
// import { CardDetail } from './cardDetail';
import { AppointmentState, defaultAppointmentState, inquiryDefaultState, InquiryState, PopOverState } from './CardActions';
import { Dispatch, memo, SetStateAction, useState } from 'react';
// import { cardDataToUrl } from '../../utils/cardData2Url';
import React from 'react';
// import moment from 'moment';
import dynamic from 'next/dynamic';
import { ProjectCards } from './cards/projectcard';
import { NormalCard } from './cards/normalcard';

const sourcePic = 'https://cdn.9asset.com/download/project_image';
const sourcePicProperty = 'https://cdn.9asset.com/download/property_gallery' // 96295/property_Images_260420211619454824728_thumb.jpg'

const DynamicProjectCards = dynamic(() => import('./cards/projectcard')
  .then(module => module.ProjectCards), { 
        ssr: false,
        loading: () => <div className={styles.cardContainerOutter}>Loading...</div> 
    })

const DynamicNormalCard = dynamic(() => import('./cards/normalcard')
  .then(module => module.NormalCard), { 
        ssr: false, 
        loading: () => <div className={styles.cardContainerOutter}>Loading...</div> 
    })

export enum PropertyTypes {
    Project, 
    Normal
}


// const convertNullToEmptyStr = (value) => {
//     if(value === null) return '';

//     return value;
// }
export const generateName = (locale, d, type) => {
    let name;
    const localeUpperCase = locale.toUpperCase();
    if(d.property) {
        name = d.property.name[localeUpperCase] || d.property.name[localeUpperCase];
    } 

    if(type === PropertyTypes.Project) {
        name = d.project.name[localeUpperCase];
    }
    return name || '-';
}
export const generateLocation = (locale, d) => {
    const languageUpperCase = locale.toUpperCase();
    const district = get(d, `district.name.${languageUpperCase}`);
    const amphur = get(d, `amphur.name.${languageUpperCase}`);
    const province = get(d, `province.name.${languageUpperCase}`);
    let road = get(d, `project.road.${languageUpperCase}`, undefined);
    if(road === '-') road = undefined;

    let locations = [];
    if(road) locations.push(`${locale === 'th'? 'ถ.': ''}${road}${locale === 'en'? 'Rd.': ''}`);
    if(district) locations.push(district);
    if(amphur) locations.push(amphur);
    if(province) locations.push(province);

    return locations.join(' ');
}

export const generateImage = (locale, d, type) => {
    console.log('xxxx latestData - generateImage: ', d)
    if(type === PropertyTypes.Project) {
        return `${sourcePic}/${d.project?.id}/${d.project?.images}`;
    }
    
    return d?.property?.images || `${sourcePic}/${d.project?.id}/${d.project?.images}` 
}

export const generateImageFallback = (locale, d, type) => {
    if(type === PropertyTypes.Project) {
        return `${sourcePic}/${d.project.id}/${d.project.images}`;
    }
    return d.property?.images?.replace('property_gallery', 'property_image') || `${sourcePic}/${d.project.id}/${d.project.images}` 
}

const generatePrice = (d: any) => {
    if(d.property) {
        return d.property.sell || d.project.price; 
    }

    return d.project.price;
}
const cardActions = () => {
    return [
        <FontAwesomeIcon icon={faPhone} size="lg" />,
        // <FontAwesomeIcon icon={faCalendar} size="lg" />
        <FontAwesomeIcon icon={faComment} size="lg" />,
        <FontAwesomeIcon icon={faHeartRegular} size="lg" />,
    ]
}

const renderSellAndRent = (t, locale, d, sellvalue, rentvalue) => {
    let text  = `${t('ขาย', { ns: 'common' })}${locale !== 'th' ? ' ' : ''}`;
    text += `/${t('เช่า', { ns: 'common' })}${locale !== 'th' ? ' ' : ''}`;
    text += ` ${d.project?.category?.name[locale.toUpperCase()] || ''}`; 
    
    return <div className={styles.price}>
            <div style={{paddingRight: '5px', color: 'black', width: '100%'}}>
                { text }
            </div>
            <div style={{fontWeight: 'bold'}}>
                <span>{`${formatPrice(sellvalue, locale, false)}`}</span>
                <span style={{color:'#888888'}}>{`/ `}</span>
                <span style={{color:'#87d068'}}>{`${formatPrice(rentvalue, locale)}`}</span>
            </div>
    </div>
}

const advertisementCard = (d) => {
    // return <Col xs={24} sm={24} md={8} lg={8} xl={6} key={d.id} >
    //     <Paper   style={{height: '100%'}}>
    //         <Card
    //             key={d.id}
    //             hoverable
    //             className={styles.normalcard}
    //             // actions={cardActions()}
    //             style={{height: '100%'}}
    //             cover={
    //                 <div className={styles.cardImagecontainer} >
    //                     <div className={styles.exclusiveLabel}>ประกาศ Exclusive</div>
    //                     <MyImage className={styles.cardImage} alt={null} src={``} width="100%" height="100%" 
    //                         imageurlfallback={``}
    //                         onError={function(){
    //                             console.log('image error xxxxxxxx')
    //                         }} 
    //                     />
    //                 </div>
    //             }
    //         >
    //         </Card>
    //     </Paper>
    // </Col>
}

const renderPrice = (text, price, locale, d, styleName) => {
    return <div className={styleName}>
        {/* <spn> */}
            <div style={{paddingRight: '5px', color: 'black', width: '100%'}}>
                { `${text}${locale !== 'th' ? ' ' : ''}${d.project?.category?.name[locale.toUpperCase()] || ''}`}
            </div>
            <div style={{fontWeight: 'bold'}}>{
                `${formatPrice(price, locale)}`
            }</div>
        {/* </span> */}
    </div>
}

const convertPropertyNew = (type: PropertyTypes, data, locale, facilities, t, onClick, onMouseDown, action,
  popOverState?,
  setPopOverState?,
  inquiryState?: InquiryState,
  setInquiryState?: Dispatch<SetStateAction<InquiryState>>,
  appointmentState?: AppointmentState,
  setAppointmentState?: Dispatch<SetStateAction<AppointmentState>>,
  ) => {
    console.log("🚀 ~ file: index.tsx ~ line 128 ~ convertPropertyNew ~ type", type, locale, popOverState, setInquiryState)
    console.log("🚀 ~ file: index.tsx:130 ~ convertPropertyNew ~ data", action)
    // console.log("🚀 ~ file: index.tsx ~ line 128 ~ convertPropertyNew ~ type", type, locale)
    const language = locale.toUpperCase();

    const getPropertyPrice = (d: any, t, locale) => {
        // console.log('price_redemption: ', d.property.price_redemption) 
        if(d?.property && Number(d.property.sell) > 0){
            const price = generatePrice(d); 
            
            if((d?.property?.price_rent || d?.property?.price_rent_min)
                && Number(d.property.price_rent || d.property.price_rent_min) > 0) {

                const rent = Number(d.property.price_rent || d.property.price_rent_min);

                return renderSellAndRent(t, locale, d, price, rent) 
            } else {
                return renderPrice(t('ขาย', { ns: 'common' }), price, locale, d, styles.price);
            }
        } else if((d?.property?.price_rent || d?.property?.price_rent_min)
            && Number(d.property.price_rent || d.property.price_rent_min) > 0) {

            return renderPrice(t('เช่า', { ns: 'common' }), d.property.price_rent || d.property.price_rent_min, locale, d, 
                styles.pricerent); 
            
        } else if(d?.property && Number(d.property.price_lease) > 0){
            return renderPrice(t('lease', { ns: 'common' }), d.property.price_lease, locale, d, styles.price);

           
        }  else if(d?.property?.price_mortgage 
            && Number(d.property.price_mortgage) > 0) {
            return renderPrice(t('lease', { ns: 'common' }), d.property.price_mortgage, locale, d, styles.price);
            
        } else if(d?.property?.price_redemption 
            && Number(d.property.price_redemption) > 0) {
            // console.log('price_redemption: ', d.property.price_redemption) 
            return renderPrice(t('redemption', { ns: 'common' }), d.property.price_redemption, locale, d,styles.price);
            
        } else {
            return undefined;
        }
    }
    
    if(type === PropertyTypes.Project) {
        console.log('PropertyTypes.Project: ', locale)

        return data && data.items && data.items.map((d) => {
            const name = generateName(language, d, type);
            const location = generateLocation(language, d);
            const imageUrl = generateImage(language,d, type);
            const price = generatePrice(d);
            // let projectName = locale === 'th' ? d.project_Name_TH: d.project_Name_EN;
            // const Developed = locale === 'th' ? d.project.developed.TH: d.project.developed.EN;
            const Developed = d.project.developed[language];

            // const myfacilities = unSerializePHP(getFieldWithlocale(d, 'project_Facilities', locale));
            // console.log('fac: ', addFacilitiesName(myfacilities, facilities));
            const parseUnits = d.project.units.map(d => {
                return `${convertUnitTypeToString(d.unit_Type, t)} ${d.unit_Sqm} ${t('square meter', { ns: 'common' })}`;
            }) 
        
            
            // console.log('fac', d)
            return (
                // <Col xs={24} sm={12} md={8} lg={6} xl={6} key={d.project_ID}>
                    <>
                        <ProjectCards 
                            onClick={onClick}
                            onMouseDown={onMouseDown} 
                            d={d} 
                            locale={locale} 
                            t={t} 
                            name={name} 
                            imageUrl={imageUrl} 
                            location={location} 
                            parseUnits={parseUnits} 
                            price={price} 
                            Developed={Developed} 
                            popOverState={popOverState} 
                            setPopOverState={setPopOverState} 
                            inquiryState={inquiryState} 
                            setInquiryState={setInquiryState} 
                            appointmentState={appointmentState} 
                            setAppointmentState={setAppointmentState}                        
                            />
                        {
                        // <Card
                        //     key={d.project.id}
                        //     hoverable
                        //     className={styles.projectcard}
                        //     cover={
                        //         <div className={styles.cardImagecontainer}
                        //         onClick={() => onClick(d)}
                        //         onMouseDown={(e)=> onMouseDown(e, d)}
                        //      >
                        //             <a href={`${cardDataToUrl(d,  t('โครงการ'), locale, t)}`}>
                        //             <MyImage className={styles.cardImage} alt={name || null} 
                        //                 src={`${imageUrl}`} width="100%" height="100%" 
                        //             />
                        //             </a>
                        //         </div>

                        //     }
                        //     actions={CardActions(
                        //    { id: d.project.id,
                        //     type: 'project',
                        //     locale,
                        //     popOverState,
                        //     setPopOverState,
                        //     inquiryState,
                        //     setInquiryState,
                        //     appointmentState,
                        //     setAppointmentState,
                        //     imageURL: imageUrl,
                        //     name: name,
                        //     details: d,}
                        //     )}
                        // > <div
                        //     onClick={() => onClick(d)}
                        //     onMouseDown={(e)=> onMouseDown(e, d)}>
                        //     <Card.Meta title={<div className={styles.cardmeta}>
                        //         <Link legacyBehavior className="nav-link" href={`${cardDataToUrl(d, t('โครงการ'), locale, t)}`} itemProp="url">
                        //             {name}
                        //         </Link>
                        //     </div>} />
                        //     <p className={styles.cardlocationcontainer}>{location}</p>
                        //     <p>
                        //         { 
                        //             d.property && d.property.sales === 1 ? <Tag color="#f50">{t('ขาย', { ns: 'common' })}</Tag> // edit
                        //                 : d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า', { ns: 'common' })}</Tag>: undefined  // edit
                        //         }
                        //         {/* { d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า')}</Tag>: undefined } */}
                        //     </p> 
                        //     <p className={styles.projectprice}>{`${formatPrice(price, locale)}`}</p>
                        //     <div className={styles.unitsection}>
                        //         {
                        //             parseUnits.slice(0,3).map((u) => {
                        //                 return <p className={styles.unittype} >{u} </p>
                        //             })
                        //         }
                        //     </div>
                        //     <Row  justify="space-between" align="middle" className={styles.postUserProjectContainer}>
                        //         <Col span={12}>
                        //             <div className={styles.postuser}>
                        //                 {/* <Avatar  size="small" icon={<UserOutlined />} style={{marginRight: '5px'}} /> */}
                        //                 {`${Developed}`}
                        //             </div>
                        //         </Col>             
                        //     </Row> 
                        //     {/* <p className={styles.cardfooter}>{Developed}</p> */}
                        //     <p className={styles.cardfooter}>
                               
                        //     </p>
                        //     </div>
                        // </Card>
                        }
                        {
                            // <div className={styles.cardContainerOutter}>
                            //      <div className={styles.cardHeader}>
                            //         <div className={styles.cardImagecontainer}
                            //                 onClick={() => onClick(d)}
                            //                 onMouseDown={(e)=> onMouseDown(e, d)}
                            //             >
                            //             <a href={`${cardDataToUrl(d,  t('โครงการ'), locale, t)}`}>
                            //                 <MyImage className={styles.cardImage} alt={name || null} 
                            //                     src={`${imageUrl}`} width="100%" height="100%" 
                            //                 />
                            //             </a>
                            //         </div>
                            //     </div>
                            //     <div className={styles.cardbody}>
                            //         <div className={styles.cardTitle} 
                            //             onClick={() => onClick(d)}
                            //             onMouseDown={(e)=> onMouseDown(e, d)}>
                            //             <Link legacyBehavior className="nav-link" href={`${cardDataToUrl(d, t('โครงการ'), locale, t)}`} itemProp="url">
                            //                 {name}
                            //             </Link> 
                            //         </div>
                            //         <div>
                            //             <p className={styles.cardlocationcontainer}>{location}</p>
                            //             <p>
                            //                 { 
                            //                     d.property && d.property.sales === 1 ? <div style={{color: "#f50"}}>{t('ขาย', { ns: 'common' })}</div> // edit
                            //                         : d.property && d.property.rent === 1 ? <div style={{color: "#87d068"}}>{t('เช่า', { ns: 'common' })}</div>: undefined  // edit
                            //                 }
                            //                 {/* { d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า')}</Tag>: undefined } */}
                            //             </p> 
                            //             <p className={styles.projectprice}>{`${formatPrice(price, locale)}`}</p>
                            //             <div className={styles.unitsection}>
                            //                 {
                            //                     parseUnits.slice(0,3).map((u) => {
                            //                         return <p className={styles.unittype} >{u} </p>
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className={styles.postUserProjectContainer}>
                            //                 <div className={styles.postuser}>
                            //                     {/* <Avatar  size="small" icon={<UserOutlined />} style={{marginRight: '5px'}} /> */}
                            //                     {`${Developed}`}
                            //                 </div>
                            //             </div> 
                            //             {/* <p className={styles.cardfooter}>{Developed}</p> */}
                            //             <p className={styles.cardfooter}>
                                        
                            //             </p>
                            //         </div>
                            //      </div>
                            //      <div className={styles.cardActions}>
                            //        {
                            //             CardActions(
                            //                { id: d.project.id,
                            //                 type: 'project',
                            //                 locale,
                            //                 popOverState,
                            //                 setPopOverState,
                            //                 inquiryState,
                            //                 setInquiryState,
                            //                 appointmentState,
                            //                 setAppointmentState,
                            //                 imageURL: imageUrl,
                            //                 name: name,
                            //                 details: d,})
                            //        } 
                            //      </div>
                            // </div>
                        }
                    </>
            )
        })
    } else if(type === PropertyTypes.Normal) {
        // console.log('PropertyTypes.Normal: ', locale, data)

        return data && data.items && data.items.map((d) => {
            if (!d.property) {
                return <></>;
            }

            if(d?.advertisement) {
                return advertisementCard(d); 
            }
            const name = generateName(locale, d, type);
            const location = generateLocation(locale, d);
            const imageUrl = generateImage(locale,d, type);
            const imageurlfallback = generateImageFallback(locale,d, type);

            // console.log(`test image: ${d.property_ID}`, imageUrl, type, d)
            const price = generatePrice(d); 
           
            // const { 
            //     bedroom,
            //     bathroom,
            //     property_size,
            //     floor,
            //     parking,
            // } = d.property;
            // let detail = `${bedroom} นอน ${bathroom} น้ำ ${property_size} ตร.ม. ชั้น ${floor} ที่จอดรถ ${parking} คัน`; 
            
            // console.log('fac', styles.cardstyle.na)
            return (
                <>
                        <NormalCard 
                            onClick={onClick} 
                            onMouseDown={onMouseDown} 
                            d={d} 
                            action={action} 
                            locale={locale} 
                            t={t} 
                            name={name} 
                            imageUrl={imageUrl} 
                            imageurlfallback={imageurlfallback} 
                            location={location} 
                            popOverState={popOverState} 
                            setPopOverState={setPopOverState} 
                            inquiryState={inquiryState}
                            setInquiryState={setInquiryState} 
                            appointmentState={appointmentState} 
                            setAppointmentState={setAppointmentState} 
                            getPropertyPrice={getPropertyPrice} 
                            language={language} />
                        {
        //                     <Card
        //                     key={d.property.id}
        //                     hoverable
        //                     className={styles.normalcard}
        //                     actions={CardActions(
        //                         { 
        //                             id: d.property.id,
        //                          type: 'property',
        //                          locale,
        //                          popOverState,
        //                          setPopOverState,
        //                          inquiryState,
        //                          setInquiryState,
        //                          appointmentState,
        //                          setAppointmentState,
        //                          imageURL: imageUrl,
        //                          name: name,
        //                          details: d,})}
        //                     cover={
        //                         <div className={styles.cardImagecontainer}
        //                         onClick={() => onClick(d)}
        //                         onMouseDown={(e)=> onMouseDown(e, d)}
        //                         >
        //                             {
        //                                 (d?.property?.boots_status === 1) ?  
        //                                     <div className={styles.exclusiveLabel}>ประกาศ Exclusive</div>
        //                                     : undefined
        //                             }
        //                             <a href={`${cardDataToUrl(d, action, locale, t)}`}>
        //                             <MyImage className={styles.cardImage} alt={name || null} src={`${imageUrl}`} width="100%" height="100%" 
        //                                 imageurlfallback={`${imageurlfallback}`}
        //                                 onError={function(){
        //                                     console.log('image error xxxxxxxx')
        //                                     // this.style.display='none';
        //                                 }} 
        //                             />
        //                             </a>
        //                         </div>
        //                     }
        //                 >
        //                    <div        
        //                         onClick={() => onClick(d)}
        //                         onMouseDown={(e)=> onMouseDown(e, d)}
        //                     >
        //                     <Card.Meta title={<div className={styles.cardmeta}>
        //                             <Link legacyBehavior  className="nav-link" href={`${cardDataToUrl(d, action, locale, t)}`} itemProp="url">
        //                                 {name}
        //                             </Link>
        //                         </div>
        //                     } />
        //                     <p className={styles.cardlocationcontainer} >{location}</p>
        //                     {/* <p>
        //                         { d.property && d.property.sales === 1 ? <Tag color="#f50">{t('ขาย')}</Tag>: undefined }
        //                         { d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า')}</Tag>: undefined }
        //                     </p>  */}
        //                     <div className={styles.cardDetailContainer}>
        //                         <div className={styles.priceContainer} >
        //                         {
        //                             getPropertyPrice(d, t, locale)
        //                         }
        //                         </div>
        //                         {/* <p>{d.property_ID}</p> */}
        //                         {/* <div className={styles.category}>{d.project?.category?.name[locale.toUpperCase()] || ''}</div> */}
        //                         <div className={styles.detail}>
        //                             {/* {detail} */}
        //                             <CardDetail property={d.property} project={d.project} 
        //                                 carddetail={d.carddetail} action={action} t={t} locale={locale} /> 
        //                         </div>
        //                     </div>
        //                     {
        //                     //     <Row  justify="space-between" align="middle" className={styles.postUserContainer}>
        //                     //     <Col span={12}>
        //                     //         <div className={styles.postuser}>
        //                     //             {/* <Avatar  size="small" icon={<UserOutlined />} style={{marginRight: '5px'}} /> */}
        //                     //             {`${d.user[`first_name_${language}`] || ''} ${d.user[`last_name_${language}`] || ''}`}
        //                     //         </div>
        //                     //     </Col>             
        //                     //     <Col span={12}>
        //                     //         <div style={{display:"inline-flex", float: 'right'}}>
        //                     //             <FontAwesomeIcon icon={faClock} size="xs" className={styles.detailIcon} />
        //                     //             <div className={styles.updatedate} >{`${moment(d.property?.update).fromNow()}`}</div>
        //                     //         </div>
        //                     //     </Col>
        //                     // </Row>
        // }
        //                     {/* <p className={styles.cardfooter}> */}
        //                         {/* <p>{`อัพเดทล่าสุด​: ${d.property.update}`}</p> */}
        //                         {/* <p>{`Add Date​: ${d.property.adddate}`}</p> */}
        //                         {/* <p>{`End date: ${d.property.property_End_date}`}</p> */}
        //                         {/* <p>{`boot date: ${d.property.boots_date}`}</p> */}
        //                     {/* </p> */}
        //                     </div>
        //                 </Card>
                        }
                        {
                        // <div className={styles.cardContainerOutter}>
                        //     <div className={styles.cardHeader}>
                        //         <div className={styles.cardImagecontainer}
                        //             onClick={() => onClick(d)}
                        //             onMouseDown={(e)=> onMouseDown(e, d)}
                        //             >
                        //             {
                        //                 (d?.property?.boots_status === 1) ?  
                        //                     <div className={styles.exclusiveLabel}>ประกาศ Exclusive</div>
                        //                     : undefined
                        //             }
                        //             <a href={`${cardDataToUrl(d, action, locale, t)}`}>
                        //             <MyImage className={styles.cardImage} alt={name || null} src={`${imageUrl}`} width="100%" height="100%" 
                        //                 imageurlfallback={`${imageurlfallback}`}
                        //                 onError={function(){
                        //                     console.log('image error xxxxxxxx')
                        //                     // this.style.display='none';
                        //                 }} 
                        //             />
                        //             </a>
                        //         </div> 
                        //     </div>
                        //     <div className={styles.cardbody}>
                        //         <div className={styles.cardTitle}       
                        //                 onClick={() => onClick(d)}
                        //                 onMouseDown={(e)=> onMouseDown(e, d)}
                        //             >
                        //             <div className={styles.cardmeta}>
                        //                 <Link legacyBehavior  className="nav-link" href={`${cardDataToUrl(d, action, locale, t)}`} itemProp="url">
                        //                     {name}
                        //                 </Link>
                        //             </div>
                        //         </div>
                        //         <div>
                        //             <p className={styles.cardlocationcontainer} >{location}</p>
                        //             {/* <p>
                        //                 { d.property && d.property.sales === 1 ? <Tag color="#f50">{t('ขาย')}</Tag>: undefined }
                        //                 { d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า')}</Tag>: undefined }
                        //             </p>  */}
                        //             <div className={styles.cardDetailContainer}>
                        //                 <div className={styles.priceContainer} >
                        //                 {
                        //                     getPropertyPrice(d, t, locale)
                        //                 }
                        //                 </div>
                        //                 {/* <p>{d.property_ID}</p> */}
                        //                 {/* <div className={styles.category}>{d.project?.category?.name[locale.toUpperCase()] || ''}</div> */}
                        //                 <div className={styles.detail}>
                        //                     {/* {detail} */}
                        //                     <CardDetail property={d.property} project={d.project} 
                        //                         carddetail={d.carddetail} action={action} t={t} locale={locale} /> 
                        //                 </div>
                        //             </div>
                        //             <div className={styles.postUserContainer}>
                        //                     <div className={styles.postuser}>
                        //                         {/* <Avatar  size="small" icon={<UserOutlined />} style={{marginRight: '5px'}} /> */}
                        //                         {`${d.user[`first_name_${language}`] || ''} ${d.user[`last_name_${language}`] || ''}`}
                        //                     </div>
                        //                     <div style={{display:"flex", justifyContent: 'right', alignItems: 'center', flexFlow: '1 1 auto'}}>
                        //                         <FontAwesomeIcon icon={faClock} size="xs" className={styles.detailIcon}/>
                        //                         <div className={styles.updatedate} >{`${moment(d.property?.update).fromNow()}`}</div>
                        //                     </div>
                        //             </div>
                        //         </div>
                        //     </div>
                        //     <div className={styles.cardActions}>
                        //         {
                        //             CardActions({ 
                        //             id: d.property.id,
                        //             type: 'property',
                        //             locale,
                        //             popOverState,
                        //             setPopOverState,
                        //             inquiryState,
                        //             setInquiryState,
                        //             appointmentState,
                        //             setAppointmentState,
                        //             imageURL: imageUrl,
                        //             name: name,
                        //             details: d,})
                        //         }
                        //     </div>
                        // </div>
                        }
                </>
            )
        })
    }
}
export interface IRecommentProps {
    data: any[];
    locale: string;
    title: string;
    type: PropertyTypes;
    facilities: any;
    linkpath: any; 
    onClick: any;
    onMouseDown: any;
}

const popOverDefaultState = {
    visible: false,
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    id: 0,
    developer: '',
    salesEmail: '',
    salesMobile: '',
    photo: '',
};

export const Recommend = (props: IRecommentProps) => {
    const {data = [], locale, title,  type, facilities, linkpath, onClick, onMouseDown} = props;
    const { t } = useTranslation();
    const [popOverState, setPopOverState] = useState<PopOverState>(popOverDefaultState)
    const [inquiryState, setInquiryState] = useState<InquiryState>(inquiryDefaultState)
    const [appointmentState, setAppointmentState] =
      useState<AppointmentState>({
        ...defaultAppointmentState,
      })

    const handleonClick = (e) => {
        onClick(e, linkpath);
    }

    const handleonMouseDown = (e, d) => {
        onMouseDown(e, d, linkpath);
    }

    return (
        <div style={{width: '100%'}}>
            <div className={ styles.recommendheader } key={title}>
                <div className={ styles.title } >{title}</div>
                <div className={styles.seeall } >
                    <Link legacyBehavior href={`${linkpath??'/'}`}>{t('seeall', { ns: 'home' })}</Link>
                </div>
            </div>
            <div style={{height: '20px'}}></div>
            {/* <Row gutter ={[16, 24]} style={{paddingTop: '20px', paddingBottom: '20px'}} > */}
            <div className={styles.cardItemContainer}>
                {
                    convertPropertyNew(type, data, locale, facilities, t, handleonClick, handleonMouseDown, 
                        t('ขาย', { ns: 'common' }), 
                        popOverState, setPopOverState, 
                        inquiryState, setInquiryState, 
                        appointmentState, setAppointmentState)
                }
            </div>
            <div style={{height: '20px'}}></div>
        </div>
    )
}

type OnClick = (e: any) => void;
type onMouseDown = (e: any, d: any, linkpath: any) => void;
type PropertyListProps = {
    data: any,
    locale: string,
    action: string,
    type: PropertyTypes,
    onClick: OnClick,
    onMouseDown: onMouseDown
}
export const PropertyList = memo(({data, locale, action,  type , onClick, onMouseDown}: PropertyListProps) => {
    const { t } = useTranslation('common');
    
    const [popOverState, setPopOverState] = useState<PopOverState>(popOverDefaultState)
    const [inquiryState, setInquiryState] = useState<InquiryState>(inquiryDefaultState)

    const [appointmentState, setAppointmentState] =
        useState<AppointmentState>({
        ...defaultAppointmentState,
        })
    
    const items = () => convertPropertyNew(type, data, locale, [], t, onClick, onMouseDown, action,
        popOverState, setPopOverState, 
        inquiryState, setInquiryState, 
        appointmentState, setAppointmentState );

    return (
        // <div style={{width: '100%'}}>
            // <Row gutter={[{ xs: 8, sm: 8, md: 8, lg: 8 }, {xs: 16, sm: 16, md: 16, lg: 16 }]}
                // className={ styles.listcontainer }  >
            <div className={styles.cardItemContainer}>
                {
                   items()
                }
            </div>
            // </Row>
        // </div>
    )
});

