import { useEffect } from 'react';
import styles from './googleAd.module.scss';

export const AdBanner = (props) => {
  useEffect(() => {
    // try {
    //   //@ts-ignore
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (err) {
    //   console.log('google ads error: ', err);
    // }
    // const ads = document.getElementsByClassName("adsbygoogle").length;
    // for (var i = 0; i < ads; i++) {
      try {
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) { }
    // }
  }, []);

  // const addStyle = { textAlign: 'center', width: '100%', padding: '40px', 
  //   ...props.style,
  // }

  return (
    <>
     <ins className={`adsbygoogle ${styles.example_responsive_1}`}
     style={{display:"block", ...props.style}}
     data-ad-client= {`${props.adclient || 'ca-pub-3702887933016265'}`}
     data-ad-slot={`${props.adSlot || '1627420869'}`}
    //  data-ad-format="auto"
    //  data-full-width-responsive="true"
     ></ins>
    </>
  );
};

export const AdBannerVertical = (props) => {
  useEffect(() => {
    // try {
    //   //@ts-ignore
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (err) {
    //   console.log('google ads error: ', err);
    // }
    // const ads = document.getElementsByClassName("adsbygoogle").length;
    // for (var i = 0; i < ads; i++) {
      try {
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) { }
    // }
  }, []);


  return (
    <>
     <ins className={`adsbygoogle`}
     style={{display:"block", ...props.style}}
     data-ad-client= {`${props.adclient || 'ca-pub-3702887933016265'}`}
     data-ad-slot={`${props.adSlot || '1627420869'}`}
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    </>
  );
};

export const AdBannerTop = (props) => {
  useEffect(() => {
    // try {
    //   //@ts-ignore
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (err) {
    //   console.log('google ads error: ', err);
    // }
    // const ads = document.getElementsByClassName("adsbygoogle").length;
    // for (var i = 0; i < ads; i++) {
      try {
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) { }
    // }
  }, []);

  // const addStyle = { textAlign: 'center', width: '100%', padding: '40px', 
  //   ...props.style,
  // }

  return (
    <>
     <ins className={`adsbygoogle ${styles.example_responsive_top}`}
     style={{display:"block", ...props.style}}
     data-ad-client= {`${props.adclient || 'ca-pub-3702887933016265'}`}
     data-ad-slot={`9422541016`}
    //  data-ad-format="auto"
     ></ins>
    </>
  );
};
