import {
  MailOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  WechatOutlined,
} from '@ant-design/icons'
// import { faCalendar } from '@fortawesome/free-regular-svg-icons'
// import { faList, faPhone } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import MenuIcon from '@mui/icons-material/Menu'
import {
  CardMedia,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Tag,
  Typography,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import moment, { Moment } from 'moment'
import dynamic from 'next/dynamic'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  getAgentByProjectId,
  getAgentByPropertyId,
  getAppointedSlots,
  handleAppointmentActivity,
  handleGetAppointmentAvailable,
  handleInquiryActivity,
} from '../../utils/requests'
import {
  getImageUrl,
  getInitialSlots,
  getProjectImageUrl,
  getUser,
  getUserSync,
} from '../../utils/utils'
import {
  AppointmentTime,
  handleLocaleProjectName,
} from '../recommended/AppointmentActivity'
import {
  AppointmentState,
  defaultAppointmentState,
  inquiryDefaultState,
  InquiryState,
} from '../recommended/CardActions'
import { useLocalStorage } from '../../ui-components/hooks/useLocalStorage'
import { InquiryDetailCard } from './InquiryDetail'
import { t } from 'i18next'

const LeafletMapSearch = dynamic(
  () => import('../LeafletMapSearch'),
  { ssr: false },
)

const { Text } = Typography

interface ActivitiesProps {
  details: any
  t: any
  locale: string

  onSellerInfoChanged?: (seller: any) => void;
}

export default function Activities({ ...props }: ActivitiesProps) {
  const [seller, setSeller] = useState<any>(null)
  const [appointmentState, setAppointmentState] =
    useState<AppointmentState>({
      ...defaultAppointmentState,
    })

  let type = ''
  let id = 0
  let images = []
  if (props.details?.property_ID) {
    type = 'property'
    id = props.details?.property_ID
    images =
      props.details.images?.map((m) => {
        const urls = getImageUrl(m.property_ID, m.gallery_Images)

        return {
          original: urls.full,
          thumbnail: urls.thumb,
          originalClass: 'full-image-class',
        }
      }) || []
  } else if (props.details?.project_ID) {
    type = 'project'
    id = props.details?.project_ID
    images =
      props.details.images?.map((m) => {
        const urls = getProjectImageUrl(
          props.details.project_ID,
          m.gallery_Images,
        )

        return {
          original: urls.full,
          thumbnail: urls.thumb,
          originalClass: 'full-image-class',
        }
      }) || []
  }
  const name =
    props.details.project_Name_?.[props.locale] ||
    props.details.project_Name_EN ||
    props.details.project_Name_TH ||
    props.details.project_Name_CN ||
    '-'
  const imageURL = images[0]?.thumbnail || ''

  const [appointmentTimeState, setAppointmentTimeState] =
    useState<AppointmentTime>({
      dateTimeStart: null,
      dateTimeEnd: null,
    })
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [datePickerLoading, setDatePickerLoading] =
    useState<boolean>(false)
  const [isAvailable, setIsAvailable] = useState<boolean>(true)
  const [referenceIdState, setReferenceIdState] = useState<string>('')
  const [inquiryState, setInquiryState] = useState<InquiryState>(
    inquiryDefaultState,
  )
  const [defaultTimeSlot, setDefaultTimeSlot] = useState<string>('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isOpenModal, setIsOpenModal] = useState<any>({
    call: false,
    inquiry: false,
    appointment: false,
    requirement: false,
  })

  const isReloadNeedRef = useRef<boolean>(false);
  const [userInfo] = useLocalStorage<any>(`9asset.userinfo`);

  async function handleOpenInquiry() {
    setButtonLoading(true)

    const user = await getUserSync()
    if (!user.id) {
      // push a user to login page
      document.location.href =
        process.env.NEXT_PUBLIC_LOGIN_URL ||
        'https://my.9asset.com/login?redirect=https://my.9asset.com'
    }

    const { email, id: buyerId } = user

    let firstName = ''
    let lastName = ''
    switch (props.locale) {
      case 'th':
        firstName = user.nameTh
        lastName = user.lastnameTh
        break
      case 'en':
        firstName = user.nameEn
        lastName = user.lastnameEn
        break
      case 'cn':
        firstName = user.nameCn
        lastName = user.lastnameCn
        break
      default:
        firstName = user.nameTh
        lastName = user.lastnameTh
        break
    }

    let mobile = user.mobile
    let mobileCountryCode = user.mobileCode

    // that means the first 3 chars is a country code
    if (mobile.includes('+')) {
      mobileCountryCode = mobile.slice(0, 3)
      mobile = mobile.slice(3)
    }

    setInquiryState({
      ...inquiryState,
      visible: true,
      id: id,
      firstName,
      lastName,
      email,
      mobile,
      mobileCountryCode,
      buyerId,
    })

    setButtonLoading(false)
  }

  async function handleOpenAppointment() {
    try {
      setButtonLoading(true)

      const user = await getUserSync()
      if (!user.id) {
        // push a user to login page
        document.location.href =
          process.env.NEXT_PUBLIC_LOGIN_URL ||
          'https://my.9asset.com/login?redirect=https://my.9asset.com'
      }

      setAppointmentState({
        ...appointmentState,
        visible: true,
        id,
      })

      let initialAppointedSlots = getInitialSlots(moment())

      const response = await getAppointedSlots(
        type,
        id,
        user.id,
        initialAppointedSlots,
      )
      let data = response.data

      let firstAvailableSlot = data.appointedSlots.find(
        (slot: any) => slot.isAvailable === true,
      )
      while (!firstAvailableSlot) {
        initialAppointedSlots = getInitialSlots(
          moment().add(1, 'day'),
        )

        const response = await getAppointedSlots(
          type,
          id,
          user.id,
          initialAppointedSlots,
        )
        data = response.data

        firstAvailableSlot = data.appointedSlots.find(
          (slot: any) => slot.isAvailable === true,
        )
      }

      setAppointmentTimeState({
        ...appointmentTimeState,
        dateTimeStart: moment(firstAvailableSlot?.start || null),
        dateTimeEnd: moment(firstAvailableSlot?.end || null),
      })

      setDefaultTimeSlot(
        `${moment(firstAvailableSlot?.start || null).format(
          'HH:mm',
        )} - ${moment(firstAvailableSlot?.end || null).format(
          'HH:mm',
        )}`,
      )

      setAppointmentState({
        ...appointmentState,
        visible: true,
        type: type === 'project' ? 'project' : 'property',
        id,
        appointedSlots: [...data.appointedSlots],
        project_Latitude: data.project_Latitude,
        project_Longitude: data.project_Longitude,
        project_Zoom: data.project_Zoom,
        project_Name_TH: data.project_Name_TH,
        project_Name_EN: data.project_Name_EN,
        project_Name_CN: data.project_Name_CN,
        appointedXLon: data.project_Longitude,
        appointedYLat: data.project_Latitude,
        appointedVenue: name,
      })

      setButtonLoading(false)
    } catch (error) {
      console.error(error)
      setButtonLoading(false)
    }
  }

  async function handleResetSlots({
    current,
    user,
  }: {
    current: Moment | null
    user: any
  }) {
    // todo: get slot time in hour and minute from backend of the selected date
    if (!current) return

    let initialAppointedSlots = getInitialSlots(current)

    const response = await getAppointedSlots(
      type,
      id,
      user.id,
      initialAppointedSlots,
    )
    let data = response.data

    let firstAvailableSlot = data.appointedSlots.find(
      (slot: any) => slot.isAvailable === true,
    )
    while (!firstAvailableSlot) {
      initialAppointedSlots = getInitialSlots(moment().add(1, 'day'))

      const response = await getAppointedSlots(
        type,
        id,
        user.id,
        initialAppointedSlots,
      )
      data = response.data

      firstAvailableSlot = data.appointedSlots.find(
        (slot: any) => slot.isAvailable === true,
      )
    }

    setAppointmentTimeState({
      ...appointmentTimeState,
      dateTimeStart: moment(firstAvailableSlot?.start || null),
      dateTimeEnd: moment(firstAvailableSlot?.end || null),
    })

    setAppointmentState({
      ...appointmentState,
      appointedSlots: [...data.appointedSlots],
    })

    setDefaultTimeSlot(
      `${moment(firstAvailableSlot?.start || null).format(
        'HH:mm',
      )} - ${moment(firstAvailableSlot?.end || null).format(
        'HH:mm',
      )}`,
    )
  }

  // hard code for v4 antd date picker,
  // since bodyRender is supported in v5 only
  useEffect(() => {
    const datePicker = document.querySelector(
      '.ant-picker-input',
    ) as HTMLElement
    if (!datePicker) return

    const inputElement = datePicker.childNodes[0] as HTMLInputElement
    if (!inputElement) return

    inputElement.style.textAlign = 'center'
    inputElement.style.fontWeight = 'bold'
    inputElement.style.fontSize = '1.2rem'
    inputElement.style.color = '#fa8c16'
  }, [buttonLoading])

  // hard code for v4 antd Select,
  // since bodyRender is supported in v5 only
  useEffect(() => {
    const selectSlotElement =
      document.querySelector('#select-slots')?.parentNode
        ?.parentNode || null
    if (!selectSlotElement) return

    const optionElement =
      (selectSlotElement.querySelector(
        '.ant-select-selection-item',
      ) as HTMLElement) ||
      (selectSlotElement.querySelector(
        '.ant-select-selection-placeholder',
      ) as HTMLElement)
    if (!optionElement) return

    optionElement.style.textAlign = 'center'
    optionElement.style.fontWeight = 'bold'
    optionElement.style.fontSize = '1.2rem'
    optionElement.style.color = '#fa8c16'
  }, [appointmentTimeState, buttonLoading])

  useEffect(() => {
    props.onSellerInfoChanged?.(seller);
  }, [seller]);

  // use effect to get seller
  useEffect(() => {
    async function effectWrapper() {
      let seller = null
      if (type === 'property') {
        const { data } = await getAgentByPropertyId(
          props.details?.property_ID,
        )
        if (!data) return

        seller = data
      } else if (type === 'project') {
        const { data } = await getAgentByProjectId(
          props.details?.project_ID,
        )
        if (!data) return

        seller = data
      }

      setSeller(seller)
    }

    effectWrapper()
    return () => {
      setSeller(null)
    }
  }, [props.details])

  // initial useEffect
  useEffect(() => {
    const user = getUser()
    if (!user?.id) return

    handleGetAppointmentAvailable(type, id, user.id).then(
      ({ data }) => {
        setIsAvailable(data?.isAvailable === false ? false : true)
      },
    )

    handleOpenAppointment()

    handleOpenInquiry()
  }, [])

  const ContactComponent = useCallback(({ onFinish, onCancel }) => {
    const getMobile = () => {
      if (userInfo) {
        return `${
          seller?.user_mobile_code
            ? `+${seller?.user_mobile_code}`
            : ''
        } ${seller?.mobile || '-'}`;
      } else {
        return `${seller?.user_mobile_code || ''} ${seller?.mobile ? (seller.mobile as string).replace(/([0-9]{3})([0-9]+)([0-9]{3})/g, '$1-xxxx-$3') : ''}`.trim()
      }
    }

    const getEmail = () => {
      if (userInfo) {
        return `${seller?.user_email || '-'}`;
      } else {
        return `xxxx@email.com`
      }
    }

    return (
      <>
        {/* Title */}
        <Row>
          <Col
            span={24}
            style={{ fontSize: 'x-large', color: '#FA8C16' }}
          >
            Contact Information
          </Col>
          <Col span={24}>
            <Divider
              style={{
                marginTop: '4px',
                marginBottom: '4px',
              }}
            />
          </Col>
        </Row>

        {/* Details */}
        {/* Corporate agent */}
        {seller?.sellerCompany?.company_name_?.[props.locale] ||
        seller?.sellerCompany?.company_name_en ||
        seller?.sellerCompany?.company_name_th ||
        seller?.sellerCompany?.company_name_cn ? (
          <Row
            style={{
              marginTop: '1rem',
            }}
            align="middle"
          >
            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Row>
                <Avatar
                  icon={<TeamOutlined />}
                  src={
                    `https://www.9asset.com/legacy-service/v1/company/image/company-profile/${seller?.sellerCompany?.company_id}` ||
                    ''
                  }
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </Row>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Typography.Text
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {seller?.sellerCompany?.company_name_?.[
                      props.locale
                    ] ||
                      seller?.sellerCompany?.company_name_en ||
                      seller?.sellerCompany?.company_name_th ||
                      seller?.sellerCompany?.company_name_cn ||
                      'ชื่อบริษัท'}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                type="primary"
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  onFinish()
                }}
              >
                {t('goToBuyerCenter')}
              </Button>
              <Button
                type="default"
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  onCancel()
                }}
              >
                Stay on this page
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {/* Agent */}
        <Row
          style={{
            marginTop: '1rem',
          }}
          align="middle"
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Row>
              <Avatar
                icon={<UserOutlined />}
                src={seller?.userimage || ''}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Row>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {`${`${
                    seller?.user_title_?.[props.locale] ||
                    seller?.user_title_EN ||
                    seller?.user_title_TH ||
                    seller?.user_title_CN ||
                    ''
                  } `}
            ${`${
              seller?.first_name_?.[props.locale] ||
              seller?.first_name_EN ||
              seller?.first_name_TH ||
              seller?.first_name_CN ||
              '-'
            } `}

            ${` ${
              seller?.last_name_?.[props.locale] ||
              seller?.last_name_EN ||
              seller?.last_name_TH ||
              seller?.last_name_CN ||
              ''
            }`}`}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '.875rem',
                  }}
                >
                  {seller?.sellerCompany?.company_id
                    ? 'Cooperate Agent '
                    : 'Freelance Agent '}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '.875rem',
                    // assign color based on verified status
                    color:
                      seller?.sellerCompany?.seller_status ===
                      'approved'
                        ? '#52c41a'
                        : '#ff4d4f',
                  }}
                >{`${
                  seller?.sellerCompany?.seller_status === 'verified'
                    ? 'Verified'
                    : 'Unverified'
                }`}</Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '.875rem',
                  }}
                >{`ID ${seller?.user_profile_id}`}</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Mobile & email */}
        <Row
          style={{
            marginTop: '1rem',
          }}
          align="middle"
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Row>
              <Avatar
                icon={<WechatOutlined />}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Row>
          </Col>
          <Col
            span={20}
            style={{
              // pale blue bg
              backgroundColor: '#e6f7ff',
              borderRadius: '.5rem',
              padding: '.5rem',
            }}
          >
            <Row>
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '1rem',
                  }}
                >{getMobile()}</Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '1rem',
                  }}
                >{getEmail()}</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        { !userInfo && 
        <Row>
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          ></Col>
          <Col
            span={20}
            style={{
              // pale blue bg
              borderRadius: '.5rem',
              padding: '.5rem',
            }}
          >
            <Button
              type="primary"
              style={{
                fontWeight: 'bold',
                width: '100%',
              }}
              onClick={() => {
                const loginRequested = new CustomEvent('loginrequested', { detail: { reload: false } });
                window.addEventListener('logged-in', handleInfoLoggedIn);
                window.addEventListener('login-cancelled', handleInfoLoginCancelled);
                window.dispatchEvent(loginRequested);
              }}
            >
              Contact
            </Button>
          </Col>
        </Row>
        }

        {/* Listing */}
        <Row
          style={{
            marginTop: '1rem',
          }}
          align="middle"
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Row>
              <Avatar
                icon={<ShopOutlined />}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Row>
          </Col>
          <Col
            span={20}
            style={{
              // pale blue gray
              backgroundColor: '#f0f2f5',
              borderRadius: '.5rem',
              padding: '.5rem',
            }}
          >
            <Row>
              {/* Selling */}
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '1rem',
                  }}
                >{`Selling `}</Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '1rem',
                    color: '#f4762a',
                  }}
                >
                  {/* random 0-15 */}
                  {` ${Math.floor(Math.random() * 15) + 1} `}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '.8rem',
                  }}
                >{` Listings`}</Typography.Text>
              </Col>
              {/* Renting */}
              <Col span={24}>
                <Typography.Text
                  style={{
                    fontSize: '1rem',
                  }}
                >{`Renting `}</Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '1rem',
                    color: '#f4762a',
                  }}
                >
                  {/* random 0-15 */}
                  {` ${Math.floor(Math.random() * 15) + 1} `}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '.8rem',
                  }}
                >{` Listings`}</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }, [seller, props.locale])

  const AppointmentComponent = useCallback(() => {
    const user = getUser();

    return (
      <>
        {buttonLoading ? (
          <Row
            gutter={[0, 16]}
            style={{
              marginTop: '1rem',
            }}
          >
            <Col span={24}>
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
              />
            </Col>
          </Row>
        ) : (
          <>
            {/* Title */}
            <Row>
              <Col
                span={24}
                style={{ fontSize: 'x-large', color: '#FA8C16' }}
              >
                {props.t('Schedule to View')}
              </Col>
              <Col span={24}>
                <Divider
                  style={{
                    marginTop: '4px',
                    marginBottom: '4px',
                  }}
                />
              </Col>
            </Row>

            <Row
              gutter={[0, 16]}
              style={{
                marginTop: '1rem',
              }}
            >
              <Col span={24}>
                <Row
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                  }}
                >
                  <Col span={6}>
                    <CardMedia
                      component="img"
                      sx={{ height: '5rem' }}
                      image={imageURL}
                      alt="Live from space album cover"
                    />
                  </Col>
                  <Col
                    span={18}
                    style={{
                      color: '#333333',
                      padding: '4px',
                    }}
                  >
                    {name}
                  </Col>
                </Row>
              </Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DatePicker
                  key={appointmentState.id}
                  style={{ width: '100%' }}
                  allowClear={false}
                  defaultValue={moment(appointmentTimeState.dateTimeStart || undefined)}
                  onChange={async (current: Moment | null) => {
                    const user = getUser()
                    if (!user?.id) return

                    setDatePickerLoading(true)
                    await handleResetSlots({ current, user })

                    setDatePickerLoading(false)
                  }}
                  disabledDate={(current) => {
                    return current && current < moment().endOf('day')
                  }}
                />
              </Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {datePickerLoading === true ? (
                  <Skeleton
                    active
                    title={false}
                    paragraph={{
                      rows: 1,
                    }}
                  />
                ) : (
                  <Select
                    showSearch={false}
                    id="select-slots"
                    loading={buttonLoading}
                    key={appointmentState.id}
                    style={{ width: '100%' }}
                    placeholder="Select a slot"
                    defaultValue={defaultTimeSlot}
                    notFoundContent={ !user?.id ? 'Please login' : undefined}
                    onChange={(value: string) => {
                      // get selected slot
                      const selectedSlot =
                        appointmentState.appointedSlots.find(
                          (slot, index) => index === Number(value),
                        )

                      setAppointmentTimeState({
                        ...appointmentTimeState,
                        dateTimeStart: moment(
                          selectedSlot?.start || undefined,
                        ),
                        dateTimeEnd: moment(
                          selectedSlot?.end || undefined,
                        ),
                      })
                    }}
                    options={appointmentState.appointedSlots.map(
                      (
                        slot: {
                          start: string
                          end: string
                          isAvailable: boolean
                        },
                        index: number,
                      ) => {
                        const { start, end, isAvailable } = slot;
                        const startDate = moment(start)
                        const endDate = moment(end)

                        return {
                          value: `${index}`,
                          label: `${startDate.format(
                            'HH:mm',
                          )} - ${endDate.format('HH:mm')}`,
                          disabled: !isAvailable,
                        }
                      },
                    )}
                  />
                )}
              </Col>

              <Col span={24} key={appointmentState.project_Latitude}>
                <Row
                  align="middle"
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tag
                      color="blue"
                      style={{
                        color: '#0071CB',
                        backgroundColor: '#E6F7FF',
                      }}
                    >
                      <Row justify="center" align="middle">
                        <Col
                          span={24}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Meet
                        </Col>
                        <Col
                          span={24}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          @
                        </Col>
                      </Row>
                    </Tag>
                  </Col>
                  <Col
                    span={18}
                    style={{
                      color: '#0071CB',
                    }}
                  >
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: false,
                      }}
                      style={{
                        marginBottom: '0',
                      }}
                    >
                      {`${appointmentState.appointedVenue}`}
                    </Typography.Paragraph>
                  </Col>
                </Row>

                <LeafletMapSearch
                  lat={appointmentState.project_Latitude}
                  lng={appointmentState.project_Longitude}
                  label={handleLocaleProjectName({
                    locale: props.locale,
                    project_Name_TH:
                      appointmentState?.project_Name_TH || props.details?.project_Name_TH || '',
                    project_Name_EN:
                      appointmentState?.project_Name_EN || props.details?.project_Name_EN || '',
                    project_Name_CN:
                      appointmentState?.project_Name_CN || props.details?.project_Name_CN || '',
                  })}
                  appointmentState={appointmentState}
                  setAppointmentState={setAppointmentState}
                  enabledSearch={true}
                />
              </Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="primary"
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                  loading={buttonLoading}
                  onClick={() => {
                    // confirmation
                    if (
                      !window.confirm(
                        'Are you sure to create this appointment?',
                      )
                    )
                      return

                    setButtonLoading(true);
                    const user = getUser()
                    if (!user?.id) {
                      isReloadNeedRef.current = true;
                      const loginRequested = new CustomEvent('loginrequested', { detail: { reload: false } });
                      window.addEventListener('logged-in', handleMeetLoggedIn);
                      window.addEventListener('login-cancelled', handleMeetLoginCancelled);
                      window.dispatchEvent(loginRequested);
                      return;
                    } else {

                      if (
                        !appointmentTimeState.dateTimeStart ||
                        !appointmentTimeState.dateTimeEnd
                      ) {
                        return alert('Please select a slot')
                      }

                    // create new appointment
                      handleAppointmentActivity(
                        type,
                        id,
                        user.id,
                        appointmentTimeState.dateTimeStart.toDate(),
                        appointmentTimeState.dateTimeEnd.toDate(),
                        appointmentState.appointedXLon,
                        appointmentState.appointedYLat,
                        appointmentState.appointedVenue,
                      ).then(({ data }) => {
                        const { id: appointmentId, referenceId } = data
                        const { id } = appointmentState

                        setReferenceIdState(referenceId)

                        setAppointmentState({
                          ...defaultAppointmentState,
                          id,
                          appointmentId,
                          visibleSucceed: true,
                          visible: false,
                        })

                        setAppointmentTimeState({
                          dateTimeStart: null,
                          dateTimeEnd: null,
                        })

                        setButtonLoading(false)

                        setIsOpenModal({
                          ...isOpenModal,
                          appointment: false,
                        })
                      })
                    }
                  }}
                >
                  Send Now
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    )
  }, [
    appointmentState,
    appointmentTimeState,
    buttonLoading,
    datePickerLoading,
    defaultTimeSlot,
    imageURL,
    name,
    props.locale,
    props.t,
    type,
    id,
  ])

  const handleInfoLoginCancelled = () => {
    isReloadNeedRef.current = false;
    window.removeEventListener('logged-in', handleInfoLoggedIn);
    window.removeEventListener('login-cancelled', handleInfoLoginCancelled);
  }

  const handleInfoLoggedIn = async () => {
    window.location.reload();
  }

  const handleLoginCancelled = () => {
    setButtonLoading(false);
    isReloadNeedRef.current = false;
    window.removeEventListener('logged-in', handleLoggedIn);
    window.removeEventListener('login-cancelled', handleLoginCancelled);
  }

  const handleLoggedIn = async () => {

    const user = await getUserSync();
    console.log(user);
    window.removeEventListener('login-cancelled', handleLoginCancelled);
    window.removeEventListener('logged-in', handleLoggedIn);
    if (user && user.id) {
      const { error, message, data } = await handleInquiryActivity(
                    type,
                    id,
                    user.id,
                    inquiryState.message,
      );
      if (error === true) {
        console.error(message)
      }
      if (!data?.id) {
        console.error('inquiry id was not sent back')
      }

      setInquiryState({
        ...inquiryState,
        visible: false,
        visibleSucceed: true,
        inquiryId: data.id,
        id: data.id
      })

      setReferenceIdState(data.referenceId)

      setButtonLoading(false)

      setIsOpenModal({
        ...isOpenModal,
        inquiry: false,
      })
    }
  }

  const handleMeetLoginCancelled = () => {
    setButtonLoading(false);
    isReloadNeedRef.current = false;
    window.removeEventListener('logged-in', handleMeetLoggedIn);
    window.removeEventListener('login-cancelled', handleMeetLoginCancelled);
  }

  const handleMeetLoggedIn = async () => {

    const user = await getUserSync();
    window.removeEventListener('login-cancelled', handleMeetLoginCancelled);
    window.removeEventListener('logged-in', handleMeetLoggedIn);
    if (user && user.id) {
      if (
        !appointmentTimeState.dateTimeStart ||
        !appointmentTimeState.dateTimeEnd
      ) {
        return alert('Please select a slot')
      }

    // create new appointment
      handleAppointmentActivity(
        type,
        id,
        user.id,
        appointmentTimeState.dateTimeStart.toDate(),
        appointmentTimeState.dateTimeEnd.toDate(),
        appointmentState.appointedXLon,
        appointmentState.appointedYLat,
        appointmentState.appointedVenue,
      ).then(({ data }) => {
        const { id: appointmentId, referenceId } = data
        const { id } = appointmentState

        setReferenceIdState(referenceId)

        setAppointmentState({
          ...defaultAppointmentState,
          id,
          appointmentId,
          visibleSucceed: true,
          visible: false,
        })

        setAppointmentTimeState({
          dateTimeStart: null,
          dateTimeEnd: null,
        })

        setButtonLoading(false)

        setIsOpenModal({
          ...isOpenModal,
          appointment: false,
        })
      })
    }
  }
  
  const InquiryComponent = useCallback((props) => {

    const getType = () => {
      return props.detail?.property_ID ? 'property' : 'project';
    }

    const getName = () => {
      return props.detail?.property_ID ? props.detail?.property_name_en : props.detail?.project_Name_EN ;
    }

    return (
      <>
        {' '}
        {/* Title */}
        <Col
          span={24}
          style={{ fontSize: 'x-large', color: '#FA8C16' }}
        >
          Inquiry Detail
        </Col>
        <Col span={24}>
          <Divider
            style={{
              marginTop: '4px',
              marginBottom: '4px',
            }}
          />
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Form
            layout="vertical"
            style={{
              width: '100%',
            }}
          >
            <Form.Item
              style={{
                marginBottom: '.75rem',
              }}
            >
              <Input
                prefix={
                  <UserOutlined
                    style={{
                      color: '#757575',
                    }}
                  />
                }
                style={{
                  pointerEvents: 'none',
                }}
                value={`${inquiryState.firstName}  ${inquiryState.lastName}`}
              />
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: '.75rem',
              }}
            >
              <Input
                prefix={
                  !inquiryState.mobileCountryCode || inquiryState.mobileCountryCode === '+66' ? (
                    <Text style={{ color: '#757575' }}>🇹🇭</Text>
                  ) : (
                    <Text style={{ color: '#757575' }}>🇨🇳</Text>
                  )
                }
                style={{
                  pointerEvents: 'none',
                }}
                value={inquiryState.mobile}
              />
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: '.75rem',
              }}
            >
              <Input
                prefix={
                  <MailOutlined
                    style={{
                      color: '#757575',
                    }}
                  />
                }
                style={{
                  pointerEvents: 'none',
                }}
                value={inquiryState.email}
              />
            </Form.Item>
            <Form.Item>
              <Input.TextArea
                defaultValue={`I am interested in this ${getType()} at ${getName()}. Please send me more detial.`}
                onChange={(e) => {
                  setInquiryState({
                    ...inquiryState,
                    message: e.target.value,
                  })
                }}
                maxLength={200}
                rows={5}
                showCount
              />
            </Form.Item>
          </Form>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            loading={buttonLoading}
            type="primary"
            style={{
              fontWeight: 'bold',
            }}
            onClick={async () => {
              setButtonLoading(true);
              const user = await getUser();
  
              if (!user?.id) {
                isReloadNeedRef.current = true;
                const loginRequested = new CustomEvent('loginrequested', { detail: { reload: false } });
                window.addEventListener('logged-in', handleLoggedIn);
                window.addEventListener('login-cancelled', handleLoginCancelled);
                window.dispatchEvent(loginRequested);
                return;
              } else {
                const { error, message, data } =
                  await handleInquiryActivity(
                    type,
                    id,
                    inquiryState.buyerId,
                    inquiryState.message,
                  )
                if (error === true) {
                  console.error(message)
                }
                if (!data?.id) {
                  console.error('inquiry id was not sent back')
                }

                setInquiryState({
                  ...inquiryState,
                  visible: false,
                  visibleSucceed: true,
                  inquiryId: data.id,
                  id: data.id
                })

                setReferenceIdState(data.referenceId)

                setButtonLoading(false)

                setIsOpenModal({
                  ...isOpenModal,
                  inquiry: false,
                })
              }
              
            }}
          >
            Send Now
          </Button>
        </Col>
      </>
    )
  }, [
    buttonLoading,
    id,
    inquiryState,
    name,
    props.locale,
    props.t,
    type,
  ])

  const handelBottomMenuClicked = (key: string) => {
    switch(key) {
      case 'call':
        setIsOpenModal({ ...isOpenModal, call: true });
        return;
      case 'inquiry':
        setIsOpenModal({ ...isOpenModal, inquiry: true });
        return;
      case 'appointment':
        setIsOpenModal({ ...isOpenModal, appointment: true });
        return;
      case 'favorite':
        setIsOpenModal({ ...isOpenModal, requirement: true });
        return;
      default:
        break;
    }
  }

  return (
    <>
      {/* Contact */}
      {!isMobile && (
        <Paper elevation={3}>
          <Card>
            <ContactComponent 
              onFinish={() => {
                setIsOpenModal({
                  ...isOpenModal,
                  call: false,
                })
                window.location.href = `${window.location.origin}/buyer/requirements`
              }} 
              onCancel={() => {
                setIsOpenModal({
                  ...isOpenModal,
                  call: false,
                })
              }} />
          </Card>
        </Paper>
      )}
      <Modal
        destroyOnClose
        centered
        footer={null}
        width={360}
        open={isOpenModal.call}
        onCancel={() => {
          setIsOpenModal({
            ...isOpenModal,
            call: false,
          })
          if (isReloadNeedRef.current === true) {
            window.location.reload();
          }
        }}
      >
        <ContactComponent onFinish={() => {  }} onCancel={() => {}} />
      </Modal>

      {/* Appointment */}
      {!isMobile && (
        <Paper elevation={3} key={defaultTimeSlot}>
          <Card
            style={{
              marginTop: '1rem',
            }}
          >
            <AppointmentComponent />
          </Card>
        </Paper>
      )}
      <Modal
        destroyOnClose
        centered
        footer={null}
        width={360}
        open={isOpenModal.appointment}
        onCancel={() => {
          setIsOpenModal({
            ...isOpenModal,
            appointment: false,
          })
          if (isReloadNeedRef.current === true) {
            window.location.reload();
          }
        }}
      >
        <AppointmentComponent />
      </Modal>

      {/* Inquiry */}
      {!isMobile && (
        <Paper elevation={3}>
          <Card style={{ marginTop: '1rem' }}>
            <InquiryComponent detail={props.details} />
          </Card>
        </Paper>
      )}
      {/* {!isMobile && (
        <InquiryDetailCard />
        <Paper elevation={3}>
          <Card style={{ marginTop: '1rem' }}>
            <InquiryComponent />
          </Card>
        </Paper>
      )} */}
      <Modal
        destroyOnClose
        centered
        footer={null}
        width={360}
        open={isOpenModal.inquiry}
        onCancel={() => {
          setIsOpenModal({
            ...isOpenModal,
            inquiry: false,
          })
          if (isReloadNeedRef.current === true) {
            window.location.reload();
          }
        }}
      >
        <InquiryComponent />
      </Modal>

      {/* Appointment Succeed Modal */}
      <Modal
        destroyOnClose
        centered
        footer={null}
        width={360}
        open={
          appointmentState.visibleSucceed &&
          appointmentState.id === id
        }
        onCancel={() => {
          setAppointmentState({
            ...appointmentState,
            visibleSucceed: false,
          })
          if (isReloadNeedRef.current === true) {
            window.location.reload();
          }
        }}
      >
        <Row gutter={[0, 16]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Title
              style={{
                fontSize: '1.4rem',
                fontWeight: 'bold',
                color: '#6cac19',
              }}
            >
              A new apointment time
            </Title>
          </Col>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '-26px',
            }}
          >
            <Text style={{ color: '#6cac19' }}>
              has been sent to seller
            </Text>
          </Col>

          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '26px',
            }}
          >
            <Text
              style={{
                color: '#757575',
                textAlign: 'right',
              }}
            >
              {`Appointment ID : `}
            </Text>
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'left',
              marginTop: '26px',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                color: '#FA8C16',
                textAlign: 'left',
              }}
            >
              {`${referenceIdState}`}
            </Text>
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text style={{ color: '#757575' }}>
              Please wait for seller contact you back soon.
            </Text>
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              type="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setAppointmentState({
                  ...appointmentState,
                  visibleSucceed: false,
                })
                window.location.href = `${window.location.origin}/buyer/requirements`
              }}
            >
              {t('goToBuyerCenter')}
            </Button>
            <Button
              type="default"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setAppointmentState({
                  ...appointmentState,
                  visibleSucceed: false,
                })
                if (isReloadNeedRef.current === true) {
                  window.location.reload();
                }
              }}
            >
              Stay on this page
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Inquiry succeed modal */}
      <Modal
        destroyOnClose
        centered
        open={inquiryState.visibleSucceed && inquiryState.id === id}
        footer={null}
        width={300}
        onCancel={() => {
          setInquiryState({
            ...inquiryState,
            visibleSucceed: false,
          })
        }}
      >
        <Row gutter={[0, 16]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Title
              style={{
                fontWeight: 'bold',
                color: '#6cac19',
              }}
            >
              Inquiry
            </Title>
          </Col>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '-26px',
            }}
          >
            <Text style={{ color: '#6cac19' }}>
              has been sent to seller
            </Text>
          </Col>

          <Col
            span={8}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '26px',
            }}
          >
            <Text
              style={{
                color: '#757575',
                textAlign: 'right',
              }}
            >
              {`Inquiry ID : `}
            </Text>
          </Col>
          <Col
            span={16}
            style={{
              display: 'flex',
              justifyContent: 'left',
              marginTop: '26px',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                color: '#FA8C16',
                textAlign: 'left',
              }}
            >
              {`${referenceIdState}`}
            </Text>
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text style={{ color: '#757575' }}>
              Please wait for seller contact you back soon.
            </Text>
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              type="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setInquiryState({
                  ...inquiryState,
                  visibleSucceed: false,
                })
                window.location.href = `${window.location.origin}/buyer/requirements`
              }}
            >
              {t('goToBuyerCenter')}
            </Button>
            <Button
              type="default"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setInquiryState({
                  ...inquiryState,
                  visibleSucceed: false,
                })
                if (isReloadNeedRef.current) {
                  window.location.reload();
                }
              }}
            >
              Stay on this page
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Bottom App bar */}
      {/* <BottomBar onMenuClicked={handelBottomMenuClicked} /> */}
      {/* {isMobile && (
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Toolbar>
            <IconButton
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
              }}
              aria-label="open call modal"
              onClick={() => {
                setIsOpenModal({
                  ...isOpenModal,
                  call: true,
                })
              }}
            >
              <Stack direction="column">
                <FontAwesomeIcon icon={faPhone} size="sm" />
                <Typography.Text
                  style={{
                    fontSize: '.75rem',
                  }}
                >
                  Call
                </Typography.Text>
              </Stack>
            </IconButton>

            <IconButton
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
              }}
              aria-label="open inquiry modal"
              onClick={() => {
                setIsOpenModal({
                  ...isOpenModal,
                  inquiry: true,
                })
              }}
            >
              <Stack direction="column">
                <FontAwesomeIcon icon={faPhone} size="sm" />
                <Typography.Text
                  style={{
                    fontSize: '.75rem',
                  }}
                >
                  Inquiry
                </Typography.Text>
              </Stack>
            </IconButton>

            <IconButton
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
              }}
              aria-label="open appointment modal"
              onClick={() => {
                setIsOpenModal({
                  ...isOpenModal,
                  appointment: true,
                })
              }}
            >
              <Stack direction="column">
                <FontAwesomeIcon icon={faCalendar} size="sm" />
                <Typography.Text
                  style={{
                    fontSize: '.75rem',
                  }}
                >
                  Appointment
                </Typography.Text>
              </Stack>
            </IconButton>

            <IconButton
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
              }}
              aria-label="open requirement modal"
            >
              <Stack direction="column">
                <FontAwesomeIcon icon={faList} size="sm" />
                <Typography.Text
                  style={{
                    fontSize: '.75rem',
                  }}
                >
                  Requirement
                </Typography.Text>
              </Stack>
            </IconButton>
          </Toolbar>
        </AppBar>
      )} */}
    </>
  )
}
