import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC8Yi6jarHdVaGs9bkwZhfPvQ_do0eZvuE",
  authDomain: "directed-radius-140921.firebaseapp.com",
  projectId: "directed-radius-140921",
  storageBucket: "directed-radius-140921.appspot.com",
  messagingSenderId: "866569999379",
  appId: "1:866569999379:web:5fb234f0b59c777c6e1be6"
};

class Myfirebase {
  private static instance: Myfirebase;
  
  public app: FirebaseApp;
  public auth: Auth;

  private constructor() {
    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);

    // this.auth.onAuthStateChanged((user) => {
    //     console.log('user: ', user)
    // })
  }

  public static getInstance(): Myfirebase {
    if (!Myfirebase.instance) {
      console.log('Init firebase App')
      Myfirebase.instance = new Myfirebase();
    }

    return Myfirebase.instance;
  }
}


export default Myfirebase.getInstance();
