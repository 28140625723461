import Link from 'next/link'
import { MyImage } from '../../myImage'
import styles from '../css/recommend.module.scss'
import { cardDataToUrl } from '../../../utils/cardData2Url';
import { formatPrice } from '../../../utils/utils';
import { CardActions } from '../CardActions';
import Paper from '@mui/material/Paper';

export const ProjectCards = ({
    onClick,
    onMouseDown,
    d,
    locale,
    t,
    name,
    imageUrl,
    location,
    parseUnits,
    price,
    Developed,
    popOverState,
    setPopOverState,
    inquiryState,
    setInquiryState,
    appointmentState,
    setAppointmentState
}) => {
    return (
        <div key={d.project_ID} className={styles.cardItem}>
            <Paper key={d.project.id} >
            <div className={styles.cardContainerOutter}>
                 <div className={styles.cardHeader}>
                    <div className={styles.cardImagecontainer}
                            onClick={() => onClick(d)}
                            onMouseDown={(e)=> onMouseDown(e, d)}
                        >
                        <a href={`${cardDataToUrl(d,  t('โครงการ'), locale, t)}`}>
                            <MyImage className={styles.cardImage} alt={name || null} 
                                src={`${imageUrl}`} width="100%" height="100%" 
                            />
                        </a>
                    </div>
                </div>
                <div className={styles.cardbody}>
                    <div className={styles.cardTitle} 
                        onClick={() => onClick(d)}
                        onMouseDown={(e)=> onMouseDown(e, d)}>
                        <Link legacyBehavior className="nav-link" href={`${cardDataToUrl(d, t('โครงการ'), locale, t)}`} itemProp="url">
                            {name}
                        </Link> 
                    </div>
                    <div>
                        <p className={styles.cardlocationcontainer}>{location}</p>
                        <p>
                            { 
                                d.property && d.property.sales === 1 ? <div style={{color: "#f50"}}>{t('ขาย', { ns: 'common' })}</div> // edit
                                    : d.property && d.property.rent === 1 ? <div style={{color: "#87d068"}}>{t('เช่า', { ns: 'common' })}</div>: undefined  // edit
                            }
                            {/* { d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า')}</Tag>: undefined } */}
                        </p> 
                        <p className={styles.projectprice}>{`${formatPrice(price, locale)}`}</p>
                        <div className={styles.unitsection}>
                            {
                                parseUnits.slice(0,3).map((u) => {
                                    return <p className={styles.unittype} >{u} </p>
                                })
                            }
                        </div>
                        <div className={styles.postUserProjectContainer}>
                            <div className={styles.postuser}>
                                {/* <Avatar  size="small" icon={<UserOutlined />} style={{marginRight: '5px'}} /> */}
                                {`${Developed}`}
                            </div>
                        </div> 
                        {/* <p className={styles.cardfooter}>{Developed}</p> */}
                        <p className={styles.cardfooter}>
                        
                        </p>
                    </div>
                 </div>
                 <div className={styles.cardActions}>
                   {
                        CardActions(
                           { id: d.project.id,
                            type: 'project',
                            locale,
                            popOverState,
                            setPopOverState,
                            inquiryState,
                            setInquiryState,
                            appointmentState,
                            setAppointmentState,
                            imageURL: imageUrl,
                            name: name,
                            details: d,})
                   } 
                 </div>
            </div>
            </Paper>
        </div>
    )
}