import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  OutlinedInput,
  FormHelperText,
  Container,
} from '@mui/material'
import { Row, Col, Card, Typography, Form, Button, Spin } from 'antd'
import { useRouter } from 'next/router'
import { useEffect, forwardRef } from 'react'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  getSubGroupCategory,
  getCateogry,
  getAllProjectOnlyData,
  addRequirement,
  getActionsRequirement,
  getTypeRequirement,
  getWelcomeRequirement,
  getPurpose,
  getProvinceWithAmphur,
  saveProjectProperty,
  getSearchList,
} from '../../utils/requests'

import MapsPanel from './mapsPanel'
import { ISelected } from './mapsPanel'

import dynamic from 'next/dynamic'
import styles from './leaveyourrequirement.module.scss'
import { getUser, getUserSync } from '../../utils/utils'
import { SuccessModal } from './SuccessModal'
import Highlighter from 'react-highlight-words'

import NumberFormat, { NumberFormatProps } from 'react-number-format'
import firebaseInit from '../../utils/firebaseInit'

const LeafletMapMultiMarkComponent = dynamic(
  () => import('../LeafletMapSearchMultiMark'),
  {
    ssr: false,
  },
)

export interface IAddRequirement {
  country: any
  action: number
  category: number
  project: string
  location: string
  priceFrom: number
  priceTo: number
  currency: string
  buyFor: number
  bedroomMin: number
  bedroomMax: number
  noUnitFrom: number
  noUnitTo: number
  descriptionTH: string
  descriptionEN: string
  descriptionCN: string
  type: number
  welcome: number
  user_id: string
  sizeFrom?: number
  sizeTo?: number
  sizeUnit?: string
  sizeId?: number
  landFrom?: string
  landTo?: string
  landSizeUnit?: string
  rentalPeriod: string
  rentalPeriodUnit: string
  spaceType: number
}

export interface ILeaveYourRequirement {
  t: any
}

export interface ICountry {
  code: string
  label: string
  phone: string
  suggested?: boolean | undefined
}

export const LeaveYourRequirement = (
  props: ILeaveYourRequirement,
) => {
  const { t } = props
  const { i18n } = useTranslation()
  const locale = useRouter().locale.toUpperCase()

  const router = useRouter()

  const [provinceWithAmphur, setProvinceWithAmphur] = useState([])

  const [provinceWithAmphurTH, setProvinceWithAmphurTH] = useState([])

  const [categoryList, setCategoryList] = useState([])

  const [actionList, setActionList] = useState([])

  const [spaceTypeList, setSpaceTypeList] = useState([
    {
      value: 1,
      label: 'ว่างเปล่า',
    },
    {
      value: 2,
      label: 'ร้านค้า',
    },
    {
      value: 3,
      label: 'โชว์รูม',
    },
    {
      value: 4,
      label: 'ขายของ',
    },
    {
      value: 5,
      label: 'จัดงาน',
    },
  ])

  const buyBudget = [
    '0',
    '300,000',
    '500,000',
    '1,000,000',
    '2,000,000',
    '3,000,000',
    '4,000,000',
    '5,000,000',
    '6,000,000',
    '7,000,000',
    '8,000,000',
    '9,000,000',
    '10,000,000',
    '15,000,000',
    '20,000,000',
    '30,000,000',
    '50,000,000',
    '100,000,000',
    '500,000,000',
    '1,000,000,000',
  ]

  const rentBudget = [
    '0',
    '2,000',
    '3,000',
    '5,000',
    '7,000',
    '10,000',
    '13,000',
    '15,000',
    '20,000',
    '25,000',
    '30,000',
    '40,000',
    '50,000',
    '80,000',
    '100,000',
    '150,000',
    '200,000',
    '500,000',
    '1,000,000',
  ]

  const [projectList, setProjectList] = useState([])

  const [buyForList, setBuyForList] = useState([])

  const [typeList, setTypeList] = useState([])

  const [welcomeList, setWelcomeList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const responses: any = await Promise.all([
        getCateogry(locale),
        getSubGroupCategory('1'),
        getActionsRequirement(locale),
        getTypeRequirement(locale),
        getWelcomeRequirement(locale),
        getPurpose(locale),
        getProvinceWithAmphur('EN'),
        getProvinceWithAmphur('TH'),
      ])

      const categoriesPhase = responses[0].data
        .sort((a: any, b: any) => a.category_Sort - b.category_Sort)
        .map((d) => {
          return {
            value: d.category_ID,
            label: d[`category_Name_${locale.toUpperCase()}`],
          }
        })
      setCategoryList(categoriesPhase)

      const actionsRequirement = responses[2].data?.filter(
        (e: any) => e.value != 1,
      )
      const typeRequirement = responses[3].data
      const welcomeRequirement = responses[4].data
      const purpose = responses[5].data
      const _provinceWithAmphur = responses[6].data
      const _provinceWithAmphurTHRaw = responses[7].data
      const _provinceWithAmphurTH = [
        { ..._provinceWithAmphurTHRaw[0], label: 'กรุงเทพมหานคร' },
        { ..._provinceWithAmphurTHRaw[0], label: 'กรุงเทพมหานครฯ' },
        ..._provinceWithAmphurTHRaw,
      ]

      setProvinceWithAmphur(_provinceWithAmphur)
      setProvinceWithAmphurTH(_provinceWithAmphurTH)
      setBuyForList(purpose)
      setTypeList(typeRequirement)
      setWelcomeList(welcomeRequirement)
      setActionList(actionsRequirement)

      loadRequirement()
    }

    fetchData().catch(console.error)
  }, [router])

  const [country, setCountry] = useState('')

  const [action, setAction] = useState('')

  const [type, setType] = useState('')

  const [bedroomMin, setBedroomMin] = useState('')

  const [bedroomMax, setBedroomMax] = useState('')

  const [sizeMin, setSizeMin] = useState('')

  const [sizeMax, setSizeMax] = useState('')

  const [sizeUnit, setSizeUnit] = useState('Sq.m')

  const [landFrom, setLandFrom] = useState('')

  const [landTo, setLandTo] = useState('')

  const [landSizeUnit, setLandSizeUnit] = useState('Sq.w')

  const [noUnitFrom, setNoUnitFrom] = useState('')

  const [noUnitTo, setNoUnitTo] = useState('')

  const [project, setProject] = useState('')

  const [projectNameTH, setProjectNameTH] = useState('')

  const [projectNameEN, setProjectNameEN] = useState('')

  const [projectNameCN, setProjectNameCN] = useState('')

  const [projectId, setProjectId] = useState(undefined)

  const [category, setCategory] = useState('')

  const [spaceType, setSpaceType] = useState('')

  const [descriptionTH, setDescriptionTH] = useState('')

  const [descriptionEN, setDescriptionEN] = useState('')

  const [descriptionCN, setDescriptionCN] = useState('')

  const [priceFrom, setPriceFrom] = useState('')

  const [priceTo, setPriceTo] = useState('')

  const [buyFor, setBuyFor] = useState('')

  const [location, setLocation] = useState<ISelected[]>([])

  const [welcome, setWelcome] = useState('')

  const [rentalPeriod, setRentalPeriod] = useState('')

  const [rentalPeriodUnit, setRentalPeriodUnit] = useState('year')

  const [successId, setSuccessId] = useState('')

  const [isError, setIsError] = useState(false)

  const [errorTitle, setErrorTitle] = useState('')

  const [errorDescription, setErrorDescription] = useState('')

  const [reRender, setReRender] = useState(false)

  const [selectedProjectName, setSelectedProjectName] = useState('')
  const [user, setUser] = useState<any | null>(null)
  const [token, setToken] = useState<string|null>(null);

  useEffect(() => {
    console.log('token changed');
    console.log(token);
  }, [token])

  const rentalUnitList =
    category == '11' || category == '17'
      ? ['day', 'week', 'month', 'year']
      : ['year', 'month']

  const [formValidate, setFormValidate] = useState<{
    [key: string]: boolean
  }>({
    action: true,
    property: true,
    location: true,
    priceFrom: true,
    priceTo: true,
    type: true,
    welcome: true,

    bedroomFrom: true,
    bedroomTo: true,
    sizeFrom: true,
    sizeTo: true,
    unitFrom: true,
    unitTo: true,
    landFrom: true,
    landTo: true,
    rentalPeriod: true,
    spaceType: true,
  })

  const [loading, setLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const [isLoadedStorePayload, setIsLoadedStorePayload] =
    useState(false)

  useEffect(() => {
    if (isLoadedStorePayload) {
      submitForm()
    }
  }, [isLoadedStorePayload])
  const loadRequirement = async () => {
    const storePayload = JSON.parse(
      localStorage.getItem('requirement'),
    )

    if (storePayload) {
      const user = await getUser()
      const user_id = user?.id

      if (user_id) {
        localStorage.removeItem('requirement')
        setAction(storePayload.action)
        setCategory(storePayload.category)
        setProject(storePayload.project)
        setProjectNameTH(storePayload.projectNameTH)
        setProjectNameEN(storePayload.projectNameEN)
        setProjectNameCN(storePayload.projectNameCN)
        setProjectId(storePayload.projectId)
        setLocation(storePayload.location)
        setPriceFrom(storePayload.priceFrom)
        setPriceTo(storePayload.priceTo)
        setBedroomMin(storePayload.bedroomMin)
        setBedroomMax(storePayload.bedroomMax)
        setSizeMin(storePayload.sizeMin)
        setSizeMax(storePayload.sizeMax)
        setSizeUnit(storePayload.sizeUnit)
        setLandFrom(storePayload.landFrom)
        setLandTo(storePayload.landTo)
        setLandSizeUnit(storePayload.landSizeUnit)
        setNoUnitFrom(storePayload.noUnitFrom)
        setNoUnitTo(storePayload.noUnitTo)
        setBuyFor(storePayload.buyFor)
        setType(storePayload.type)
        setWelcome(storePayload.welcome)
        setRentalPeriod(storePayload.rentalPeriod)
        setRentalPeriodUnit(storePayload.rentalPeriodUnit)
        setSpaceType(storePayload.spaceType)
        setDescriptionTH(storePayload.descriptionTH)
        setDescriptionEN(storePayload.descriptionEN)
        setDescriptionCN(storePayload.descriptionCN)

        setIsLoadedStorePayload(true)
      }
    }
  }

  async function getProjectList(_cateId) {
    let _project = await getAllProjectOnlyData(parseInt(_cateId), '')
    _project = _project.items

    setProjectList(_project)
  }

  const onCategoryChange = (e) => {
    setCategory(e.target.value.toString())
    getProjectList(
      categoryList.filter(
        (f) => f.value == e.target.value.toString(),
      )[0].value,
    )
    setProject('')
  }

  const onActionChange = (e) => {
    setAction(e.target.value.toString())
  }

  const onProjectChange = (e) => {
    if (e) {
      const value = e.option
      const isBkk = value.province && value.province.code === '10'
      setProject(value.project.name[locale] || value.project.name.EN)
      setProjectNameTH(value.project.name.TH)
      setProjectNameEN(value.project.name.EN)
      setProjectNameCN(value.project.name.CN)
      setProjectId(value.project.id)
      setLocation([
        {
          label: `${
            isBkk
              ? t('common:addressMap.district.metro.prefix')
              : t('common:addressMap.subdistrict.non-metro.prefix')
          }${
            value.district.name[locale.toUpperCase()] ||
            value.district.name.TH
          }, ${
            isBkk
              ? t('common:addressMap.district.metro.prefix')
              : t('common:addressMap.district.non-metro.prefix')
          }${
            value.amphur.name[locale.toUpperCase()] ||
            value.amphur.name.TH
          }, ${
            isBkk ? '' : t('common:addressMap.province.metro.prefix')
          }${
            value.province.name[locale.toUpperCase()] ||
            value.province.name.TH
          }`,
          lat: value.project.lat,
          lng: value.project.lng,
          distance: 5000,
          address: {
            country: 'Thailand',
            amphur:
              value.amphur?.name[locale.toUpperCase()] ||
              value.amphur?.name?.EN,
            province:
              value.province?.name[locale.toUpperCase()] ||
              value.province?.name?.EN,
          },
          location: value.project,
        },
      ])
    } else {
      setProject('')
    }
  }

  const [getProjectListDelay, setGetProjectListDelay] = useState(null)
  const onProjectInputChange = async (e) => {
    clearTimeout(getProjectListDelay)

    setSelectedProjectName(e.target.value)
    const delay = setTimeout(async () => {
      const projectname = e.target.value
      let _project = await getAllProjectOnlyData(
        parseInt(category),
        projectname,
      )
      _project = _project.items

      setProjectList(_project)
    }, 1000)
    setGetProjectListDelay(delay)
  }

  const [projectNameRequestCount, setProjectNameRequestCount] =
    useState(Array<AbortController>())

  const loadProjectName = async (e) => {
    let query: {
      projectname?: string
      projectonly?: boolean
      categoryid?: number 
      limit: number
      projectnameonly: boolean
    } = e.target.value
      ? {
          projectname: `${e.target.value}*`,
          projectonly: true,
          limit: 10,
          projectnameonly: true,
        }
      : {
          projectonly: true,
          limit: 10,
          projectnameonly: true,
        }

      console.log('loadProjectName leaveyout requirement: ', action);

    query['created_sources'] = [0, 1, 2]

    if (category) {
      query.categoryid = Number(category);
    }

    while (projectNameRequestCount.length > 1) {
      const a = projectNameRequestCount.pop()
      a.abort()
    }

    const projectNameAbortCon = new AbortController()
    const projects = await getSearchList(query as any, projectNameAbortCon)
    projectNameRequestCount.push(projectNameAbortCon)

    if (projects?.data?.items?.length > 0) {
      const projectsName = projects.data.items
      setProjectList(projectsName)
    } else {
      setProjectList([])
    }
  }

  const onPriceFromChange = (e) => {
    setPriceFrom(e.target.value.toString())
  }

  const onPriceToChange = (e) => {
    setPriceTo(e.target.value.toString())
  }

  const onBedroomMinChange = (e) => {
    setBedroomMin(parseInt(e.target.value) + '')
  }

  const onBedroomMaxChange = (e) => {
    setBedroomMax(parseInt(e.target.value) + '')
  }

  const onSizeMinChange = (e) => {
    setSizeMin(parseInt(e.target.value) + '')
  }

  const onSizeMaxChange = (e) => {
    setSizeMax(parseInt(e.target.value) + '')
  }

  const onSizeUnitChange = (e) => {
    setSizeUnit(e.target.value)
  }

  const onLandFromChange = (e) => {
    setLandFrom(parseInt(e.target.value) + '')
  }

  const onLandToChange = (e) => {
    setLandTo(parseInt(e.target.value) + '')
  }

  const onLandSizeUnitChange = (e) => {
    setLandSizeUnit(e.target.value)
  }

  const onNoUnitFromChange = (e) => {
    setNoUnitFrom(parseInt(e.target.value) + '')
  }

  const onNoUnitToChange = (e) => {
    setNoUnitTo(parseInt(e.target.value) + '')
  }

  const onBuyForChange = (e) => {
    setBuyFor(e.target.value.toString())
  }

  const onTypeChange = (e) => {
    setType(e.target.value.toString())
  }

  const onWelcomeChange = (e) => {
    setWelcome(e.target.value.toString())
  }

  const onRentalPeriodChange = (e: any) => {
    setRentalPeriod((parseInt(e.target.value) || '') + '')
  }

  const onRentalPeriodUnitChange = (e: any) => {
    setRentalPeriodUnit(e.target.value)
  }

  const onSpaceTypeChange = (e) => {
    setSpaceType(e.target.value.toString())
  }

  const validation = () => {
    const formValidate: { [key: string]: boolean } = {
      action: true,
      property: true,
      location: true,
      priceFrom: true,
      priceTo: true,
      bedroomFrom: true,
      bedroomTo: true,
      sizeFrom: true,
      sizeTo: true,
      landFrom: true,
      landTo: true,
      unitFrom: true,
      unitTo: true,
      type: true,
      welcome: true,
      rentalPeriod: true,
      spaceType: true,
    }
    let cate_type = getCateType()

    const checkValid = (s: any) =>
      s != '' && s != undefined && s != null

    if (!checkValid(action)) formValidate.action = false
    if (!checkValid(category)) formValidate.property = false
    if (!checkValid(priceFrom)) formValidate.priceFrom = false
    if (!location.length) formValidate.location = false
    if (!checkValid(priceTo)) formValidate.priceTo = false
    if (action == '3' && !checkValid(rentalPeriod))
      formValidate.rentalPeriod = false
    if (!checkValid(bedroomMin) && (cate_type == 1 || cate_type == 2))
      formValidate.bedroomFrom = false
    if (!checkValid(bedroomMax) && (cate_type == 1 || cate_type == 2))
      formValidate.bedroomTo = false
    if (!checkValid(sizeMin) && (cate_type == 2 || cate_type == 3))
      formValidate.sizeFrom = false
    if (!checkValid(sizeMax) && (cate_type == 2 || cate_type == 3))
      formValidate.sizeTo = false
    if (!checkValid(landFrom) && cate_type == 4)
      formValidate.landFrom = false
    if (!checkValid(landTo) && cate_type == 4)
      formValidate.landTo = false
    if (!checkValid(noUnitFrom) && cate_type == 5)
      formValidate.unitFrom = false
    if (!checkValid(noUnitTo) && cate_type == 5)
      formValidate.unitTo = false
    if (!checkValid(type)) formValidate.type = false
    if (!checkValid(welcome)) formValidate.welcome = false
    if (!checkValid(spaceType) && category === '10')
      formValidate.spaceType = false

    setFormValidate(formValidate)

    return Object.keys(formValidate)
      .map((e: any) => e)
      .every((e: string) => formValidate[e] === true)
  }

  const handleLoginCancelled = () => {
    window.removeEventListener(
      'login-cancelled',
      handleLoginCancelled,
    )
    window.removeEventListener('logged-in', handleLoggedIn)
    localStorage.removeItem('requirement')
  }

  const handleLoggedIn = async (e: any) => {
    console.log(`>>>>>> logged in >>>>>`)
    setLoading(true)
    window.removeEventListener(
      'login-cancelled',
      handleLoginCancelled,
    )
    window.removeEventListener('logged-in', handleLoggedIn)
    localStorage.removeItem('requirement')
    console.log(e.detail.token);
    setToken(e.detail.token);
    const user = e.detail.user;
    user?.id ? await saveRequirement(user.id, e.detail.token) : setLoading(false)

    setUser(user)
  }

  const submitForm = async () => {
    const user = await getUser()
    const user_id = user?.id

    if (!validation()) {
      setIsError(true)
      setErrorTitle(t('complete_information'))
      setErrorDescription('')
      setLoading(false)
      setIsButtonLoading(false)
      return
    }

    if (!user_id) {
      const storePayload = {
        action,
        category,
        project,
        projectNameTH,
        projectNameEN,
        projectNameCN,
        location,
        priceFrom,
        priceTo,
        buyFor,
        bedroomMin,
        bedroomMax,
        sizeMin,
        sizeMax,
        landFrom,
        landTo,
        noUnitFrom,
        noUnitTo,
        descriptionTH,
        descriptionEN,
        descriptionCN,
        type,
        welcome,
        sizeUnit,
        landSizeUnit,
        rentalPeriod,
        rentalPeriodUnit,
        spaceType,
        projectId,
      }
      localStorage.setItem(
        'requirement',
        JSON.stringify(storePayload),
      )
      window.addEventListener('logged-in', handleLoggedIn)
      window.addEventListener('login-cancelled', handleLoginCancelled)

      const loginRequested = new CustomEvent('loginrequested', {
        detail: { reload: false },
      })
      window.dispatchEvent(loginRequested)
    } else {
      const token = await firebaseInit.auth.currentUser.getIdToken();
      saveRequirement(user_id, token);
    }
  }

  const saveRequirement = async (user_id: string | number, ltoken: string) => {
    setLoading(true)
    setIsButtonLoading(true)

    let _location = [...location]
    console.log('save requirement')
    if (!validation()) {
      setIsError(true)
      setErrorTitle(t('complete_information'))
      setErrorDescription('')
      setLoading(false)
      setIsButtonLoading(false)
      return
    }

    console.log('location:'+ JSON.stringify(_location));
    try {
      for (let i = 0; i < _location.length; ++i) {
        let _province = provinceWithAmphur.filter(
          (f: any) =>
            f.label.toLowerCase() ===
            _location[i].address.province.toLowerCase(),
        )
        if (!_province.length) {
          _province = provinceWithAmphurTH.filter(
            (f: any) =>
              f.label.toLowerCase() ===
              location[i].address.province.toLowerCase(),
          )
        }

        let _amphur = _province[0].children.filter(
          (f: any) =>
            f.label.toLowerCase() ===
            _location[i].address.amphur.toLowerCase(),
        )
        _location[i].address.province = _province[0].value
        _location[i].address.amphur = _amphur[0]?.value ?? -1
      }
    } catch (e) {
      console.error(e)
    }

    const _priceFrom = priceFrom.replaceAll(',', '')
    const _priceTo = priceTo.replaceAll(',', '')

    const payload = {
      country,
      action: parseInt(action),
      category: parseInt(category),
      project,
      location: JSON.stringify(_location),
      priceFrom: parseInt(_priceFrom),
      priceTo: parseInt(_priceTo),
      currency: 'THB',
      buyFor: parseInt(buyFor),
      bedroomMin: parseInt(bedroomMin),
      bedroomMax: parseInt(bedroomMax),
      sizeFrom: parseInt(sizeMin),
      sizeTo: parseInt(sizeMax),
      landFrom: landFrom,
      landTo: landTo,
      noUnitFrom: parseInt(noUnitFrom),
      noUnitTo: parseInt(noUnitTo),
      descriptionTH,
      descriptionEN,
      descriptionCN,
      type: parseInt(type),
      welcome: parseInt(type),
      user_id,
      sizeUnit,
      landSizeUnit,
      rentalPeriod,
      rentalPeriodUnit,
      spaceType: parseInt(spaceType),
    }
    console.log(payload)
    console.log(ltoken);
    try {
      console.log(ltoken)
      const res = await addRequirement(ltoken, payload)
      if (res.data) {
        await saveProjectProperty(ltoken, {
          rq_id: res.data,
          project_name_TH: projectNameTH,
          project_name_EN: projectNameEN,
          project_name_CN: projectNameCN,
          project_id: projectId,
        })

        setSuccessId(res.data)
        setLoading(false)
        setIsLoadedStorePayload(false)
        setIsButtonLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const [isShowLocation, setIsShowLocation] = useState(false)

  const showMapsPanel = () => setIsShowLocation(true)
  const closeMapsPanel = () => {
    setIsShowLocation(false)
    setReRender(!reRender)
  }

  const onNextPageClicked = () => {
    window.location.href = `${window.location.origin}/buyer/requirements`
  }

  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  useEffect(() => {
    if (priceFrom && priceTo) {
      let _priceFrom = parseInt(priceFrom.split(',').join(''))
      let _priceTo = parseInt(priceTo.split(',').join(''))

      if (_priceFrom > _priceTo) {
        setIsError(true)
        setErrorTitle(t('priceFrom>priceTo'))
        setErrorDescription('')
        setPriceFrom(numberWithCommas(_priceTo))
      }
    }
  }, [priceFrom])

  useEffect(() => {
    if (priceFrom && priceTo) {
      let _priceFrom = parseInt(priceFrom.split(',').join(''))
      let _priceTo = parseInt(priceTo.split(',').join(''))

      if (_priceFrom > _priceTo) {
        setIsError(true)
        setErrorTitle(t('priceFrom>priceTo'))
        setErrorDescription('')
        setPriceTo(numberWithCommas(_priceFrom))
      }
    }
  }, [priceTo])

  const onBedroomMinBlur = () => {
    if (bedroomMin && bedroomMax) {
      let _bedroomMin = parseInt(bedroomMin)
      let _bedroomMax = parseInt(bedroomMax)

      if (_bedroomMin > _bedroomMax) {
        setBedroomMin(_bedroomMax + '')
        setIsError(true)
        setErrorTitle(t('bedroomMin>bedroomMax'))
        setErrorDescription('')
      }
    }
  }

  const onBedroomMaxBlur = () => {
    if (bedroomMin && bedroomMax) {
      let _bedroomMin = parseInt(bedroomMin)
      let _bedroomMax = parseInt(bedroomMax)

      if (_bedroomMin > _bedroomMax) {
        setBedroomMax(_bedroomMin + '')
        setIsError(true)
        setErrorTitle(t('bedroomMin>bedroomMax'))
        setErrorDescription('')
      }
    }
  }

  const onSizeMinBlur = () => {
    if (sizeMin && sizeMax) {
      let _sizeMin = parseInt(sizeMin)
      let _sizeMax = parseInt(sizeMax)

      if (_sizeMin > _sizeMax) {
        setSizeMin(_sizeMax + '')
        setIsError(true)
        setErrorTitle(t('sizeFrom>sizeTo'))
        setErrorDescription('')
      }
    }
  }

  const onSizeMaxBlur = () => {
    if (sizeMin && sizeMax) {
      let _sizeMin = parseInt(sizeMin)
      let _sizeMax = parseInt(sizeMax)

      if (_sizeMin > _sizeMax) {
        setSizeMax(_sizeMin + '')
        setIsError(true)
        setErrorTitle(t('sizeFrom>sizeTo'))
        setErrorDescription('')
      }
    }
  }

  const onLandFromBlur = () => {
    if (landFrom && landTo) {
      let _landFrom = parseInt(landFrom)
      let _landTo = parseInt(landTo)

      if (_landFrom > _landTo) {
        setLandFrom(_landTo + '')
        setIsError(true)
        setErrorTitle(t('landFrom>landTo'))
        setErrorDescription('')
      }
    }
  }

  const onLandToBlur = () => {
    if (landFrom && landTo) {
      let _landFrom = parseInt(landFrom)
      let _landTo = parseInt(landTo)

      if (_landFrom > _landTo) {
        setLandTo(_landFrom + '')
        setIsError(true)
        setErrorTitle(t('landFrom>landTo'))
        setErrorDescription('')
      }
    }
  }

  const onNoUnitFromBlur = () => {
    if (noUnitFrom && noUnitTo) {
      let _noUnitFrom = parseInt(noUnitFrom)
      let _noUnitTo = parseInt(noUnitTo)

      if (_noUnitFrom > _noUnitTo) {
        setNoUnitFrom(_noUnitTo + '')
        setIsError(true)
        setErrorTitle(t('noUnitFrom>noUnitTo'))
        setErrorDescription('')
      }
    }
  }

  const onNoUnitToBlur = () => {
    if (noUnitFrom && noUnitTo) {
      let _noUnitFrom = parseInt(noUnitFrom)
      let _noUnitTo = parseInt(noUnitTo)

      if (_noUnitFrom > _noUnitTo) {
        setNoUnitTo(_noUnitFrom + '')
        setIsError(true)
        setErrorTitle(t('noUnitFrom>noUnitTo'))
        setErrorDescription('')
      }
    }
  }

  const getCateType = function (): number {
    let cate_type = 0
    if (category == '1') {
      cate_type = 1
    } else if (
      category == '3' ||
      category == '25' ||
      category == '2' ||
      category == '24' ||
      category == '4' ||
      category == '9'
    ) {
      cate_type = 2
    } else if (category == '6' || category == '10') {
      cate_type = 3
    } else if (category == '5') {
      cate_type = 4
    } else if (
      category == '7' ||
      category == '11' ||
      category == '17'
    ) {
      cate_type = 5
    } else if (category == '16' || category == '8') {
      cate_type = 3
    }

    return cate_type
  }

  let cate_type: number = getCateType()

  const RenderErrorDialog = () => {
    return (
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography>{errorTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsError(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const onDescriptionTHKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      const newValue = descriptionTH + '\n'
      setDescriptionTH(newValue)

      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd =
          newValue.length
      }, 0)
    }
  }

  const onDescriptionENKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      const newValue = descriptionEN + '\n'
      setDescriptionEN(newValue)

      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd =
          newValue.length
      }, 0)
    }
  }

  const onDescriptionCNKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      const newValue = descriptionCN + '\n'
      setDescriptionCN(newValue)

      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd =
          newValue.length
      }, 0)
    }
  }

  const renderDescription = () => {
    let desc: string[] = []
    switch (i18n.language) {
      case 'th':
        desc = ['th', 'en', 'cn']
        break
      case 'en':
        desc = ['en', 'th', 'cn']
        break
      case 'cn':
        desc = ['cn', 'en', 'th']
        break
      default:
        desc = ['th', 'en', 'cn']
    }

    return (
      <>
        {desc.map((d: string) => (
          <Form.Item>
            <TextField
              value={
                (d == 'th'
                  ? descriptionTH
                  : d == 'en'
                  ? descriptionEN
                  : d == 'cn'
                  ? descriptionCN
                  : '') || ''
              }
              onChange={(e) =>
                d == 'th'
                  ? setDescriptionTH(e.target.value.toString())
                  : d == 'en'
                  ? setDescriptionEN(e.target.value.toString())
                  : d == 'cn'
                  ? setDescriptionCN(e.target.value.toString())
                  : () => {}
              }
              onKeyDown={
                d == 'th'
                  ? onDescriptionTHKeyDown
                  : d == 'en'
                  ? onDescriptionENKeyDown
                  : d == 'cn'
                  ? onDescriptionCNKeyDown
                  : () => {}
              }
              label={t(`description${d.toUpperCase()}`)}
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              className={styles.formControl}
              multiline
              rows="5"
              inputProps={{ maxLength: 900 }}
            />
          </Form.Item>
        ))}
      </>
    )
  }

  const getProjectAddress = (addr: any | undefined) => {
    let text = ''
    if (addr) {
      if (addr.addressText) {
        text += addr.addressText[locale] || addr.addressText.EN || ''
      }
      if (addr.alley && addr.alley[locale]) {
        text += `${t('addressMap.soi.prefix')} ${addr.alley[locale]}`
      }
      if (addr.road && addr.road[locale]) {
        text += `${
          locale.toUpperCase() === 'TH'
            ? t('addressMap.road.postfix')
            : ''
        } ${addr.road[locale]}${
          locale.toUpperCase() !== 'TH'
            ? t('addressMap.road.postfix')
            : ''
        }`
      }

      // const prov = provinces.find(p => p.PROVINCE_ID === addr.provinceId);
      const isBkk = addr.province && addr.province.code === '10'

      // const subd = subdistricts.find(s => s.DISTRICT_ID === addr.subdistrictId);
      if (addr.subdistrict?.name) {
        text += `${
          isBkk
            ? t('addressMap.subdistrict.metro.prefix')
            : t('subdistrict')
        } ${
          addr.subdistrict.name[locale] ||
          addr.subdistrict.name.EN ||
          '-'
        }`
      }

      // const dist = districts.find(d => d.AMPHUR_ID === addr.districtId);
      if (addr.district?.name) {
        text += `${
          isBkk
            ? t('addressMap.district.metro.prefix')
            : t('district')
        } ${
          addr.district?.name[locale] || addr.district?.name.EN || '-'
        }`
      }

      if (addr.province?.name) {
        text += `${isBkk ? '' : t('province')} ${
          addr.province.name[locale] || addr.province.name.TH || '-'
        }`
      }
    }

    return text.trim()
  }

  return (
    <>
      { loading && <ModalLoading />}
      {successId && (
        <SuccessModal
          email={user?.email}
          emailVerified={user?.emailVerified}
          successId={successId}
          onToNextClicked={onNextPageClicked}
          onStayClicked={() => {
            window.location.reload()
          }}
          language={i18n.language}
        />
      )}
      <RenderErrorDialog />
      <Card className={styles.cardContainer}>
        <Card.Meta
          title={
            <div className={styles.title}>
              {t('create_requirement')}
            </div>
          }
        />
        <Row>
          <Col span={24} className={styles.formContainer}>
            <Form layout="vertical">
              <Form.Item>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={styles.formControl}
                  error={!formValidate.action}
                >
                  <InputLabel id="property-type-label">
                    {t('requirement')}
                  </InputLabel>
                  <Select
                    id="property-type"
                    label={t('requirement')}
                    value={action}
                    onChange={onActionChange}
                  >
                    {actionList.map((action) => (
                      <MenuItem
                        key={action.value}
                        value={action.value}
                      >
                        {action.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!formValidate.action && (
                    <FormHelperText error>{`${t(
                      'please_specify',
                    )} ${t('requirement')}`}</FormHelperText>
                  )}
                </FormControl>
              </Form.Item>
              <Form.Item>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={styles.formControl}
                  error={!formValidate.property}
                >
                  <InputLabel id="property-type-label">
                    {t('project-type-requirement')}
                  </InputLabel>
                  <Select
                    id="property-type"
                    label={t('project-type-requirement')}
                    onChange={onCategoryChange}
                    value={category}
                  >
                    {categoryList.map((category) => (
                      <MenuItem
                        key={category.value}
                        value={category.value}
                      >
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!formValidate.property && (
                    <FormHelperText error>{`${t(
                      'please_specify',
                    )} ${t(
                      'project-type-requirement',
                    )}`}</FormHelperText>
                  )}
                </FormControl>
              </Form.Item>
              {category && (cate_type === 1 || cate_type === 2) ? (
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    style={{ maxWidth: '400px' }}
                    size="small"
                    fullWidth
                    className={styles.formAutocomplete}
                  >
                    <Autocomplete
                      id="property-type"
                      disablePortal
                      options={projectList}
                      size="small"
                      autoHighlight
                      getOptionLabel={(option) =>
                        option.project.name[locale] ||
                        option.project.name.EN
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <span style={{ fontSize: '14px' }}>
                            <Highlighter
                              highlightStyle={{
                                color: 'rgb(244, 118, 42)',
                                backgroundColor: 'unset',
                              }}
                              searchWords={[`${selectedProjectName}`]}
                              autoEscape={true}
                              textToHighlight={t(
                                option.project.name[locale],
                              )}
                            />
                            {/* {
                              t(option.project.name[locale])
                            } */}
                          </span>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('project-name')}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                          // onChange={onProjectInputChange}
                          onChange={loadProjectName}
                        />
                      )}
                      onChange={(e, v, r, d) => {
                        onProjectChange(d)
                      }}
                    />
                  </FormControl>
                </Form.Item>
              ) : null}
              {category === '10' ? (
                <Form.Item>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={styles.formControl}
                    error={!formValidate.action}
                  >
                    <InputLabel id="property-type-label">
                      {t('spaceType')}
                    </InputLabel>
                    <Select
                      id="property-type"
                      label={t('spaceType')}
                      value={spaceType}
                      onChange={onSpaceTypeChange}
                    >
                      {spaceTypeList.map((spaceType) => (
                        <MenuItem
                          key={spaceType.value}
                          value={spaceType.value}
                        >
                          {spaceType.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!formValidate.spaceType && (
                      <FormHelperText error>{`${t(
                        'please_specify',
                      )} ${t('requirement')}`}</FormHelperText>
                    )}
                  </FormControl>
                </Form.Item>
              ) : null}
              {project == '' ? (
                <Form.Item>
                  <TextField
                    label={t('location')}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    className={styles.formControl}
                    onClick={showMapsPanel}
                    value={
                      location.length
                        ? location[0].label +
                          (location.length > 1
                            ? ' + ' + (location.length - 1)
                            : '')
                        : ''
                    }
                    error={!formValidate.location}
                    helperText={
                      !formValidate.location
                        ? `${t('please_specify')} ${t('location')}`
                        : ''
                    }
                  />
                </Form.Item>
              ) : null}
              {location.length ? (
                <>
                  <LeafletMapMultiMarkComponent
                    latlng={location[0]}
                    placeList={location}
                    reRender={reRender}
                    isOnce={location.length > 1 ? false : true}
                    isShowLocation={isShowLocation}
                    isPure={true}
                  />

                  <div style={{ marginTop: 14 }}>
                    {project != '' ? (
                      <>
                        <strong>
                          {t('project_name')}: {project}
                        </strong>{' '}
                        <br />
                        <strong>
                          {t('address')}:{' '}
                          {getProjectAddress(location[0].location)}{' '}
                          {location &&
                            (locale === 'TH'
                              ? location[0]?.label
                                  ?.replaceAll(',', ' ')
                                  .replaceAll('null,', '')
                                  .replaceAll('null', '')
                              : location[0]?.label
                                  ?.replaceAll('null,', '')
                                  .replaceAll('null', ''))}
                        </strong>
                      </>
                    ) : (
                      location.slice(0, 3).map((item, index) => (
                        <span style={{ display: 'block' }}>
                          {index + 1}. {item.label}
                        </span>
                      ))
                    )}
                  </div>
                </>
              ) : null}
              {isShowLocation ? (
                <div className={styles.locationContainer}>
                  <div
                    className={styles.hoverBackground}
                    onClick={closeMapsPanel}
                  ></div>
                  <Form.Item className={styles.mapsContainer}>
                    <MapsPanel
                      closeMapsPanel={closeMapsPanel}
                      setLocation={setLocation}
                      location={location}
                      t={t}
                    />
                  </Form.Item>
                </div>
              ) : null}
              {action == '3' && (
                <>
                  <Form.Item
                    className={styles.titleLine}
                    style={{ marginTop: 36 }}
                  >
                    <strong>{t('rental_period')}</strong>
                  </Form.Item>
                  <Form.Item style={{ marginTop: -12 }}>
                    <div className={styles.budgetGroup}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={`${styles.formControl} ${styles.formBudgetInput}`}
                        error={!formValidate.rentalPeriod}
                      >
                        <InputLabel htmlFor="rental-period">
                          {t('rental_period')}
                        </InputLabel>
                        <OutlinedInput
                          id="rental-period"
                          label={t('rental_period')}
                          onChange={onRentalPeriodChange}
                          value={rentalPeriod}
                          className={styles.budgetInput}
                        />
                        {!formValidate.rentalPeriod && (
                          <FormHelperText error>
                            {`${t('please_specify')} ${t(
                              'rental_period',
                            )}`}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={styles.dropdownControl}
                        style={{ minWidth: '30%' }}
                      >
                        <Select
                          className={styles.currencyInput}
                          value={rentalPeriodUnit}
                          onChange={onRentalPeriodUnitChange}
                        >
                          {rentalUnitList.map((rentalUnit, index) => (
                            <MenuItem value={rentalUnit}>
                              {t(rentalUnit)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Form.Item>
                </>
              )}
              <Form.Item
                style={{ marginTop: action == '3' ? -12 : 36 }}
              >
                <strong>{t('budget')}</strong>
              </Form.Item>
              <Form.Item style={{ marginTop: -12 }}>
                <div className={styles.budgetGroup}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={`${styles.formControl} ${styles.formBudgetInput}`}
                    style={{ width: '55%' }}
                    error={!formValidate.priceFrom}
                  >
                    <InputLabel id="property-type-label">
                      {t('min_price')}
                    </InputLabel>
                    <Select
                      onChange={onPriceFromChange}
                      value={priceFrom}
                      id="property-type"
                      size="small"
                      label={t('min_price')}
                    >
                      {(action == '3' ? rentBudget : buyBudget).map(
                        (price) => {
                          return (
                            <MenuItem key={price} value={price}>
                              {price}
                            </MenuItem>
                          )
                        },
                      )}
                    </Select>
                    {!formValidate.priceFrom && (
                      <FormHelperText error>{`${t(
                        'please_specify',
                      )} ${t('min_price')}`}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={`${styles.formCurrencyInputDisabled} ${styles.dropdownControl}`}
                    style={{ width: '45%' }}
                  >
                    <Select
                      className={styles.currencyInput}
                      value="THB"
                      disabled
                    >
                      <MenuItem value="THB">
                        {t('THB')}
                        {action == '3'
                          ? rentalPeriodUnit == 'year'
                            ? `/${t('month')}`
                            : ''
                          : ''}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Form.Item>
              <Form.Item style={{ marginTop: -12 }}>
                <div className={styles.budgetGroup}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={`${styles.formControl} ${styles.formBudgetInput}`}
                    style={{ width: '55%' }}
                    error={!formValidate.priceTo}
                  >
                    <InputLabel id="property-type-label">
                      {t('max_price')}
                    </InputLabel>
                    <Select
                      onChange={onPriceToChange}
                      value={priceTo}
                      id="property-type"
                      size="small"
                      label={t('max_price')}
                    >
                      {(action == '3' ? rentBudget : buyBudget).map(
                        (price) => {
                          return (
                            <MenuItem key={price} value={price}>
                              {price}
                            </MenuItem>
                          )
                        },
                      )}
                    </Select>
                    {!formValidate.priceTo && (
                      <FormHelperText error>{`${t(
                        'please_specify',
                      )} ${t('max_price')}`}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={`${styles.formCurrencyInputDisabled} ${styles.dropdownControl}`}
                    style={{ width: '45%' }}
                  >
                    <Select
                      className={styles.currencyInput}
                      value="THB"
                      disabled
                    >
                      <MenuItem value="THB">
                        {t('THB')}
                        {action == '3'
                          ? rentalPeriodUnit == 'year'
                            ? `/${t('month')}`
                            : ''
                          : ''}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Form.Item>
              {cate_type === 1 ? (
                <RenderProperty1
                  t={t}
                  bedroomMin={bedroomMin}
                  bedroomMax={bedroomMax}
                  onBedroomMinChange={onBedroomMinChange}
                  onBedroomMaxChange={onBedroomMaxChange}
                  sizeMin={sizeMin}
                  sizeMax={sizeMax}
                  onSizeMinChange={onSizeMinChange}
                  onSizeMaxChange={onSizeMaxChange}
                  onSizeMinBlur={onSizeMinBlur}
                  onSizeMaxBlur={onSizeMaxBlur}
                  sizeUnit={sizeUnit}
                  onSizeUnitChange={onSizeUnitChange}
                  action={action}
                  buyFor={buyFor}
                  onBuyForChange={onBuyForChange}
                  buyForList={buyForList}
                  onBedroomMinBlur={onBedroomMinBlur}
                  onBedroomMaxBlur={onBedroomMaxBlur}
                  formValidate={formValidate}
                />
              ) : null}
              {cate_type === 2 ? (
                <RenderProperty2
                  t={t}
                  bedroomMin={bedroomMin}
                  bedroomMax={bedroomMax}
                  onBedroomMinChange={onBedroomMinChange}
                  onBedroomMaxChange={onBedroomMaxChange}
                  sizeMin={sizeMin}
                  sizeMax={sizeMax}
                  onSizeMinChange={onSizeMinChange}
                  onSizeMaxChange={onSizeMaxChange}
                  onSizeMinBlur={onSizeMinBlur}
                  onSizeMaxBlur={onSizeMaxBlur}
                  sizeUnit={sizeUnit}
                  onSizeUnitChange={onSizeUnitChange}
                  onBedroomMinBlur={onBedroomMinBlur}
                  onBedroomMaxBlur={onBedroomMaxBlur}
                  landFrom={landFrom}
                  landTo={landTo}
                  onLandFromChange={onLandFromChange}
                  onLandToChange={onLandToChange}
                  onLandFromBlur={onLandFromBlur}
                  onLandToBlur={onLandToBlur}
                  landSizeUnit={landSizeUnit}
                  onLandSizeUnitChange={onLandSizeUnitChange}
                  formValidate={formValidate}
                />
              ) : null}
              {cate_type === 3 ? (
                <RenderProperty3
                  t={t}
                  sizeMin={sizeMin}
                  sizeMax={sizeMax}
                  onSizeMinChange={onSizeMinChange}
                  onSizeMaxChange={onSizeMaxChange}
                  onSizeMinBlur={onSizeMinBlur}
                  onSizeMaxBlur={onSizeMaxBlur}
                  sizeUnit={sizeUnit}
                  onSizeUnitChange={onSizeUnitChange}
                  landFrom={landFrom}
                  landTo={landTo}
                  onLandFromChange={onLandFromChange}
                  onLandToChange={onLandToChange}
                  onLandFromBlur={onLandFromBlur}
                  onLandToBlur={onLandToBlur}
                  landSizeUnit={landSizeUnit}
                  onLandSizeUnitChange={onLandSizeUnitChange}
                  formValidate={formValidate}
                />
              ) : null}
              {cate_type === 4 ? (
                <RenderProperty4
                  t={t}
                  landFrom={landFrom}
                  landTo={landTo}
                  onLandFromChange={onLandFromChange}
                  onLandToChange={onLandToChange}
                  onLandFromBlur={onLandFromBlur}
                  onLandToBlur={onLandToBlur}
                  landSizeUnit={landSizeUnit}
                  onLandSizeUnitChange={onLandSizeUnitChange}
                  formValidate={formValidate}
                />
              ) : null}
              {cate_type === 5 ? (
                <RenderProperty5
                  t={t}
                  sizeMin={sizeMin}
                  sizeMax={sizeMax}
                  onSizeMinChange={onSizeMinChange}
                  onSizeMaxChange={onSizeMaxChange}
                  onSizeMinBlur={onSizeMinBlur}
                  onSizeMaxBlur={onSizeMaxBlur}
                  sizeUnit={sizeUnit}
                  onSizeUnitChange={onSizeUnitChange}
                  landFrom={landFrom}
                  landTo={landTo}
                  onLandFromChange={onLandFromChange}
                  onLandToChange={onLandToChange}
                  onLandFromBlur={onLandFromBlur}
                  onLandToBlur={onLandToBlur}
                  landSizeUnit={landSizeUnit}
                  onLandSizeUnitChange={onLandSizeUnitChange}
                  noUnitFrom={noUnitFrom}
                  noUnitTo={noUnitTo}
                  onNoUnitFromChange={onNoUnitFromChange}
                  onNoUnitToChange={onNoUnitToChange}
                  onNoUnitFromBlur={onNoUnitFromBlur}
                  onNoUnitToblur={onNoUnitToBlur}
                  formValidate={formValidate}
                />
              ) : null}

              {/* description */}
              {renderDescription()}

              <Form.Item>
                <FormControl
                  variant="outlined"
                  style={{ maxWidth: '400px' }}
                  size="small"
                  fullWidth
                  className={styles.formControl}
                  error={!formValidate.type}
                >
                  <InputLabel id="property-type-label">
                    {t('request-by')}
                  </InputLabel>
                  <Select
                    id="property-type"
                    onChange={onTypeChange}
                    value={type}
                    label={t('request-by')}
                  >
                    {typeList
                      .filter(
                        (f: any) => f.action_id == (action || 2),
                      )
                      .map((type) => {
                        return (
                          <MenuItem
                            key={type.value}
                            value={type.value}
                          >
                            {type.label}
                          </MenuItem>
                        )
                      })}
                  </Select>
                  {!formValidate.type && (
                    <FormHelperText error>{`${t(
                      'please_specify',
                    )} ${t('request-by')}`}</FormHelperText>
                  )}
                </FormControl>
              </Form.Item>
              <Form.Item>
                <FormControl
                  variant="outlined"
                  style={{ maxWidth: '400px' }}
                  size="small"
                  fullWidth
                  className={styles.formControl}
                  error={!formValidate.welcome}
                >
                  <InputLabel id="property-type-label">
                    {t('accept-listing-from')}
                  </InputLabel>
                  <Select
                    id="property-type"
                    onChange={onWelcomeChange}
                    value={welcome}
                    label={t('accept-listing-from')}
                  >
                    {welcomeList &&
                      welcomeList
                        .filter((f: any) => f.type_id == action)
                        .map((welcome) => {
                          return (
                            <MenuItem
                              key={welcome.value}
                              value={welcome.value}
                            >
                              {welcome.label}
                            </MenuItem>
                          )
                        })}
                  </Select>
                  {!formValidate.welcome && (
                    <FormHelperText error>{`${t(
                      'please_specify',
                    )} ${t('accept-listing-from')}`}</FormHelperText>
                  )}
                </FormControl>
              </Form.Item>

              <Form.Item className={styles.buttonContainer}>
                <Button
                  type="primary"
                  onClick={submitForm}
                  loading={isButtonLoading}
                  disabled={isButtonLoading}
                >
                  {t('Sign up')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

const RenderProperty1 = ({
  t,
  bedroomMin,
  bedroomMax,
  onBedroomMinChange,
  onBedroomMaxChange,
  onBedroomMinBlur,
  onBedroomMaxBlur,
  sizeMin,
  sizeMax,
  onSizeMinChange,
  onSizeMaxChange,
  onSizeMinBlur,
  onSizeMaxBlur,
  sizeUnit,
  onSizeUnitChange,
  action,
  buyFor,
  onBuyForChange,
  buyForList,
  formValidate,
}) => {
  const sizeUnitList = ['Sq.m', 'Sq.f']

  return (
    <>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('number_of_bedroom')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          className={styles.formControl}
        >
          <TextField
            label={t('min_bedroom')}
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            className={styles.formControl}
            value={bedroomMin}
            onChange={onBedroomMinChange}
            onBlur={onBedroomMinBlur}
            error={!formValidate.bedroomFrom}
            helperText={
              !formValidate.bedroomFrom
                ? `${t('please_specify')} ${t('min_bedroom')}`
                : ''
            }
          />
        </FormControl>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          className={styles.formControl}
        >
          <TextField
            label={t('max_bedroom')}
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            className={styles.formControl}
            value={bedroomMax}
            onChange={onBedroomMaxChange}
            onBlur={onBedroomMaxBlur}
            error={!formValidate.bedroomTo}
            helperText={
              !formValidate.bedroomTo
                ? `${t('please_specify')} ${t('max_bedroom')}`
                : ''
            }
          />
        </FormControl>
      </Form.Item>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('useable_area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_useable_area')}
              onChange={onSizeMinChange}
              value={sizeMin}
              className={styles.budgetInput}
              onBlur={onSizeMinBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
              fullWidth
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={styles.dropdownControl}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_useable_area')}
              fullWidth
              onChange={onSizeMaxChange}
              value={sizeMax}
              className={styles.budgetInput}
              onBlur={onSizeMaxBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={styles.dropdownControl}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      {(action == '2' || action == '3') && (
        <Form.Item>
          <FormControl
            variant="outlined"
            style={{ maxWidth: '400px' }}
            size="small"
            fullWidth
            className={styles.formControl}
          >
            <InputLabel id="property-type-label">
              {t(action == '2' ? 'buy-for' : 'rent-for')}
            </InputLabel>
            <Select
              id="property-type"
              label={t(action == '2' ? 'buy-for' : 'rent-for')}
              value={buyFor}
              onChange={onBuyForChange}
            >
              {(action == '3'
                ? buyForList.filter((f: any) => f.action_id == 3)
                : buyForList
              ).map((buyFor) => {
                return (
                  <MenuItem key={buyFor.value} value={buyFor.value}>
                    {buyFor.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Form.Item>
      )}
    </>
  )
}

const RenderProperty2 = ({
  t,
  bedroomMin,
  bedroomMax,
  onBedroomMinChange,
  onBedroomMaxChange,
  onBedroomMinBlur,
  onBedroomMaxBlur,
  sizeMin,
  sizeMax,
  onSizeMinChange,
  onSizeMaxChange,
  onSizeMinBlur,
  onSizeMaxBlur,
  sizeUnit,
  onSizeUnitChange,
  landFrom,
  landTo,
  onLandFromChange,
  onLandToChange,
  onLandFromBlur,
  onLandToBlur,
  landSizeUnit,
  onLandSizeUnitChange,
  formValidate,
}) => {
  const sizeUnitList = ['Sq.m', 'Sq.f']
  const landSizeUnitList = ['Sq.w', 'ngan', 'Rai']

  return (
    <>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('number_of_bedroom')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          className={styles.formControl}
        >
          <TextField
            label={t('min_bedroom')}
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            className={styles.formControl}
            value={bedroomMin}
            onChange={onBedroomMinChange}
            onBlur={onBedroomMinBlur}
            error={!formValidate.bedroomFrom}
            helperText={
              !formValidate.bedroomFrom
                ? `${t('please_specify')} ${t('min_bedroom')}`
                : ''
            }
          />
        </FormControl>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          className={styles.formControl}
        >
          <TextField
            label={t('max_bedroom')}
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            className={styles.formControl}
            value={bedroomMax}
            onChange={onBedroomMaxChange}
            onBlur={onBedroomMaxBlur}
            error={!formValidate.bedroomTo}
            helperText={
              !formValidate.bedroomTo
                ? `${t('please_specify')} ${t('max_bedroom')}`
                : ''
            }
          />
        </FormControl>
      </Form.Item>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('useable_area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
            error={!formValidate.sizeFrom}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_useable_area')}
              onChange={onSizeMinChange}
              value={sizeMin}
              className={styles.budgetInput}
              onBlur={onSizeMinBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
            {!formValidate.sizeFrom && (
              <FormHelperText error>{`${t('please_specify')} ${t(
                'min_useable_area',
              )}`}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
            error={!formValidate.sizeTo}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_useable_area')}
              fullWidth
              onChange={onSizeMaxChange}
              value={sizeMax}
              className={styles.budgetInput}
              onBlur={onSizeMaxBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
            {!formValidate.sizeTo && (
              <FormHelperText error>{`${t('please_specify')} ${t(
                'max_useable_area',
              )}`}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('Land Area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_land_size')}
              onChange={onLandFromChange}
              value={landFrom}
              className={styles.budgetInput}
              onBlur={onLandFromBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_land_size')}
              fullWidth
              onChange={onLandToChange}
              value={landTo}
              className={styles.budgetInput}
              onBlur={onLandToBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
    </>
  )
}

const RenderProperty3 = ({
  t,
  sizeMin,
  sizeMax,
  onSizeMinChange,
  onSizeMaxChange,
  onSizeMinBlur,
  onSizeMaxBlur,
  sizeUnit,
  onSizeUnitChange,
  landFrom,
  landTo,
  onLandFromChange,
  onLandToChange,
  onLandFromBlur,
  onLandToBlur,
  landSizeUnit,
  onLandSizeUnitChange,
  formValidate,
}) => {
  const sizeUnitList = ['Sq.m', 'Sq.f']
  const landSizeUnitList = ['Rai', 'Sq.w', 'ngan']

  return (
    <>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('useable_area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
            error={!formValidate.sizeFrom}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_useable_area')}
              onChange={onSizeMinChange}
              value={sizeMin}
              className={styles.budgetInput}
              onBlur={onSizeMinBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
            {!formValidate.sizeFrom && (
              <FormHelperText error>{`${t('please_specify')} ${t(
                'min_useable_area',
              )}`}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={styles.dropdownControl}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
            error={!formValidate.sizeTo}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_useable_area')}
              fullWidth
              onChange={onSizeMaxChange}
              value={sizeMax}
              className={styles.budgetInput}
              onBlur={onSizeMaxBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
            {!formValidate.sizeTo && (
              <FormHelperText error>{`${t('please_specify')} ${t(
                'max_useable_area',
              )}`}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={styles.dropdownControl}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('Land Area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_land_size')}
              onChange={onLandFromChange}
              value={landFrom}
              className={styles.budgetInput}
              onBlur={onLandFromBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_land_size')}
              fullWidth
              onChange={onLandToChange}
              value={landTo}
              className={styles.budgetInput}
              onBlur={onLandToBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
    </>
  )
}

const RenderProperty4 = ({
  t,
  landFrom,
  landTo,
  onLandFromChange,
  onLandToChange,
  onLandFromBlur,
  onLandToBlur,
  landSizeUnit,
  onLandSizeUnitChange,
  formValidate,
}) => {
  const sizeUnitList = ['Sq.m', 'Sq.f']
  const landSizeUnitList = ['Rai', 'Sq.w', 'ngan']

  return (
    <>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('Land Area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
            error={!formValidate.landFrom}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_land_size')}
              onChange={onLandFromChange}
              value={landFrom}
              className={styles.budgetInput}
              onBlur={onLandFromBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
            {!formValidate.landFrom && (
              <FormHelperText error>{`${t('please_specify')} ${t(
                'min_land_size',
              )}`}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
            error={!formValidate.landTo}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_land_size')}
              fullWidth
              onChange={onLandToChange}
              value={landTo}
              className={styles.budgetInput}
              onBlur={onLandToBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
            {!formValidate.landTo && (
              <FormHelperText error>{`${t('please_specify')} ${t(
                'max_land_size',
              )}`}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
    </>
  )
}

const RenderProperty5 = ({
  t,
  sizeMin,
  sizeMax,
  onSizeMinChange,
  onSizeMaxChange,
  onSizeMinBlur,
  onSizeMaxBlur,
  sizeUnit,
  onSizeUnitChange,
  landFrom,
  landTo,
  onLandFromChange,
  onLandToChange,
  onLandFromBlur,
  onLandToBlur,
  landSizeUnit,
  onLandSizeUnitChange,
  noUnitFrom,
  noUnitTo,
  onNoUnitFromChange,
  onNoUnitToChange,
  onNoUnitFromBlur,
  onNoUnitToblur,
  formValidate,
}) => {
  const sizeUnitList = ['Sq.m', 'Sq.f']
  const landSizeUnitList = ['Rai', 'Sq.w', 'ngan']

  return (
    <>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('no_of_unit')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <FormControl
          variant="outlined"
          size="small"
          className={styles.formControl}
          fullWidth
          error={!formValidate.unitFrom}
        >
          <InputLabel htmlFor="budget-start">
            {t('min_no_of_unit')}
          </InputLabel>
          <OutlinedInput
            id="budget-start"
            label={t('min_no_of_unit')}
            onChange={onNoUnitFromChange}
            value={noUnitFrom}
            className={styles.budgetInput}
            onBlur={onNoUnitFromBlur}
            type="number"
          />
          {!formValidate.unitFrom && (
            <FormHelperText error>{`${t('please_specify')} ${t(
              'min_no_of_unit',
            )}`}</FormHelperText>
          )}
        </FormControl>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <FormControl
          variant="outlined"
          size="small"
          className={styles.formControl}
          fullWidth
          error={!formValidate.unitTo}
        >
          <InputLabel htmlFor="budget-to">
            {t('max_no_of_unit')}
          </InputLabel>
          <OutlinedInput
            id="budget-to"
            label={t('max_no_of_unit')}
            fullWidth
            onChange={onNoUnitToChange}
            value={noUnitTo}
            className={styles.budgetInput}
            onBlur={onNoUnitToblur}
            type="number"
          />
          {!formValidate.unitTo && (
            <FormHelperText error>{`${t('please_specify')} ${t(
              'max_no_of_unit',
            )}`}</FormHelperText>
          )}
        </FormControl>
      </Form.Item>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('useable_area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_useable_area')}
              onChange={onSizeMinChange}
              value={sizeMin}
              className={styles.budgetInput}
              onBlur={onSizeMinBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={styles.dropdownControl}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_useable_area')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_useable_area')}
              fullWidth
              onChange={onSizeMaxChange}
              value={sizeMax}
              className={styles.budgetInput}
              onBlur={onSizeMaxBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={styles.dropdownControl}
          >
            <Select
              className={styles.currencyInput}
              value={sizeUnit}
              onChange={onSizeUnitChange}
              fullWidth
            >
              {sizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: 36 }}>
        <strong>{t('Land Area')}</strong>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-start">
              {t('min_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-start"
              label={t('min_land_size')}
              onChange={onLandFromChange}
              value={landFrom}
              className={styles.budgetInput}
              onBlur={onLandFromBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
      <Form.Item style={{ marginTop: -12 }}>
        <div className={styles.budgetGroup}>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formControl} ${styles.formBudgetInput}`}
          >
            <InputLabel htmlFor="budget-to">
              {t('max_land_size')}
            </InputLabel>
            <OutlinedInput
              id="budget-to"
              label={t('max_land_size')}
              fullWidth
              onChange={onLandToChange}
              value={landTo}
              className={styles.budgetInput}
              onBlur={onLandToBlur}
              // type="number"
              inputComponent={NumberFormatCustom as any}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className={`${styles.formCurrencyInput} ${styles.dropdownControl}`}
          >
            <Select
              className={styles.currencyInput}
              value={landSizeUnit}
              onChange={onLandSizeUnitChange}
              fullWidth
            >
              {landSizeUnitList.map((sizeUnit, index) => (
                <MenuItem value={sizeUnit}>{t(sizeUnit)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Form.Item>
    </>
  )
}

const ModalLoading = () => {
  return (
    <Container className={styles.modalSuccess} style={{ display: 'flex' }}>
      <Spin
        tip="Loading..."
        spinning={true}
        size="large"
        style={{ color: '#f4762a', fontSize: '1rem' }}
      ></Spin>
    </Container>
  )
}

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: string }
  }) => void
  name: string
}

const NumberFormatCustom = forwardRef<NumberFormatProps, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        isNumericString
      />
    )
  },
)
