import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff' // '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});


export const themeAntD = {
  token: {
    colorPrimary: '#f4762a',
    // colorFillSecondary: '#6cac19',
    colorBgLayout: '#fff',
    borderRadiusLG: 0

// @layout-body-background: #fff;
// @layout-header-background: #fff; // #f4762a;

  }
}

export default theme;