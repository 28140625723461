import { Row, Col, Divider } from 'antd'
// import { Information, OwnerInformation } from '../detail/sections'

import {
  SearchByOptions,
  LeaveYourRequirement,
  NearbyPropertyList,
  BreadcrumbBar,
  AdBannerVertical
} from '..'

import styles from './scss/detailPageLayout.module.scss'
import { useRouter } from 'next/router'
import Activities from '../Activities'

export type IDetailPageLayoutProps = {
  t: any
  breadcrumb: any
  nearbyPropertyList: any
  isMobileFilterOpen: any
  onMobileSearchOk: any
  onMobileSearchClose: any
  gallerySection: React.ReactNode
  header: React.ReactNode | React.ReactNode[]
  ownerInfo: React.ReactNode
  detail: React.ReactNode

  provinceTable: any
  actionsTable: any
  categoryTable: any
  locationTypeTable: any
  data: any,
  actionCateLocationType: any
  onSellerInfoChanged?: (seller: any) => void;
  loading: any
}

export const DetailPageLayout = (props: IDetailPageLayoutProps) => {
  const {
    t,
    nearbyPropertyList,
    isMobileFilterOpen,
    onMobileSearchOk,
    onMobileSearchClose,
    gallerySection,
    header,
    detail,
    ownerInfo,
    breadcrumb,

    provinceTable,
    actionsTable,
    categoryTable,
    locationTypeTable,
    data,
    actionCateLocationType,
    loading
  } = props

  const { locale } = useRouter()

  return (
    <Col span={24}>
      <Row>
        <SearchByOptions
          nologo
          searchbgClass={{ width: '100%' }}
          isMobileFilterOpen={isMobileFilterOpen}
          onMobileSearchOk={onMobileSearchOk}
          onMobileSearchClose={onMobileSearchClose}
          provinceTable={provinceTable}
          locationTypeTable={locationTypeTable}
          actionsTable={actionsTable}
          categoryTable={categoryTable}
          pathIds={{
            action_id: null,
            cateogry_ID: null,
            province_id: null,
            amphur_id: null,
            distinct_id: null,

            location_type_id: null,
            location_id: null,
            location_branch_id: null,
          }}
          actionCateLocationType={actionCateLocationType} 
          loading={loading}        />
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row>
        <BreadcrumbBar
          breadcrumb={breadcrumb}
          locale={locale}
          t={t}
        />
      </Row>
      <Row justify="center">
        <Col xs={24} sm={24} md={24}>
          {gallerySection}
        </Col>
      </Row>

      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          className={styles.owerInformation}
        >
          {header}
        </Col>
      </Row>

      <Row gutter={[8, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row justify="center" gutter={[8, 64]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {detail}
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              {/* <Row>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24}  md={24} lg={24} xl={24}>
                    <Row justify="center"  gutter={[8, 80]}>
                        <Col xs={24} sm={24}  md={18} lg={18} xl={18}>
                           {detail} 
                        </Col>
                        <Col xs={24} sm={24}  md={6} lg={6} xl={6} >
                            <Row>
                                {ownerInfo}
                            </Row> */}
              <Activities details={data} t={t} locale={locale} onSellerInfoChanged={props.onSellerInfoChanged} />
              <NearbyPropertyList
                list={nearbyPropertyList}
                t={t}
                locale={locale}
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Row>
                <LeaveYourRequirement t={t} />
              </Row>
              <Row>
                  <AdBannerVertical adSlot={'9031826647'} 
                    style={{width: '100%', paddingTop: '10px'}}
                  />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}
