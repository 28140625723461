import { useEffect, useState, useRef } from 'react'
import dynamic from 'next/dynamic'
import styles from './mapsPanel.module.scss'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import { useLoadScript } from '@react-google-maps/api'
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

const LeafletMapMultiMarkComponent = dynamic(
  () => import('../LeafletMapSearchMultiMark'),
  {
    ssr: false,
  },
)

export interface ISelectedAddress {
  country: string
  province: string
  amphur: string
}

export interface ISelected {
  label: string
  lat: number
  lng: number
  address?: ISelectedAddress
  distance: number
  location?: any
}

export interface ILatlng {
  lat: number
  lng: number
}

export const MapsPanel = ({
  closeMapsPanel,
  setLocation,
  location,
  t,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA2VMaK5n4GOwHQPcr4aXDCJE34FYkUX1k',
    libraries: ['places'],
  })
  const [selected, setSelected] = useState<ISelected>({
    label: null,
    lat: null,
    lng: null,
    distance: 5000,
  })
  const [placeList, setPlaceList] = useState<ISelected[]>(location)

  const removePlace = ({ lat, lng }) => {
    setPlaceList(
      placeList.filter((e) => e.lat !== lat && e.lng !== lng),
    )
    setRemovePlaceLatlng({ lat, lng })
  }

  const [removePlaceLatlng, setRemovePlaceLatlng] = useState<ILatlng>(
    { lat: null, lng: null },
  )

  const resetPlace = () => {
    setPlaceList([])
    setTriggerResetPlace(triggerResetPlace + 1)
  }

  const confirmLocation = () => {
    setLocation(placeList)
    closeMapsPanel()
  }

  const onDistanceChange = (e: any, label: string) => {
    let _place = [...placeList]
    const _index = _place.map((e) => e.label).indexOf(label)
    _place[_index].distance = parseInt(e.target.value) ?? 0

    setPlaceList(_place)
  }

  const [triggerResetPlace, setTriggerResetPlace] = useState(0)

  if (!isLoaded) return <div>Loading ...</div>
  return (
    <>
      <strong className={styles.labelHeader}>* {t('location')}</strong>
      <div className={styles.closeMapsPanel} onClick={closeMapsPanel}>
        ✖
      </div>
      <strong className={styles.labelCountLocation}>
        {t('choose_location')}: {placeList.length}
      </strong>
      <ListPlacesSelected
        placeList={placeList}
        removePlace={removePlace}
        onDistanceChange={onDistanceChange}
      />

      <PlacesAutocomplete
        setSelected={setSelected}
        setPlaceList={setPlaceList}
        placeList={placeList}
        t={t}
      />
      <LeafletMapMultiMarkComponent
        latlng={selected}
        removePlaceLatlng={removePlaceLatlng}
        placeList={placeList}
        triggerResetPlace={triggerResetPlace}
      />
      <ButtonSection
        resetPlace={resetPlace}
        confirmLocation={confirmLocation}
        t={t}
      />
    </>
  )
}

const PlacesAutocomplete = ({
  setSelected,
  setPlaceList,
  placeList,
  t,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete()

  const inputRef = useRef(null)

  const handleSelect = async (e, value) => {
    if (!value) return
    const address = value.label
    clearSuggestions()

    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])

    const place: ISelected = {
      label: address,
      lat,
      lng,
      distance: 5000,
    }

    if (value.terms.length > 3) {
      place.address = {
        country: value.terms[value.terms.length - 1].value,
        province: value.terms[value.terms.length - 2].value,
        amphur: value.terms[value.terms.length - 3].value,
      }
    } else if (value.terms.length == 3) {
      place.address = {
        country: value.terms[value.terms.length - 1].value,
        province: value.terms[value.terms.length - 2].value,
        amphur: value.terms[value.terms.length - 3].value,
      }
    } else if (value.terms.length == 2) {
      place.address = {
        country: value.terms[value.terms.length - 1].value,
        province: value.terms[value.terms.length - 2].value,
        amphur: '',
      }
    }

    setSelected(place)
    setPlaceList([...placeList, place])
    setValue('', false)
    inputRef.current.value = ''
  }

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={styles.formAutocomplete}
      fullWidth
    >
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        fullWidth
        options={
          data.length
            ? data.map((e) => ({
                label: e.description,
                id: e.place_id,
                terms: e.terms,
              }))
            : [{ label: '', id: '', terms: [] }]
        }
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('search_location')}
            className={styles.textField}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            inputRef={inputRef}
          />
        )}
        value={value}
        onChange={handleSelect}
      />
    </FormControl>
  )
}

const ListPlacesSelected = ({
  placeList,
  removePlace,
  onDistanceChange,
}) => {
  return (
    <div className={styles.listPlacesBorder}>
      <div className={styles.listPlacesContainer}>
        {placeList.map((e) => (
          <div className={styles.locationItem}>
            <span className={styles.labelSelect}>{e.label}</span>
            <div className={styles.rightOptions}>
              <Select
                className={styles.placeDistance}
                value={e.distance}
                onChange={(event) => onDistanceChange(event, e.label)}
              >
                <MenuItem key="1000" value="1000">
                  + 1km
                </MenuItem>
                <MenuItem key="5000" value="5000">
                  + 5km
                </MenuItem>
                <MenuItem key="10000" value="10000">
                  + 10km
                </MenuItem>
              </Select>
              <span
                onClick={() =>
                  removePlace({ lat: e.lat, lng: e.lng })
                }
              >
                ✖
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const ButtonSection = ({ resetPlace, confirmLocation, t }) => {
  return (
    <div className={styles.buttonSection}>
      <button
        type="button"
        className={styles.resetButton}
        onClick={resetPlace}
      >
        {t('reset')}
      </button>
      <button
        className={styles.confirmButton}
        onClick={confirmLocation}
      >
        {t('confirm')}
      </button>
    </div>
  )
}

export default MapsPanel
