import Head from 'next/head'
import { Row, Col } from 'antd';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next';

// import { onLoad, useClick } from '../actions'
// import { initializeStore } from '../store'
// import { useAmp } from 'next/amp'
// import styles from '../styles/Home.module.css'

// import { onLoad, useClick } from '../actions'
// import { initializeStore } from '../store'
// import { useDispatch, useSelector } from 'react-redux';
// import { render } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { IRecommentProps, PropertyTypes, Recommend } from '../components/recommended'
// import { SearchByOptions, AdBannerVertical } from '../components'
import get from 'lodash/get';
import {
  getProjectOnlyData,
  getNormal,
  getFacilities,
  getProvinceWithAmphur,
  getActions,
  getCateogry,
  getLocationType,
  getActionCateLocationType
} from '../utils/requests'

import {
  convertNameToURL
} from '../utils/utils'
import dynamic from 'next/dynamic';

import styles from '../styles/Home.module.css'
import Script from 'next/script';
import { isMobile } from 'react-device-detect';
import { LinkHits } from '../components/';

const MemoizedLinkHits = React.memo(LinkHits);
// import { AdBanner } from '../components/googleAd';

// import { 
//   MeterialHeader,
//   NineAssetHealthCheck,
//   NineAssetCards
// // } from '../ui-components/src/components'
// declare global {
//   namespace JSX {
//     interface IntrinsicElements {
//       'amp-ad': React.DetailedHTMLProps<any, any>;
//     }
//   }
// }

// export const config = { amp: 'hybrid' }

const DynamicRecommend = dynamic<IRecommentProps>(() => import('../components/recommended')
.then(module => module.Recommend), {
  ssr: false,
})

const DynamicLeaveYourRequirement = dynamic<any>(() => import('../components/')
  .then(module => module.LeaveYourRequirement), {
    ssr: false,
  })

const DynamicAdBannerVertical = dynamic<any>(() => import('../components/')
  .then(module => module.AdBannerVertical), {
    ssr: false,
  })

//SearchByOptions
const DynamicSearchByOptions = dynamic<any>(() => import('../components/')
  .then(module => module.SearchByOptions), {
    ssr: true,
  })

const DynamicAdBanner = dynamic<any>(() => import('../components/googleAd')
  .then(module => module.AdBanner), {
    ssr: false,
  })

const selector = (state) => {
  return state.lang
}

const Home = (props) => {

    // const { locale, facilities } = props;
    // const lang  = useSelector(selector);
    // const dispatch = useDispatch();
    const { t  } = useTranslation(['common', 'home']);
    // const { t } = useTranslation('home');
    // const { t: tt } = useTranslation('common');
    const router = useRouter();
    const { locale } = router;
    // const isAmp = useAmp();

    let [ loading, setLoading] = useState(true);


    const { 
      // facilities, 
      projectOnly: fistProjectOnly,
      condo,
      townhouse,
      detachedHouse,
      // homeOffice,
      // land,
      // commercialBuilding,
      // shop,
  
      actionsTable: actionsTableBase,
      categoryTable,
      provinceTable,
      locationTypeTable,
      actionCateLocationType
    }: any = props

    let [ actionsTable, setActionsTable] = useState(actionsTableBase);
    // let [ categoryTable, setCategoryTable] = useState({data: []});
    // let [ provinceTable, setProvinceTable] = useState({data: []});
    // let [ locationTypeTable, setLocationTypeTable] = useState({data: []});
    // let [ actionCateLocationType, setActionCateLocationType] = useState({data: []});
    
    let [ projectOnly, setprojectOnly] = useState(fistProjectOnly);
    
    // let [ condo, setcondo] = useState(null);
    // let [ detachedHouse, setdetachedHouse] = useState(null);
    // let [ townhouse, settownhouse] = useState(null);
    
    let [ homeOffice, sethomeOffice] = useState(null);
    let [ commercialBuilding, setcommercialBuilding] = useState(null);
    let [ land, setland] = useState(null);
    let [ shop, setshop] = useState(null);
    let [ facilities, setfacilities] = useState(null);

    

    const btnClick = () => {
      // useClick(dispatch);
    }
    // console.log("projectOnly", props.condo)
    // console.log("actionCateLocationType:", actionCateLocationType);

    // console.log('home: ', locale, t('condoproject'))
    // console.log('home t: ', t) 
    const category = [
      {
        title: t('Condo new project', { ns: 'home' }),
        type: PropertyTypes.Project,
        Recommend: projectOnly?.condo,
        linkpath: `/${t('project', { ns: 'common'})}/${t('condo', { ns: 'common'})}`
      },
      {
        title: t('Condo resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: condo,
        linkpath: `/${t('sell')}/${t('condo', { ns: 'common'})}`
      },
      {
        title: t('Single house new project', { ns: 'home' }),
        type: PropertyTypes.Project,
        Recommend: projectOnly?.detachedHouse,
        linkpath: `/${t('project')}/${t('house', { ns: 'common'})}`
      },
      {
        title: t('Single house resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: detachedHouse,
        linkpath: `/${t('sell', { ns: 'common'})}/${t('house', { ns: 'common'})}`
      },
      {
        title: t('Townhouse new project', { ns: 'home' }),
        type: PropertyTypes.Project,
        Recommend: projectOnly?.townhouse,
        linkpath: `/${t('project')}/${t('townhouse', { ns: 'common'})}`
      },
      {
        title: t('Townhouse resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: townhouse,
        linkpath: `/${t('sell')}/${t('townhouse', { ns: 'common'})}`
      },
      
      {
        title: t('Homeoffice new project', { ns: 'home' }),
        type: PropertyTypes.Project,
        Recommend: projectOnly?.homeOffice,
        linkpath: `/${t('project')}/${t('homeoffice', { ns: 'common'})}`
      },
      {
        title: t('Homeoffice resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: homeOffice,
        linkpath: `/${t('sell')}/${t('homeoffice', { ns: 'common'})}`
      },
      // commercial building
      {
        title: t('Commercial building resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: commercialBuilding,
        linkpath: `/${t('sell')}/${t('homeoffice', { ns: 'common'})}`
      },
      {
        title: t('Land resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: land,
        linkpath: `/${t('sell')}/${t('land', { ns: 'common'})}`
      },
      {
        title: t('Shop resale', { ns: 'home' }),
        type: PropertyTypes.Normal,
        Recommend: shop,
        linkpath: `/${t('sell')}/${t('shop', { ns: 'common'})}`
      }
    ];

   

    useEffect(() => {
      // var ads = document.getElementsByClassName("adsbygoogle").length;
      // for (var i = 0; i < ads; i++) {
      //   // try {
      //     //@ts-ignore
      //     (window.adsbygoogle = window.adsbygoogle || []).push({});
      //   // } catch (e) { }
      // }
      // console.log('xxxxx first load: ', projectOnly)
      const loadTables  = async() => {
        setLoading(true);
        const allData = await loadData2(locale);
        const {
          facilities,
          projectOnly: newProjectOnly,
          homeOffice,
          land,
          commercialBuilding,
          shop,
          actionsTable
        }: any = allData.props;
        // const { facilities, projectOnly,
        //   condo,
        //   townhouse,
        //   detachedHouse,
        //   homeOffice,
        //   land,
        //   commercialBuilding,
        //   shop,
      
        //   actionsTable,
        //   categoryTable,
        //   provinceTable,
        //   locationTypeTable }: any = allData.props
  
        setfacilities(facilities);
        setprojectOnly({
          ...newProjectOnly,
          ...projectOnly
        });
        // setcondo(condo);
        // settownhouse(townhouse);
        // setdetachedHouse(detachedHouse);
        // setprojectOnly(projectOnly);
        sethomeOffice(homeOffice);
        setland(land);
        setcommercialBuilding(commercialBuilding);
        setshop(shop);
  
        // setProvinceTable(provinceTable|| { data: [] });
        // setActionsTable(actionsTable || { data: [] });
        // setCategoryTable(categoryTable|| { data: [] });
        // setLocationTypeTable(locationTypeTable|| { data: [] });
        // const [actionsTable,
        //   // categoryTable,
        //   // provinceTable,
        //   // locationTypeTable,
        //   // actionCateLocationType
        // ] = await Promise.all([
        //     getActions(locale),
        //     // getCateogry(),
        //     // getProvinceWithAmphur(locale),
        //     // getLocationType(),
        //     // getActionCateLocationType()
        //   ])

        setActionsTable(actionsTable);

        setLoading(false);
      }
      
      loadTables();

      console.log('xxxxxx me me')
    }, [locale]);

  const handleCardOnClick = (e, linkpath) => {
    console.log(e, linkpath);
    const { asPath } = router;
    let name = `name.${router.locale.toUpperCase()}`;
    if(router.locale === 'cn' && !e.project.name[router.locale.toUpperCase()]){
      name = `name.EN`;
    }

    let nameCut = get(e.project, name, router.locale.toUpperCase())
    if(e.property) {
      nameCut = get(e.property, name, router.locale.toUpperCase())
    }

    let href = `${linkpath}/${e.property && e.property.id || e.project.id}/${nameCut && convertNameToURL(nameCut)}`
    href = href?.substring(0, 2048 - 22);

    console.log('href: ', href, asPath)
    router.push(href, ``, { shallow: false})
  }

  const handleCardOnMouseDown = (e, d, linkpath) => {
    // e.preventDefault();
    // const { asPath, basePath, pathname } = router;
    // const href = `${linkpath}/${d.property && d.property.id || d.project.id}`
    // console.log('onmousedown', href, d, asPath, basePath, pathname);

    // window.open(`http://${window.location.host}${href}`, '_blank')
    return false;
  }


  const DesktopRender = () => {
    return (
      <>
        <DynamicSearchByOptions nologo 
          isMobileFilterOpen={props.isMobileFilterOpen} 
          onMobileSearchOk={props.onMobileSearchOk}
          onMobileSearchClose={props.onMobileSearchClose}
          actionsTable={actionsTable}
          categoryTable={categoryTable}
          provinceTable={provinceTable}
          locationTypeTable={locationTypeTable}
          pathIds={
            {
                action_id: null,
                cateogry_ID: null,
                province_id: null,
                amphur_id: null,
                distinct_id: null,
                location_type_id: null,
                location_id: null,
                location_branch_id: null
            }
          }
          actionCateLocationType={actionCateLocationType}
          loading={loading}
          />
        <h1>{`${t('home:seo.title')}`}</h1>
        {`${t('home:seo.desc')}`} 
        <Row gutter={16}>
          <Col xs={24} sm={24} md={18} lg={18} xl={19} style={{ paddingTop: "40px" }}>
            {!loading && category.map((cat, index) => {
              if (!cat) {
                return [];
              }

              return <DynamicRecommend
                key={cat.type}
                title={cat.title}
                data={cat.Recommend}
                locale={locale}
                type={cat.type}
                facilities={facilities}
                linkpath={cat.linkpath}
                onClick={handleCardOnClick}
                onMouseDown={handleCardOnMouseDown} />;
            })}
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={5} style={{ paddingTop: '10px' }} className={styles.leaveYourRequirementCol}>
            <DynamicLeaveYourRequirement t={t} />
            <Row>
              <DynamicAdBannerVertical adSlot={'9031826647'}
                style={{ width: '100%', paddingTop: '10px' }} />
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ padding: '20px', width: '100%' }}>
        {/* <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{minHeight: '100px'}}>
         <DynamicAdBanner  style={{margins: 'auto'}}/>
          </Col> */}
        </Row>
      </>
    )
  }

  const MobileRender = () => {

    const Items =  category.map((cat, index) => {
      if (!cat) {
        return [];
      }
      
      return <Recommend
          key={cat.type}
          title={cat.title}
          data={cat.Recommend}
          locale={locale}
          type={cat.type}
          facilities={facilities}
          linkpath={cat.linkpath}
          onClick={handleCardOnClick}
          onMouseDown={handleCardOnMouseDown} />
    });
   
    return (
      <>
        {
          !loading && <DynamicSearchByOptions nologo 
          isMobileFilterOpen={props.isMobileFilterOpen} 
          onMobileSearchOk={props.onMobileSearchOk}
          onMobileSearchClose={props.onMobileSearchClose}
          actionsTable={actionsTable}
          categoryTable={categoryTable}
          provinceTable={provinceTable}
          locationTypeTable={locationTypeTable}
          pathIds={
            {
                action_id: null,
                cateogry_ID: null,
                province_id: null,
                amphur_id: null,
                distinct_id: null,
                location_type_id: null,
                location_id: null,
                location_branch_id: null
            }
          }
          actionCateLocationType={actionCateLocationType}
          loading={loading}
          />
        }
        <h1>{`${t('home:seo.title')}`}</h1>
        {`${t('home:seo.desc')}`} 
        <Row gutter={16}>
          <Col xs={24} sm={24} md={18} lg={18} xl={19} style={{ paddingTop: "40px" }}>
            {!loading && Items}
          </Col>
        </Row>
        {/* <Row justify="center" style={{ padding: '20px', width: '100%' }}>
          <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{minHeight: '100px'}}>
            <DynamicAdBanner  style={{margins: 'auto'}}/>
          </Col>
        </Row> */}
         <Row>
          <MemoizedLinkHits t={t} locale={locale} />
        </Row>
      </>
    )
  }

    return (
      <>
        <Head>
          {/* <title>9asset</title> */}
          <title>{`${t('home:seo.title')}`}</title>
          <meta name="description" content={`${t('home:seo.desc')}`} />
          <meta name="keywords" content={`${t('home:seo.keywords')}`} />
          <meta name="author" content="9asset" />
          <meta property="og:site_name" content="9asset" />
          <meta property="og:url" content="https://www.9asset.com" />
          <meta property="og:type" content="WebSite" />
          <meta property="og:title" content={`${t('home:seo.title')}`} />
          <meta property="og:description" content={`${t('home:seo.desc')}`} />
          <meta property="og:image" content="" />

          <meta itemProp="description"  content={`${t('home:seo.desc')}`} />
          <meta itemProp="image" content="" />
          <meta itemProp="name" content={`${t('home:seo.title')}`}  />

          {/* <!-- Twitter  --> */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:description"  content={`${t('home:seo.desc')}`} />
          <meta name="twitter:image" content="" />
          <meta name="twitter:title" content={`${t('home:seo.title')}`}   />

          <link rel="icon" href="/favicon.ico" />
          <meta name="robots" content="index,follow, noimageindex" />
          <script type="application/ld+json"  dangerouslySetInnerHTML={{ __html: JSON.stringify({ 
                "@context" : "https://schema.org",
                "@type" : "WebSite",
                "name" : "9asset.com",
                "alternateName" : ["9Asset.com", "9ASSET.COM", "9asset.com"],
                "url" : "https://www.9asset.com/"
              })}} />
          <link rel="canonical" href='https://www.9asset.com' />
        </Head>

        {
          `${process.env.NEXT_PUBLIC_DISABLE_GOOGLEADS}` == 'true' ? undefined: 
          <Script 
            strategy="lazyOnload" 
            async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3702887933016265"
            crossOrigin="anonymous">
          </Script>
        }
        {/* <Script  
          strategy="lazyOnload" 
          async 
          custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js">  
        </Script> */}
        {
          isMobile ? <MobileRender />: <DesktopRender />
        }
      </>
    )
}

const loadData = async (locale) => {
  const [
    facilities,
    projectOnlyCondo,
    projectOnlyTownHouse,
    projectOnlyDetachedHouse,
    projectOnlyHomeOffice,
    projectOnlyLand,
    projectOnlyCommercialBuilding,
    projectOnlyShop,

    condo,
    townhouse,
    detachedHouse,
    homeOffice,
    land,
    commercialBuilding,
    shop,

    actionsTable,
    categoryTable,
    provinceTable,
    locationTypeTable,
    actionCateLocationType
  ] = await Promise.all([
    null, // getFacilities(),
    getProjectOnlyData(1, locale), // condo
    getProjectOnlyData(2, locale), // Townhouse
    getProjectOnlyData(3, locale), // Detached House
    getProjectOnlyData(4, locale), // Home office
    null,// getProjectOnlyData(5, locale), // Land
    null,// getProjectOnlyData(9, locale), // Commercial Building
    null, // getProjectOnlyData(10, locale), // Shop space,

    getNormal(1, undefined, locale),
    getNormal(2, undefined, locale), // Townhouse
    getNormal(3, undefined, locale), 
    null, // getNormal(4, undefined, locale),
    null, // getNormal(5, undefined, locale),
    null, // getNormal(9, undefined, locale),
    null, // getNormal(10, undefined, locale), // shop

    // static tables
    getActions(locale),
    getCateogry(),
    getProvinceWithAmphur(locale),
    getLocationType(),
    getActionCateLocationType()
  ]);

  // const [ provinceTable, actionsTable, categoryTable ] = await Promise.all([
  //   getProvinceWithAmphur(),
  //   getActions(locale),
  //   getCateogry()
  // ])
  

  return {
    props: {
    //  ...(await serverSideTranslations(locale, ['common', 'footer', 'home'])),
     projectOnly: {
      condo: projectOnlyCondo || null,
      detachedHouse: projectOnlyDetachedHouse || null,
      townhouse: projectOnlyTownHouse || null,
      homeOffice: projectOnlyHomeOffice || null,
      land: projectOnlyLand || null,
      commercialBuilding: projectOnlyCommercialBuilding || null,
      shop: projectOnlyShop || null
     },
     condo: condo || null,
     detachedHouse: detachedHouse || null,
     townhouse: townhouse || null,
     homeOffice: homeOffice || null,
     land: land || null,
     commercialBuilding: commercialBuilding || null,
     shop: shop || null,
     facilities: facilities || null,
     locale,
     actionsTable: actionsTable  || null,
     categoryTable: categoryTable || null,
     provinceTable: provinceTable || null,
     locationTypeTable: locationTypeTable || null,
     actionCateLocationType: actionCateLocationType || null
    },
    revalidate: 10
  }
}


const loadData2 = async (locale) => {
  const [
    facilities,
    projectOnlyCondo,
    projectOnlyTownHouse,
    projectOnlyDetachedHouse,
    projectOnlyHomeOffice,
    projectOnlyLand,
    projectOnlyCommercialBuilding,
    projectOnlyShop,

    condo,
    townhouse,
    detachedHouse,
    homeOffice,
    land,
    commercialBuilding,
    shop,

    actionsTable,
    // categoryTable,
    // provinceTable,
    // locationTypeTable,
    // actionCateLocationType
  ] = await Promise.all([
    getFacilities(),
    null, // getProjectOnlyData(1, locale), // condo
    null, // getProjectOnlyData(2, locale), // Townhouse
    null, // getProjectOnlyData(3, locale), // Detached House
    null, // getProjectOnlyData(4, locale), // Home office
    null, //getProjectOnlyData(5, locale), // Land
    null, //getProjectOnlyData(9, locale), // Commercial Building
    null, //getProjectOnlyData(10, locale), // Shop space,

    null, // getNormal(1, undefined, locale),
    null, // getNormal(2, undefined, locale), // Townhouse
    null, // getNormal(3, undefined, locale), 
    getNormal(4, undefined, locale),
    getNormal(5, undefined, locale),
    getNormal(9, undefined, locale),
    getNormal(10, undefined, locale), // shop

    // static tables
    getActions(locale),
    // getCateogry(),
    // getProvinceWithAmphur(locale),
    // getLocationType(),
    // getActionCateLocationType()
  ]);

  // const [ provinceTable, actionsTable, categoryTable ] = await Promise.all([
  //   getProvinceWithAmphur(),
  //   getActions(locale),
  //   getCateogry()
  // ])
  

  return {
    props: {
    //  ...(await serverSideTranslations(locale, ['common', 'footer', 'home'])),
     projectOnly: {
      condo: projectOnlyCondo || null,
      detachedHouse: projectOnlyDetachedHouse || null,
      townhouse: projectOnlyTownHouse || null,
      homeOffice: projectOnlyHomeOffice || null,
      land: projectOnlyLand || null,
      commercialBuilding: projectOnlyCommercialBuilding || null,
      shop: projectOnlyShop || null
     },
     condo: condo || null,
     detachedHouse: detachedHouse || null,
     townhouse: townhouse || null,
     homeOffice: homeOffice || null,
     land: land || null,
     commercialBuilding: commercialBuilding || null,
     shop: shop || null,
     facilities: facilities || null,
     locale,
     actionsTable: actionsTable  || null,
    //  categoryTable: categoryTable || null,
    //  provinceTable: provinceTable || null,
    //  locationTypeTable: locationTypeTable || null,
    //  actionCateLocationType: actionCateLocationType || null
    },
    revalidate: 10
  }
}
export const getStaticProps = async ({ locale }) => {
  const allData = await loadData(locale);

  return {
    props: {
     ...(await serverSideTranslations(locale, ['common', 'footer', 'home'])),
    //  locale,
     ...allData.props
    },
    revalidate: 3600
  }
}


export default Home;