export const Header1 = (props) => {

    return (
        <div className="Header1-text">
            { props.children }
        </div>
    )
}

export const Header2 = (props) => {

    return (
        <div className="Header2-text">
            { props.children }
        </div>
    )
}