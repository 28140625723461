import {
    Row,
    Col,
    Avatar,
    Card,
    Button,
    Rate
} from 'antd';

import {
    UserOutlined,
    PhoneTwoTone
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { 
    getFieldWithlocale,
    getAddressFromProperty
} from '../../utils/utils'

import moment from 'moment';

import { useRouter } from 'next/router';

import RenderDetail from './renderDetail';

export const Information = (props) => {
    const { t } = useTranslation('common');

    const { data, locale } = props;
    const tableRowEvent = {backgroundColor: '#f1f1f1', padding: '4px'};
    const tableRowOdd = {padding: '4px'};
    // const header = {fontWeight: 'bold'};

    // let address = `${data.House_No || ''}`
    // address += ` ${data.Address_TH || ''}`
    // address += ` ${data.Road_TH || ''}`
    // address += ` ${data.Alley_TH || ''}`
    // address += ` ${data.AMPHUR_NAME_TH || ''}`
    // address += ` ${data.DISTRICT_NAME_TH || ''}`
    // address += ` ${data.PROVINCE_NAME_TH || ''}`
    // address += ` ${data.POSTCODE || ''}`

    const address = getAddressFromProperty(data, locale);

    return (
        <>
            <RenderDetail data={data} address={address} t={t} locale={locale} />
        </>
    )
}

type IOwnerInformationProps = {
    firstName: string;
    lastName: string;
    agent: string;
    mobile: string;
    lastUpdatedDate: string;
    userid: string;
    company?: string;
}

export const OwnerInformation = (props: IOwnerInformationProps) => {
    const { t } = useTranslation('common');
    const {locale } = useRouter();
    // const { data, locale } = props;

    return (
        <Col span={24} style={{paddingTop: '11px'}}>
            <Card>
                <>
                    <Row align="middle">
                        <Col span={24}>
                            <h2>{props.company}</h2> 
                        </Col>
                    </Row>
                    <Row  align="top">
                        <Col flex="80px">
                            <Row>
                                <Col style={{margin: 'auto'}}>
                                    <Avatar shape="square" size={64} icon={<UserOutlined />} style={{backgroundColor: '#416db6'}}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Rate value={5} disabled style={{fontSize: '9px'}}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="auto" style={{paddingLeft: '20px'}}>
                            <Row style={{fontSize: 'large'}}>
                                <div>{props.firstName}</div>
                                <div style={{paddingLeft: '10px'}}>{props.lastName}</div>
                            </Row>
                            <Row>
                                <div>{props.agent || t('Private')}</div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{fontSize: 'large', paddingTop: '20px', paddingLeft: '20px'}}>
                            {
                                props.mobile ?
                                <Button block size={'large'}
                                    icon={<PhoneTwoTone style={{fontSize: '24px', top: '-4px', position: 'relative'}} twoToneColor="#52c41a"/>}>
                                    <span style={{paddingLeft: '10px', color: '#f4762a'}}>{props.mobile}</span>
                                </Button>
                                : undefined
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{paddingTop: '20px',  paddingLeft: '20px'}}>
                            <span style={{fontWeight: 'bold'}}>{`${t('ID')}:`}</span>
                            <span style={{paddingLeft: '10px'}}>{props.userid}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{paddingTop: '10px',  paddingLeft: '20px'}}>
                            <span style={{fontWeight: 'bold'}}>{`${t('Created date')}:`}</span>
                            <span style={{paddingLeft: '10px'}}>{moment(props.lastUpdatedDate).format('YYYY/MM/DD HH:mm')}</span>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Divider />
                    </Row>
                    <Row justify="center">
                        <Col>
                            <Button>{t('view all')}</Button>
                        </Col>
                    </Row> */}
                </>
            </Card>
        </Col>
    )
}