import {
    Row,
    Col,
    Card,
    Typography
} from 'antd';
import { Paper } from '@mui/material';
// import moment from 'moment';
import { 
    // unSerializePHP, 
    // getFieldWithlocale, 
    // convertUnitTypeToString,
    // addFacilitiesName,
    formatPriceSearchNo00
} from '../../utils/utils';
import styles from './scss/nearbyPropertyList.module.scss';
import {
    generateLocation
} from '../recommended'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot
} from '@fortawesome/free-solid-svg-icons'
// import { Key } from '@mui/icons-material';
import { MyImage } from '../myImage';
const sourcePic = 'https://cdn.9asset.com/download/project_image';

const generateImageFallback = (d) => {
    return d.property.images?.replace('property_gallery', 'property_image') 
        || `${sourcePic}/${d.project.id}/${d.project.images}` 
}


const NearByCard = (props) => {
    const key = props.property.id;
    const name = props.property.name[props.locale.toUpperCase()];
    const detail = '';
    const imageUrl = props.property.images;
    const update = undefined;
    const location = generateLocation(props.locale, props);
    const { t } = props;
    const imageurlfallback = generateImageFallback(props);

    // const distince = `${t('ห่างออกไป')} ${props.property?.distance?.toFixed(2)}`

    const handleOnMouseDown= (e) => {
        e.preventDefault();
        let href = `/${t('ทั้งหมด')}/${t('estate')}/${key}`
        console.log('href: ', href)
        window.open(`http://${window.location.host}${href}`, '_self')
        return false;
    };
    
    return (    
        <Col className="gutter-row" xs={24} sm={24} md={24}>
        <Paper className={styles.recommendCardContainer} elevation={0}>
            <Card
                key={key}
                hoverable 
                // cover={<img className={styles.cardImage} alt={' '} src={`${imageUrl}`} 
                //     style={{height: '150px', width: '100%', maxWidth: '100%', margin: 'auto'}} />}
                bodyStyle={{padding: '8px'}}
                onClick={handleOnMouseDown}
                onMouseDown={handleOnMouseDown}
            >
                {/* <Card.Meta title={<div className={styles.cardmeta}>{name} </div>} /> */}
                <Row gutter={[8,0]}>
                    <Col span={10}>
                        <div>
                            <MyImage className={styles.cardImage} alt={' '} src={`${imageUrl}`} 
                                imagestyle={styles.cardImage}
                                imageurlfallback={`${imageurlfallback}`}
                                customNoImageStlye={{
                                    maxHeight: "75px",
                                    padding: "0px !important",
                                    margins: "0px"
                                }}
                                onError={function(){
                                    console.log('image error xxxxxxxx')
                                }} 
                            />
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row>
                            <Typography.Text ellipsis={true}  className={styles.cardmeta}>{name}</Typography.Text> 
                        </Row> 
                        <Row ><Typography.Text ellipsis={true}>{location}</Typography.Text></Row>
                        <Row ><Typography.Text ellipsis={true}>{detail}</Typography.Text></Row>
                        <Row>
                        {
                            props.property && props.property.sales === 1 ? 
                                <p >
                                    <span>{t('ขาย')}</span>
                                    <span className={styles.price}>{ `${formatPriceSearchNo00(props.property.sell)}`}</span>
                                </p>
                            : undefined
                        }
                        {
                            props.property && props.property.rent === 1 ? 
                                <p >
                                    <span>{t('เช่า')}</span>
                                    <span className={styles.pricerent}>{`${formatPriceSearchNo00(props.property.price_rent)}`}</span>
                                </p>
                            : undefined
                        }
                        </Row>
                    </Col>
                </Row>
                {/* <p style={{fontSize: 'small'}}>{distince} </p> */}
            </Card>
        </Paper>
        </Col>
    )
}

export interface InearbyPropertyList {
    list: any [];
    t: any;
    locale: string;
}
export const NearbyPropertyList = (props: InearbyPropertyList) => {

    const { list, t, locale } = props;

    return (
        <Row className={styles.nearbyContainer} gutter={[16, 16]}>
            <Col span={24} >
                <div style={{
                    fontSize: 'large',
                    color: '#f4762a', 
                    textAlign: 'center'
            }}><FontAwesomeIcon icon={faLocationDot} size="1x" className={styles.headerIcon}/>{ t('Similar Property') }</div>
            </Col>
            <Col span={24}>
                <Row gutter={[8, 8]}>
                {
                    list && list.map((l) => {
                        console.log(l.property.name);
                    
                        return <NearByCard {...l} t={t} locale={locale}/> 
                    })
                }
                </Row>
            </Col>
        </Row>
    )
}