import {
    Row,
    Col,
    Tabs,
} from 'antd';
// import { useTranslation } from 'react-i18next';
import { 
    getFieldWithlocale,
    formatPrice,
    getProjectImageUrl,
    getProjectFloorPlanImageUrl,
    getUser
} from '../../utils/utils'
// import ImageGallery from 'react-image-gallery';
// import { Parser as HtmlToReactParser} from 'html-to-react';
import {
    Header1,
    Header2
} from '../myTheme';
import {
    OwnerInformation
} from '../detail/sections'
import {
    GallaryView
} from '../gallaryview';

// import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import styles from './styles.module.scss';

import dynamic from 'next/dynamic'
import { DetailPageLayout } from '../detailPageLayout';
import { useEffect, useState, useMemo } from 'react';
import { getSearchList, handleViewActivity } from '../../utils/requests';
import moment from 'moment';
import YouTube, { YouTubeProps } from 'react-youtube';
import NearbyLocationList from '../nearbyLocationList';
import getVideoId from 'get-video-id';

const LeafletMap: any = dynamic(() => import('../LeafletMap'), { ssr: false });


type ProjectDetailProps = {
    locale: string;
    data: any;
    t: any;
    isMobileFilterOpen: any;
    onMobileSearchOk: any
    onMobileSearchClose: any;
    breadcrumb: any;
    actionsTable: any;
    categoryTable: any;
    provinceTable: any;
    locationTypeTable: any;

    onSellerInfoChanged?: (seller: any) => void;
}

const Option = (header: string, data: any[], locale: any) => {
    if(data
        && data.length > 0) {
        return (
            <Row>
                <Col span={24}>
                    <Row >
                        <Header1>{header}</Header1>
                    </Row>
                </Col>
                <Col span={20}>
                    <Row>
                        {
                            data?.map((p) => {
                                return <Col xs={24} md={10} className={styles.facilityContainer} >
                                    <div className={styles.facilitieIcon} ></div>
                                    <div className={styles.facilitieText}>
                                        { 
                                            getFieldWithlocale(p,'Name', locale) 
                                            || getFieldWithlocale(p,'specail_Name', locale)
                                        }
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Col>
            </Row>
        )
    }

    return undefined;
}

const projectUnitDetail = (category: string, data: any[], locale: any, t) => {
    const unitType = {
        '1br': t('1 bedroom'),
        '2br': t('2 bedroom'),
        '3br': t('3 bedroom'),
        '4br': t('4 bedroom'),
        '5br': t('5 bedroom'),
        'Commer': t('commercial'),
        'Detached': t('Detached'),
        'Duplex': t('Duplex'),
        'HOthers': 'Others',
        'Otherspro': 'Others',
        'Penthouse': t('Penthouse'),
        'HOffice': t('homeoffice'),
        'HTwin': t('twinhome'),
        'Pool': t('Pool Villa'),
        'Villa': t('Villa'),
        'Shop': t('Shop'),
        'studio': t('Studio'),
        'Sky': t('Sky Villa'),
        'Townhouse': t('townhouse'),
        'Triplex': t('Triplex'),
    }
    return (
        <>
            {/* <Col span={12}>
                <Header2>{category}</Header2>
            </Col> */}
            {data?.map((item, index) => {
                if(!['HOthers', 'Otherspro'].includes(item.unit_Type)){
                    return (
                        <>
                            <Col span={24}>
                                <Header2>{item.unit_Type ? unitType[item.unit_Type] : ''}</Header2>
                            </Col>
                            <Col span={24}>
                                <p>
                                    {item.unit_fl ? <span>{category} <span className='highlight-text'>{item.unit_fl}</span> {t('floor-unit')} {' '}</span> : null}
                                    {item.unit_Series ? <span>{t('series')} <span className='highlight-text'>{item.unit_Series}</span> , {' '}</span> : null} 
                                    {item.unit_Br ? <span><span className='highlight-text'>{item.unit_Br}</span> {t('bedroom')} {' '}</span> : null}
                                    {item.unit_Bath ? <span><span className='highlight-text'>{item.unit_Bath}</span> {t('bathroom')} {' '}</span> : null}
                                    {item.unit_Sqm ? <span><span className='highlight-text'>{item.unit_Sqm}</span> {t('square meter')} {' '}</span> : null}
                                    {item.unit_Land ? <span>{t('land')} <span className='highlight-text'>{item.unit_Land}</span> {t('square wa')} {' '}</span> : null}
                                    {item.unit_Park ? <span>{t('parking')} <span className='highlight-text'>{item.unit_Park}</span> {t('car-unit')} {' '}</span> : null}
                                    {item.unit_Price ? <span>{t('price-start')} <span className='highlight-text'>{formatPrice(item.unit_Price, locale.toLowerCase())}</span></span> : null}
                                </p>
                            </Col>
                            {item.unit_Images1 && (
                                <Col span={8}>
                                    <img src={`https://cdn.9asset.com/download/project_unit/${item.project_ID}/${item.unit_Images1}`} width="100%" />
                                </Col>
                            )}
                            {item.unit_Images2 && (
                                <Col span={8}>
                                    <img src={`https://cdn.9asset.com/download/project_unit/${item.project_ID}/${item.unit_Images2}`} width="100%" />
                                </Col>
                            )}
                            {item.unit_Images3 && (
                                <Col span={8}>
                                    <img src={`https://cdn.9asset.com/download/project_unit/${item.project_ID}/${item.unit_Images3}`} width="100%" />
                                </Col>
                            )}
                        </>
                    )
                }
            })}
            <Col span={24} style={{ marginTop: 20 }}>
                {data?.filter((item) => ['HOthers', 'Otherspro'].includes(item.unit_Type)).map((item, index) => {
                    return (
                        <p>
                            <div dangerouslySetInnerHTML={{ __html: item[`unit_Others_${locale}`] ? item[`unit_Others_${locale}`] : item[`unit_Others_EN`]}} />
                        </p>
                    )
                })}
            </Col>
        </>
    )
}

const projectInfoSection = (header: string, data: any[], locale: any) => {
    if(data
        && data.length > 0) {
        return (
            <Row>
                <Col span={24}>
                    <Row >
                        <Header2>{header}</Header2>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        {
                            data?.map((p) => {
                                return <Col span={12}>
                                    { 
                                        getFieldWithlocale(p,'Name', locale) 
                                        || getFieldWithlocale(p,'specail_Name', locale)
                                    }
                                </Col>
                            })
                        }
                    </Row>
                </Col>
            </Row>
        )
    }

    return undefined;
}

export const ProjectDetailSection = ({ data, locale, t,
        isMobileFilterOpen,
        onMobileSearchOk,
        onMobileSearchClose,
        breadcrumb ,
        actionsTable,
        categoryTable,
        provinceTable,
        locationTypeTable,
        onSellerInfoChanged,
    }: ProjectDetailProps) => {
    const language = locale.toLocaleUpperCase();

    const [nearbyPropertyList, setNearbyPropertyList] = useState([]);

    console.log('ProjectDetailSection: ',  locale, data);
    const images = data.images?.map((m) => {
        const urls = getProjectImageUrl(data.project_ID, m.gallery_Images);
    
        return {
          original: urls.full,
          thumbnail: urls.thumb,
          originalClass: 'full-image-class'
        }
      }) || [];

    const projectName = data[`project_Name_${language}`] ? data[`project_Name_${language}`] : data[`project_Name_EN`];
    const address = `${data[`Alley_${language}`] || ''} ${data[`Road_${language}`] || ''} ${data[`AMPHUR_NAME_${language}`] || ''} ${data[`PROVINCE_NAME_${language}`] || ''}`

    const planImages = data.floorPlan?.map((m) => {
        const urls = getProjectFloorPlanImageUrl(data.project_ID, m.gallery_Images);
    
        return {
          original: urls.full,
          thumbnail: urls.thumb,
          originalClass: 'full-image-class'
        }
      }) || [];

    const projectInformation = data 
        && data.projectInfo 
        && data.projectInfo.length > 0 
        && data.projectInfo[0] || {};

    useEffect(()=> {
        const fetchNearbyData = async() => {
            // if(action === 'เช่า' || action === 'ให้เช่า') {
            //     sell = false;
            //     rent = true;
            // } else {
            //     sell = true;
            //     rent = false;
            // } 

            const searchParams = {
                "page": 1,
                "limit": 5,
                "sell": true,
                "rent": true,
                "propertystatus": true,
                "categoryid": data.category_ID,
                "lat": data.project_Latitude,
                "lng": data.project_Longitude
            }
            const nearbyProperty = await getSearchList(searchParams)
            const nearbyPropertyList = nearbyProperty 
                && nearbyProperty.data 
                && nearbyProperty.data.items.length > 0 
                && nearbyProperty.data.items || [];

            setNearbyPropertyList(nearbyPropertyList)
        }

        fetchNearbyData();

    }, [])

    useEffect(() => {
        if(!data.project_ID) return

        const addToViewActivity = async() => {
            const user = await getUser();
            if(user) {
                handleViewActivity("project", Number(data.project_ID), user.id);
            }
        }
        addToViewActivity();
    }, [data.project_ID])
    
    
    const nearbyList = useMemo(() => {
        // console.log('nearbyList', data);
        return data?.Nearby_Location && Object.entries(data.Nearby_Location).map(([key, value], index) => {
            return {
                key: index.toString(),
                label: value[0][`location_type_name_${locale.toUpperCase()}`],
                children: <NearbyLocationList data={value} locale={locale} />
            }
        })
    }, [data])

    const opts: YouTubeProps['opts'] = {
        width: '100%',
        height: '100%',
        // playerVars: {
        //     autoplay: 1
        // }
    }

    const getProjectPricePerUnits = () => {
        if(data?.projectInfo) {
            return `${data?.projectInfo[0].Starting_Price} / ${data?.projectInfo[0].Price_units} ${t('ตร.ม.')}`
        }

        return `- ${t('ตร.ม.')}`
    }

    const oldVesrion = (
        <Col span={24}>
            <Row justify="space-between" align="top">
            <Col xs={24} sm={24}  md={16}>
                <h1>{data.project_Name_TH}</h1>
            </Col>
            <Col xs={24} sm={24}  md={8} style={{textAlign: 'right'}}>
                <Row justify="end" >
                    <h1>
                        {
                            `${formatPrice(data.project_Price)}`
                        }
                    </h1>
                </Row>
                <Row justify="end">
                    <Col xs={24} sm={24}  md={8} style={{textAlign: 'right'}}>
                        {
                              data?.projectInfo && `${formatPrice(data?.projectInfo && data?.projectInfo.length > 0 ? data?.projectInfo[0].Price_units : 0)} / ${t('ตร.ม.')}`
                        }
                    </Col>
                </Row>
            </Col>
            </Row>
            <Row justify="space-between" align="top">
                <Col xs={24} sm={24}  md={16}>
                    {address}
                </Col>
            </Row>
            <Row justify="center">
                <Col xs={24} sm={24}  md={24}>
                    <Tabs defaultActiveKey='1'>
                        <Tabs.TabPane tab={t('แกลเลอรี่')} key="1">
                            <div className={styles.gallery} >
                                {/* <ImageGallery items={images} height={500} showPlayButton={false} /> */}
                                <GallaryView items={images} />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('ยูทูป / วิดีโอ')} key="2">
                            <div className={styles.gallery} >
                                <iframe width="100%" height="500" src={`https://www.youtube.com/embed/${data.Youtube}?autoplay=1`}> </iframe> 
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('แปลน')} key="3">
                            <div className={styles.gallery} >
                                {/* <ImageGallery items={planImages} height={500} showPlayButton={false} /> */}
                                <GallaryView items={planImages} />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row justify="center">
                <Col xs={24} sm={24}  md={24}>
                    <Row >
                        <Col xs={24} sm={24}  md={12} >
                            <Header1>{t('ข้อมูลโครงการ')}</Header1>
                            <Row>
                                <Col span={12} >
                                    {t('ประเภท')}
                                </Col> 
                                <Col span={12} >
                                    {locale == 'th'? data.category_Name_TH: data.category_Name_EN}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('ชื่อโครงการ')}
                                </Col> 
                                <Col span={12} >
                                    {locale == 'th'? data.project_Name_TH: data.project_Name_EN}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('ประเภทโครงการ')}
                                </Col> 
                                <Col span={12} >
                                    {locale == 'th'? data.project_type_Name_TH: data.project_type_Name_EN}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('ที่อยู่')}
                                </Col> 
                                <Col span={12} >
                                    { address ?? '' }
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('การถือครอง')}
                                </Col> 
                                <Col span={12} >
                                    {`${projectInformation.Tenure_TH ?? '-'}`}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('ที่จอดรถ')}
                                </Col> 
                                <Col span={12} >
                                    {`${projectInformation.No_of_Parking ?? '-'}`}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('จำนวนอาคาร')}
                                </Col> 
                                <Col span={12} >
                                    {`${projectInformation.No_of_Building ?? '-'} ${t('อาคาร')}`}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('จำนวนชั้น')}
                                </Col> 
                                <Col span={12} >
                                    {`${projectInformation.No_of_Floor_TH ?? '-'} ${t('ชั้น')}`}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('จำนวนห้อง')}
                                </Col> 
                                <Col span={12} >
                                    {`${projectInformation.No_of_Unit ?? '-'} ${t('ยูนิต')}`}
                                </Col> 
                            </Row>
                        </Col>
                        <Col xs={24} sm={24}  md={12}  >
                            <Header1>{t('สำนักงานขาย')}</Header1>
                            <Row>
                                <Col span={12} >
                                    {`${data.Sales_Tel}`}
                                </Col> 
                            </Row>
                            <Row>
                                <Col span={12} >
                                    {t('ที่อยู่')}
                                </Col> 
                                <Col span={12} >
                                    {`${locale == 'th' ? data.Sales_Office_TH : data.Sales_Office_EN}`}
                                </Col> 
                            </Row>
                        </Col> 
                    </Row>
                    <Row>
                        <Header1>{t('ข้อมูลเพิ่มเติม')}</Header1>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div dangerouslySetInnerHTML={{ __html: data.project_Article_TH?.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>{t('ราคาเริ่ม')}</Col>
                        <Col span={12}>{getProjectPricePerUnits()}</Col>
                        <Col span={12}>{t('ราคา ณ วันที่')}</Col>
                        <Col span={12}>{data?.projectInfo && data?.projectInfo[0].Launch_date}</Col>
                        <Col span={12}>{t('คาดว่าจะสร้างขึ้น')}</Col>
                        <Col span={12}>{data?.Built}</Col>
                        <Col span={12}>{t('สัดส่วนถือครอง')}</Col>
                        <Col span={12}>{data?.projectInfo && data?.projectInfo[0].Legal_Ownership_TH}</Col>
                        <Col span={12}>{t('เจ้าของโครงการ')}</Col>
                        <Col span={12}>{data?.Developed_TH}</Col>
                        <Col span={12}>{t('เว็บไซต์โครงการ')}</Col>
                        <Col span={12}>{data?.Project_Website}</Col>
                        <Col span={12}>{t('สำนักงานขาย')}</Col>
                        <Col span={12}>{data?.Sales_Office_TH}</Col>
                        <Col span={12}>{t('สำนักงานขาย')}</Col>
                        <Col span={12}>{data?.Sales_Tel}</Col>
                        <Col span={12}>{t('หมายเหตุ')}</Col>
                        <Col span={12}>{data?.projectInfo && data?.projectInfo[0].Starting_Price}</Col>
                    </Row>
                    {/* <Row>
                        <Header1>{t('สิ่งอำนวยความสะดวก')}</Header1>
                    </Row> */}
                    {
                        Option(t('สิ่งอำนวยความสะดวก'), data?.project_Facilities_TH, locale.toUpperCase())
                    }
                    <Row>
                        <Header1>{t('จุดเด่นโครงการ')}</Header1>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div dangerouslySetInnerHTML={{ __html: data.project_Features_TH?.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                        </Col>
                    </Row>
                   
                    {/* <Row>
                        <Header1>{t('ยูนิตที่ว่าง')}</Header1>
                    </Row> */}
                    <Row>
                        <Header1>{t('map')}</Header1>
                        <Col span={24}> 
                            <LeafletMap lat={data.project_Latitude} lng={data.project_Longitude} label="" /> 
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )

    const ProjectInformation = () => (
        <>
            <Row gutter={[8, 8]}>
                <Col xs={24} sm={24}  md={24} >
                    <Header1>{t('project-info')}</Header1>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('project_id')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {data.project_Code || '-'}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('project-category')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {data[`category_Name_${language}`]}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('project-name')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {projectName}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('project-type')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {data[`project_type_Name_${language}`]}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('address')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            { address ?? '' }
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('Ceiling height')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {`${projectInformation.Unit_Ceiling_Hight ? `${projectInformation.Unit_Ceiling_Hight} ${t('meter')}` : '-'}`} 
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('eia')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {/* {`${projectInformation.eia ? t('approved') : t('not approved')}`} */}
                            {`${t('approved')}`}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('tenure')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {`${projectInformation[`Tenure_${language}`] ?? '-'}`}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('parking')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {`${projectInformation.No_of_Parking ?? '-'}`}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('number-of-building')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {`${projectInformation.No_of_Building ?? '-'} ${t('building-unit')}`}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('number-of-floor')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {`${projectInformation.No_of_Floor_TH ?? '-'} ${t('floor-unit')}`}
                        </Col> 
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation}>
                            {t('number-of-room')}
                        </Col> 
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {`${projectInformation.No_of_Unit ?? '-'} ${t('unit')}`}
                        </Col> 
                    </Row>
                </Col>
            </Row>
            <Row>
                {data.Youtube && 
                    <YouTube videoId={getVideoId(data.Youtube).id || data.Youtube} opts={opts} style={{ width: '100%', aspectRatio: '16/9', margin: '16px 0' }}/>
                }
            </Row>
            <Row>
                <Header1>{t('description')}</Header1>
            </Row>
            <Row>
                <Col span={24}>
                    <div dangerouslySetInnerHTML={{ __html: data[`project_Article_${language}`]?.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                </Col>
            </Row>
            <Row>
                {projectUnitDetail(data[`category_Name_${language}`], data.unit, language, t)}
            </Row>
            <Row>
                <Col span={24}>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('price-start')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {
                                data?.projectInfo && 
                                    `${formatPrice(data?.projectInfo[0].Starting_Price)} ${data?.category_ID === 1 ? `/ ${formatPrice(data?.projectInfo[0].Price_units)} ${t('square meter')}` : ''}`
                            }
                        </Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('price-at-date')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {
                                data?.projectInfo && data?.projectInfo[0].Launch_date
                            }
                        </Col>
                    </Row>
                    <Row className={styles.tableRow}>        
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('expected-completed')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>{data?.Built ? moment(data.Built).format('DD-MM-YYYY') : ''}</Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('quota')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {
                                  data?.projectInfo && data?.projectInfo[0][`Legal_Ownership_${language}`]
                            }
                        </Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('owner-project')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>{data?.[`Developed_${language}`]}</Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('project-website')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={[styles.textLimit, styles.information].join(' ')}>
                            <a href={data?.Project_Website} style={{ color: '#0d6efd', textDecoration: 'underline' }} target="_blank">
                                {data?.Project_Website}
                            </a>
                        </Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('sale-office')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>{data?.[`Sales_Office_${language}`]}</Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('sale-office')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>{data?.Sales_Tel}</Col>
                    </Row>
                    <Row className={styles.tableRow}>
                        <Col xs={{ span: 24 }} md={{ span: 6 }} className={styles.titleInformation} >{t('remark')}</Col>
                        <Col xs={{ span: 24 }} md={{ span: 18 }} className={styles.information}>
                            {
                                  data?.projectInfo && formatPrice(data?.projectInfo[0].Starting_Price)
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Row>
                <Header1>{t('สิ่งอำนวยความสะดวก')}</Header1>
            </Row> */}
            {
                Option(t('project-facilities'), data?.project_Facilities_TH, locale.toUpperCase())
            }
            <Row>
                <Header1>{t('strengths-project')}</Header1>
            </Row>
            <Row>
                <Col span={24}>
                    <div dangerouslySetInnerHTML={{ __html: data[`project_Features_${language}`]?.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                </Col>
            </Row>
            
            {/* <Row>
                <Header1>{t('ยูนิตที่ว่าง')}</Header1>
            </Row> */}
            <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Row>
                            <Header1>{t('map')}</Header1>
                            <Col span={24}> 
                                <LeafletMap lat={data.project_Latitude} lng={data.project_Longitude} label="" /> 
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Row>
                            <Col span={24}>
                                <Header2>{t('nearbyLocation.location')}</Header2>
                            </Col>
                            <Col span={24}>
                                <Tabs defaultActiveKey={'0'} items={nearbyList} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </>
    )

    return (
        <DetailPageLayout
            t = {t}
            breadcrumb={breadcrumb}
            nearbyPropertyList={nearbyPropertyList}
            isMobileFilterOpen={isMobileFilterOpen}
            onMobileSearchOk={onMobileSearchOk}
            onMobileSearchClose={onMobileSearchOk}
            onSellerInfoChanged={onSellerInfoChanged}
            gallerySection={
                    <Tabs defaultActiveKey='1'>
                        <Tabs.TabPane tab={t('gallery')} key="1">
                            <div className={styles.gallery} >
                                {/* <ImageGallery items={images} height={500} showPlayButton={false} /> */}
                                <GallaryView items={images} />
                            </div>
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab={t('video-url')} key="2">
                            <div className={styles.gallery} >
                                <iframe width="100%" height="500" src={`https://www.youtube.com/embed/${data.Youtube}?autoplay=1`}> </iframe> 
                            </div>
                        </Tabs.TabPane> */}
                        <Tabs.TabPane tab={t('Plan')} key="3">
                            <div className={styles.gallery} >
                                {/* <ImageGallery items={planImages} height={500} showPlayButton={false} /> */}
                                <GallaryView items={planImages} />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
            }

            header= {
                [
                    <Row justify="space-between" align="top">
                        <Col xs={24} sm={24}  md={24}>
                            <h1>{projectName}</h1>
                        </Col>
                    </Row>,
                    <Row justify="space-between" align="top">
                        <Col xs={24} sm={24}  md={24}>
                            <h2>
                                {address}
                            </h2>
                        </Col>
                    </Row>,
                    <Row>
                        <Col xs={24} sm={24}  md={8}>
                            <h1>
                                <span>{
                                    `${formatPrice(data.project_Price, locale)}`
                                }
                                </span>
                                {data.category_Name_EN === "Condo" && (
                                    <span className={styles.pricePerMeter}>
                                    {
                                        `${formatPrice(data?.projectInfo 
                                            && data?.projectInfo.length > 0 ? data?.projectInfo[0].Price_units : 0, locale)} / ${t('square meter')}`
                                    }
                                    </span>
                                )}
                                
                            </h1>
                        </Col>
                    </Row>
                ]
            }

            detail= { <ProjectInformation /> }
            ownerInfo = {
                <OwnerInformation 
                    firstName={data[`Developed_${language}`]}
                    lastName={''}
                    agent={data.Project_Website}
                    mobile={data.mobile}
                    userid={data.Developed_ID}
                    lastUpdatedDate={data.upload_at}
                /> 
            }
            actionsTable={actionsTable}
            categoryTable={categoryTable}
            provinceTable={provinceTable}
            locationTypeTable={locationTypeTable}
            data={data}
            actionCateLocationType={undefined} loading={false}
        />
    )
}