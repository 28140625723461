import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Typography,
} from 'antd'
import {
  getAppointedSlots,
  handleAppointmentActivity,
  handleGetAppointmentAvailable,
} from '../../utils/requests'

import {
  AppointmentState,
  defaultAppointmentState,
} from './CardActions'

import { CardMedia } from '@mui/material'
import type { Moment } from 'moment'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getInitialSlots, getUser, getUserSync } from '../../utils/utils'
import { useLocalStorage } from '../../ui-components/hooks/useLocalStorage'
import React from 'react'

const LeafletMapSearch = dynamic(
  () => import('../LeafletMapSearch'),
  { ssr: false },
)

const { Title, Text } = Typography

export const handleLocaleProjectName = ({
  locale,
  project_Name_TH,
  project_Name_EN,
  project_Name_CN,
}: {
  locale: string
  project_Name_TH: string
  project_Name_EN: string
  project_Name_CN: string
}): string => {
  switch (locale) {
    case 'th':
      return project_Name_TH
    case 'en':
      return project_Name_EN
    case 'cn':
      return project_Name_CN
    default:
      return project_Name_TH
  }
}

export interface AppointmentTime {
  dateTimeStart: Moment | null
  dateTimeEnd: Moment | null
}

function _AppointmentActivity({
  setAppointmentState,
  appointmentState,
  type,
  id,
  locale,
  appointmentId,
  imageURL,
  name,
  details,
}: {
  setAppointmentState: Dispatch<SetStateAction<AppointmentState>>
  appointmentState: AppointmentState
  type: string
  id: number
  locale: string
  appointmentId?: number
  imageURL?: string
  name?: string
  details: any
}) {
  const { t } = useTranslation('common')
  const [appointmentTimeState, setAppointmentTimeState] =
    useState<AppointmentTime>({
      dateTimeStart: null,
      dateTimeEnd: null,
    })
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [datePickerLoading, setDatePickerLoading] =
    useState<boolean>(false)
  const [isAvailable, setIsAvailable] = useState<boolean>(true)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [referenceIdState, setReferenceIdState] = useState<string>('')
  const [defaultTimeSlot, setDefaultTimeSlot] = useState<string>('')
  const [userInfo] = useLocalStorage<any>(`9asset.userinfo`);
  const isReloadNeedRef = useRef<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const handleLoginCancelled = () => {
    setButtonLoading(false);
    isReloadNeedRef.current = false;
    window.removeEventListener('login-cancelled', handleLoginCancelled);
    window.removeEventListener('logged-in', handleLoggedIn);
  }

  const handleLoggedIn = async () => {

    console.log(`Logged>>>>`, userInfo);
    const user = userInfo || await getUserSync();
    if (details?.user?.id === user?.id) {
      alert('You cannot contact your own listing')
    } else {
      makeAppointment(user);
    }
    window.removeEventListener('login-cancelled', handleLoginCancelled);
    window.removeEventListener('logged-in', handleLoggedIn);
  }

  const makeAppointment = async (user: any) => {
    
    setAppointmentState({
      ...appointmentState,
      visible: true,
      id: id,
    })

    let initialAppointedSlots = getInitialSlots(moment())

    const response = await getAppointedSlots(
      type,
      id,
      user.id,
      initialAppointedSlots,
    )
    let data = response.data

    let firstAvailableSlot = data.appointedSlots.find(
      (slot: any) => slot.isAvailable === true,
    )
    while (!firstAvailableSlot) {
      initialAppointedSlots = getInitialSlots(
        moment().add(1, 'day'),
      )

      const response = await getAppointedSlots(
        type,
        id,
        user.id,
        initialAppointedSlots,
      )
      data = response.data

      firstAvailableSlot = data.appointedSlots.find(
        (slot: any) => slot.isAvailable === true,
      )
    }

    setAppointmentTimeState({
      ...appointmentTimeState,
      dateTimeStart: moment(firstAvailableSlot?.start || null),
      dateTimeEnd: moment(firstAvailableSlot?.end || null),
    })

    setDefaultTimeSlot(
      `${moment(firstAvailableSlot?.start || null).format(
        'HH:mm',
      )} - ${moment(firstAvailableSlot?.end || null).format(
        'HH:mm',
      )}`,
    )

    setAppointmentState({
      ...appointmentState,
      visible: true,
      type: type === 'project' ? 'project' : 'property',
      id,
      appointedSlots: [...data.appointedSlots],
      project_Latitude: data.project_Latitude,
      project_Longitude: data.project_Longitude,
      project_Zoom: data.project_Zoom,
      project_Name_TH: data.project_Name_TH,
      project_Name_EN: data.project_Name_EN,
      project_Name_CN: data.project_Name_CN,
      appointedXLon: data.project_Longitude,
      appointedYLat: data.project_Latitude,
      appointedVenue: name,
    })

    setButtonLoading(false);
  }

  async function handleOpenAppointment() {
    try {
      setButtonLoading(true);

      const user = userInfo || getUser();
      if (!user?.id) {
        setButtonLoading(true);
        isReloadNeedRef.current = true;
        const loginRequested = new CustomEvent('loginrequested');
        window.addEventListener('logged-in', handleLoggedIn);
        window.addEventListener('login-cancelled', handleLoginCancelled);
        window.dispatchEvent(loginRequested);
        
      } else {
        isReloadNeedRef.current = false;
        makeAppointment(user);
      }

    } catch (error) {
      console.error(error)
      setButtonLoading(false)
    }
  }

  

  async function handleResetSlots({
    current,
    user,
  }: {
    current: Moment | null
    user: any
  }) {
    // todo: get slot time in hour and minute from backend of the selected date
    if (!current) return

    let initialAppointedSlots = getInitialSlots(current)

    const response = await getAppointedSlots(
      type,
      id,
      user.id,
      initialAppointedSlots,
    )
    let data = response.data

    let firstAvailableSlot = data.appointedSlots.find(
      (slot: any) => slot.isAvailable === true,
    )
    while (!firstAvailableSlot) {
      initialAppointedSlots = getInitialSlots(moment().add(1, 'day'))

      const response = await getAppointedSlots(
        type,
        id,
        user.id,
        initialAppointedSlots,
      )
      data = response.data

      firstAvailableSlot = data.appointedSlots.find(
        (slot: any) => slot.isAvailable === true,
      )
    }

    setAppointmentTimeState({
      ...appointmentTimeState,
      dateTimeStart: moment(firstAvailableSlot?.start || null),
      dateTimeEnd: moment(firstAvailableSlot?.end || null),
    })

    setAppointmentState({
      ...appointmentState,
      appointedSlots: [...data.appointedSlots],
    })

    setDefaultTimeSlot(
      `${moment(firstAvailableSlot?.start || null).format(
        'HH:mm',
      )} - ${moment(firstAvailableSlot?.end || null).format(
        'HH:mm',
      )}`,
    )
  }

  useEffect(() => {
    const user = getUser()
    if (!user?.id) return

    handleGetAppointmentAvailable(type, id, user.id).then(
      ({ data }) => {
        setIsAvailable(data?.isAvailable === false ? false : true)
      },
    )
  }, [])

  // hard code for v4 antd date picker,
  // since bodyRender is supported in v5 only
  useEffect(() => {
    const datePicker = document.querySelector(
      '.ant-picker-input',
    ) as HTMLElement
    if (!datePicker) return

    const inputElement = datePicker.childNodes[0] as HTMLInputElement
    if (!inputElement) return

    inputElement.style.textAlign = 'center'
    inputElement.style.fontWeight = 'bold'
    inputElement.style.fontSize = '1.2rem'
    inputElement.style.color = '#fa8c16'
  }, [buttonLoading])

  // hard code for v4 antd Select,
  // since bodyRender is supported in v5 only
  useEffect(() => {
    const selectSlotElement =
      document.querySelector('#select-slots')?.parentNode
        ?.parentNode || null
    if (!selectSlotElement) return

    const optionElement =
      (selectSlotElement.querySelector(
        '.ant-select-selection-item',
      ) as HTMLElement) ||
      (selectSlotElement.querySelector(
        '.ant-select-selection-placeholder',
      ) as HTMLElement)
    if (!optionElement) return

    optionElement.style.textAlign = 'center'
    optionElement.style.fontWeight = 'bold'
    optionElement.style.fontSize = '1.2rem'
    optionElement.style.color = '#fa8c16'
  }, [appointmentTimeState, buttonLoading])

  const onConfirmed = () => {
    setIsConfirmOpen(false);
    const user = getUser()
    if (!user?.id) {
      // push a user to login page
      document.location.href =
        process.env.NEXT_PUBLIC_LOGIN_URL ||
        'https://my.9asset.com/login?redirect=https://my.9asset.com'
    }

    if (
      !appointmentTimeState.dateTimeStart ||
      !appointmentTimeState.dateTimeEnd
    ) {
      return alert('Please select a slot')
    }

    setButtonLoading(true)

    // create new appointment
    handleAppointmentActivity(
      appointmentState.type,
      appointmentState.id,
      user.id,
      appointmentTimeState.dateTimeStart.toDate(),
      appointmentTimeState.dateTimeEnd.toDate(),
      appointmentState.appointedXLon,
      appointmentState.appointedYLat,
      appointmentState.appointedVenue,
    ).then(({ data }) => {
      const { id: appointmentId, referenceId } = data
      const { id } = appointmentState

      setReferenceIdState(referenceId)

      setAppointmentState({
        ...defaultAppointmentState,
        id,
        appointmentId,
        visibleSucceed: true,
        visible: false,
      })

      setIsAvailable(false)

      setAppointmentTimeState({
        dateTimeStart: null,
        dateTimeEnd: null,
      })

      setButtonLoading(false)
    })
  }

  return (
    <>
      <Button
        // style={
        //   isAvailable
        //     ? {
        //         color: 'rgba(0, 0, 0, 0.45)',
        //       }
        //     : {
        //         color: '#d9d9d9',
        //         pointerEvents: 'none',
        //         cursor: 'not-allowed',
        //       }
        // }
        style={{
          color: 'rgba(0, 0, 0, 0.45)',
        }}
        type={'text'}
        size={appointmentId ? 'small' : 'large'}
        shape={appointmentId ? undefined : 'circle'}
        ref={buttonRef}
        loading={buttonLoading}
        icon={
          appointmentId ? (
            <></>
          ) : (
            <FontAwesomeIcon icon={faCalendar} size="lg" />
          )
        }
        onClick={async () => {
          if (details?.user?.id === getUser()?.id) {
            // do not allow user to action themselves
            alert(
              'You cannot create appointment with your own listing',
            )
            return
          }

          // check if already have appointment
          if (isAvailable === true) await handleOpenAppointment()
          else {
            document.location.href =
              process.env.NEXT_PUBLIC_APPOINTMENT_URL ||
              'https://my.9asset.com/buyer/appointment'
          }
        }}
      >
        {appointmentId ? 'Edit' : ''}
      </Button>

      <Modal
        destroyOnClose
        centered
        footer={null}
        width={360}
        key={appointmentState.appointmentId}
        open={appointmentState.visible && appointmentState.id === id}
        onCancel={() => {
          setAppointmentState({
            ...defaultAppointmentState,
          });
          isReloadNeedRef.current && window.location.reload();
        }}
        style={{
          zIndex: 1210,
        }}
      >
        {buttonLoading ? (
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Row justify="space-evenly">
                  {appointmentId ? (
                    <Typography.Text
                      style={{
                        color: '#818181',
                        fontSize: '0.75rem',
                        paddingRight: '0.5rem',
                      }}
                    >{`ID: AP${appointmentId}`}</Typography.Text>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
              <Col span={12}></Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Title
                  style={{
                    fontWeight: 'bold',
                    color: '#FA8C16',
                    marginTop: '0',
                  }}
                  level={3}
                >
                  {t('Schedule to View')}
                </Title>
              </Col>
            </Row>

            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Row
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                  }}
                >
                  <Col span={6}>
                    <CardMedia
                      component="img"
                      sx={{ height: '5rem' }}
                      image={imageURL}
                      alt="Live from space album cover"
                    />
                  </Col>
                  <Col
                    span={18}
                    style={{
                      color: '#333333',
                      padding: '4px',
                    }}
                  >
                    {name}
                  </Col>
                </Row>
              </Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DatePicker
                  key={appointmentState.id}
                  style={{ width: '100%' }}
                  allowClear={false}
                  defaultValue={moment(
                    appointmentTimeState.dateTimeStart,
                  )}
                  onChange={async (current: Moment | null) => {
                    const user = getUser()
                    if (!user?.id) return

                    setDatePickerLoading(true)
                    await handleResetSlots({ current, user })

                    setDatePickerLoading(false)
                  }}
                  disabledDate={(current) => {
                    return current && current < moment().endOf('day')
                  }}
                />
              </Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {datePickerLoading === true ? (
                  <Skeleton
                    active
                    title={false}
                    paragraph={{
                      rows: 1,
                    }}
                  />
                ) : (
                  <Select
                    showSearch={false}
                    id="select-slots"
                    loading={buttonLoading}
                    key={appointmentState.id}
                    style={{ width: '100%' }}
                    placeholder="Select a slot"
                    defaultValue={defaultTimeSlot}
                    onChange={(value: string) => {
                      // get selected slot
                      const selectedSlot =
                        appointmentState.appointedSlots.find(
                          (slot, index) => index === Number(value),
                        )

                      setAppointmentTimeState({
                        ...appointmentTimeState,
                        dateTimeStart: moment(
                          selectedSlot?.start || null,
                        ),
                        dateTimeEnd: moment(
                          selectedSlot?.end || null,
                        ),
                      })
                    }}
                    options={appointmentState.appointedSlots.map(
                      (
                        slot: {
                          start: string
                          end: string
                          isAvailable: boolean
                        },
                        index: number,
                      ) => {
                        const { start, end, isAvailable } = slot
                        const startDate = moment(start)
                        const endDate = moment(end)

                        return {
                          value: `${index}`,
                          label: `${startDate.format(
                            'HH:mm',
                          )} - ${endDate.format('HH:mm')}`,
                          disabled: !isAvailable,
                        }
                      },
                    )}
                  />
                )}
              </Col>

              <Col span={24} key={appointmentState.project_Latitude}>
                <Row
                  align="middle"
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tag
                      color="blue"
                      style={{
                        color: '#0071CB',
                        backgroundColor: '#E6F7FF',
                      }}
                    >
                      <Row justify="center" align="middle">
                        <Col
                          span={24}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          Meet
                        </Col>
                        <Col
                          span={24}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          @
                        </Col>
                      </Row>
                    </Tag>
                  </Col>
                  <Col
                    span={18}
                    style={{
                      color: '#0071CB',
                    }}
                  >
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: false,
                      }}
                      style={{
                        marginBottom: '0',
                      }}
                    >
                      {`${appointmentState.appointedVenue}`}
                    </Typography.Paragraph>
                  </Col>
                </Row>

                <LeafletMapSearch
                  lat={appointmentState.project_Latitude}
                  lng={appointmentState.project_Longitude}
                  label={
                    handleLocaleProjectName({
                      locale,
                      project_Name_TH:
                        appointmentState?.project_Name_TH || '',
                      project_Name_EN:
                        appointmentState?.project_Name_EN || '',
                      project_Name_CN:
                        appointmentState?.project_Name_CN || '',
                    }) || name
                  }
                  appointmentState={appointmentState}
                  setAppointmentState={setAppointmentState}
                  enabledSearch={true}
                />
              </Col>

              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="primary"
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                  onClick={() => {
                    setIsConfirmOpen(true);
                  }}
                >
                  {appointmentId ? t('Reschedule') : t('Send Now')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal>

      <Modal title="Confirmation" open={isConfirmOpen} onOk={onConfirmed} onCancel={() => { setIsConfirmOpen(false);}}>
        <p>Are you sure to create this appointment?'</p>
      </Modal>

      {/* Succeed Modal */}
      <Modal
        destroyOnClose
        centered
        footer={null}
        width={372}
        open={
          appointmentState.visibleSucceed &&
          appointmentState.id === id
        }
        onCancel={() => {
          setAppointmentState({
            ...appointmentState,
            visibleSucceed: false,
          })
        }}
      >
        <Row gutter={[0, 16]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Title
              style={{
                fontSize: '1.4rem',
                fontWeight: 'bold',
                color: '#6cac19',
              }}
            >
              A new apointment time
            </Title>
          </Col>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '-26px',
            }}
          >
            <Text style={{ color: '#6cac19' }}>
              has been sent to seller
            </Text>
          </Col>

          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '26px',
            }}
          >
            <Text
              style={{
                color: '#757575',
                textAlign: 'right',
              }}
            >
              {`Appointment ID : `}
            </Text>
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'left',
              marginTop: '26px',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                color: '#FA8C16',
                textAlign: 'left',
              }}
            >
              {`${referenceIdState}`}
            </Text>
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text style={{ color: '#757575' }}>
              Please wait for seller contact you back soon.
            </Text>
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Space size="small">
            <Button
              type="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setAppointmentState({
                  ...appointmentState,
                  visibleSucceed: false,
                })
                window.location.href = `${window.location.origin}/buyer/appointment`
              }}
            >
              {t('goToBuyerCenter')}
            </Button>
            <Button
              type="default"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setAppointmentState({
                  ...appointmentState,
                  visibleSucceed: false,
                })
                if (isReloadNeedRef.current === true) {
                  window.location.reload();
                }
              }}
            >
              Stay on this page
            </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export const AppointmentActivity = React.memo(_AppointmentActivity);