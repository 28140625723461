// import { useAmp } from 'next/amp';
import Image from 'next/image';
import React from 'react';
import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

enum ImageState {
    zero,
    first,
    second,
    thrid,
    none
}
const defaultStyle ={
    width: "100%",
    borderRadius: 0,
    maxHeight: '200px'
}
const imageLoader = ({ src, width, quality }) => {
    // const imageproxUrl = isDesktop ?
    //     `https://imgproxy-lrq33qbvfq-de.a.run.app/insecure/rs:fit:${width}:240:no:0/plain/gs://9asset-storage/${src}`
    //     : `https://imgproxy-lrq33qbvfq-de.a.run.app/insecure/q:${quality}/rs:fit:${width}:240:no:0/plain/gs://9asset-storage/${src}`
    return src;
}

export const MyImage = (props) => {

    const [imagesrc, setImageSrc] = useState('');
    const [imageStyle0, setImageStyle0] = useState({display: 'inherit'});
    const [imageStyle, setImageStyle] = useState({display: 'inherit'});
    const [imageStyle2, setImageStyle2] = useState({display: 'inherit'});
    const [imageStyle3, setImageStyle3] = useState({display: 'inherit'});
    const [fallback, setfallback] = useState(ImageState.first);

    let { width, height } = props;
    const imageproxUrl = isDesktop ?
        `https://imgproxy-lrq33qbvfq-de.a.run.app/insecure/rs:fit:360:240:no:0/plain/gs://9asset-storage/`
        : `https://imgproxy-lrq33qbvfq-de.a.run.app/insecure/q:20/rs:fit:360:240:no:0/plain/gs://9asset-storage/`

    useEffect(() => {
        props.src &&
        setImageSrc(props.src?.replace('https://cdn.9asset.com/', imageproxUrl) + '@webp');
        // setImageSrc(props.src);
    },[])

    const imageFallback = props.imageurlfallback && props.imageurlfallback?.replace('https://cdn.9asset.com/', imageproxUrl) + '@webp';

    const renderCom = () => {
        if(fallback === ImageState.zero) {
            if(imagesrc) {
                //   console.log('renderCom called: first')
                return  <Image {...props} src={imagesrc.replace('.jpg', '_thumb.jpg')} 
                    style={{...defaultStyle, ...imageStyle0}} 
                    loader={imageLoader}
                    width={width || 360}
                    height={height || 240}
                    alt={props.alt || null}
                    className={props.imagestyle || null}
                    ref={props.myref}
                    draggable="false"
                    quality={75}
                    unoptimized
                    placeholder =  "blur"
                    blurDataURL='/assets/no-image-bg.webp'
                    // priority 
                    // fetchpriority='high'
                    loading = 'eager'
                    onError={function(){
                        setImageStyle0({display: 'none'})
                        setfallback(ImageState.first)
                    }} />
            }
        } else if(fallback === ImageState.first) {
            if(imagesrc) { 
            //   console.log('renderCom called: first')
            return  <Image {...props} src={imagesrc} style={{...defaultStyle, ...imageStyle}} 
                loader={imageLoader}
                width={360}
                height={240}
                alt={props.alt || null}
                className={props.imagestyle || null}
                ref={props.myref}
                draggable="false"
                unoptimized
                // priority 
                // fetchpriority='high'
                quality={75}
                loading = 'eager'
                placeholder =  "blur"
                blurDataURL='/assets/no-image-bg.webp'
                onError={function(){
                    // console.log('renderCom called: first setImageStyle: ', imagesrc,  props.imageurlfallback)
                    if(props.imageurlfallback) {
                        setfallback(ImageState.second)
                        setImageStyle({display: 'none'})
                    } else {
                        setImageStyle({display: 'none'})
                        setfallback(ImageState.thrid)
                    }
                }} />
            }
        } else  if(fallback === ImageState.second) {
            if(imageFallback) {
                // console.log('renderCom called: second')
                return  <Image  key="renderCom-second" {...props} src={imageFallback} style={{...defaultStyle, ...imageStyle2}}
                    loader={imageLoader}
                    width={360}
                    height={240}
                    alt={props.alt || null}
                    className={props.imagestyle || null}
                    ref={props.myref}
                    draggable="false"
                    // fetchpriority='high'
                    quality={75}
                    loading = 'eager'
                    unoptimized
                    placeholder =  "blur"
                    blurDataURL='/assets/no-image-bg.webp'
                    onError={function(){
                        // console.log('renderCom called: second setImageStyle2: ', props.imageurlfallback)
                        setfallback(ImageState.thrid)
                        setImageStyle2({display: 'none'})
                    }} />
            }
        } else  if(fallback === ImageState.thrid) {
            if(imageFallback) {
            // console.log('renderCom called: thrid', props.imageurlfallback.replace('_thumb.', '.'))
                return  <Image key="renderCom-thrid" {...props} src={imageFallback.replace('_thumb.', '.')} style={{...defaultStyle, ...imageStyle3}} 
                    loader={imageLoader}
                    width={360}
                    height={240}
                    alt={props.alt || null}
                    className={props.imagestyle || null}
                    ref={props.myref}
                    draggable="false"
                    // fetchpriority='high'
                    quality={75}
                    loading = 'eager'
                    unoptimized
                    placeholder = "blur"
                    blurDataURL='/assets/no-image-bg.webp'
                    onError={function(){
                        // console.log('renderCom called: thrid setImageStyle3')
                        setfallback(ImageState.none)
                        setImageStyle3({display: 'none'})
                        props.onError && props.onError();
                    }} />
            }
        } 
        // console.log('renderCom called: none')
        // return <div style={{height: "194px"}} {...props} />
        // return <div style={{padding: '30px'}}>
        return <Image key="renderCom-default"  src='/assets/no-image-bg.webp' 
                    alt="no-image"
                    width={360}
                    height={240}
                    quality={75}
                    // fetchpriority='high'
                    className= { props.customNoImageStlye ? "": "renderCom-default-no-image" }
                    loading = 'eager'
                    style={{ 
                        ...defaultStyle, 
                        border: '1px solid #fff', 
                        ...props.customNoImageStlye 
                    }}
                    />
            // </div>
        
    }

    return renderCom(); 
}

export const MyImageImg = (props) => {

    const [imagesrc, setImageSrc] = useState('');
    const [imageStyle0, setImageStyle0] = useState({display: 'inherit'});
    const [imageStyle, setImageStyle] = useState({display: 'inherit'});
    const [imageStyle2, setImageStyle2] = useState({display: 'inherit'});
    const [imageStyle3, setImageStyle3] = useState({display: 'inherit'});
    const [fallback, setfallback] = useState(ImageState.first);

    useEffect(() => {
     setImageSrc(props.src?.replace('https://cdn.9asset.com/', 
        `https://imgproxy-lrq33qbvfq-de.a.run.app/insecure/plain/gs://9asset-storage/`) + '@webp');
    },[])

    const imageFallback = props.imageurlfallback?.replace('https://cdn.9asset.com/', 
            `https://imgproxy-lrq33qbvfq-de.a.run.app/insecure/plain/gs://9asset-storage/`) + '@webp';


    const renderCom = () => {
        if(fallback === ImageState.zero) {
            if(imagesrc) {
                return  <img {...props} src={imagesrc.replace('.jpg', '_thumb.jpg')} style={imageStyle0} 
                    alt={props.alt || null}
                    className={props.imagestyle || null}
                    ref={props.myref}
                    draggable="false"
                    onError={function(){
                        setImageStyle0({display: 'none'})
                        setfallback(ImageState.first)
                    }} />
            }
        } else if(fallback === ImageState.first) {
            if(imagesrc) { 
            return  <img {...props} src={imagesrc} style={imageStyle} 
                alt={props.alt || null}
                className={props.imagestyle || null}
                ref={props.myref}
                draggable="false"
                onError={function(){
                    if(props.imageurlfallback) {
                        setfallback(ImageState.second)
                        setImageStyle({display: 'none'})
                    } else {
                        setImageStyle({display: 'none'})
                        setfallback(ImageState.thrid)
                    }
                }} />
            }
        } else  if(fallback === ImageState.second) {
            if(imageFallback) {
                return  <img  key="renderCom-second" {...props} src={imageFallback} style={imageStyle2} 
                    alt={props.alt || null}
                    className={props.imagestyle || null}
                    ref={props.myref}
                    draggable="false"
                    onError={function(){
                        setfallback(ImageState.thrid)
                        setImageStyle2({display: 'none'})
                    }} />
            }
        } else  if(fallback === ImageState.thrid) {
            if(imageFallback) {
            // console.log('renderCom called: thrid', props.imageurlfallback.replace('_thumb.', '.'))
                return  <img key="renderCom-thrid" {...props} src={imageFallback.replace('_thumb.', '.')} style={imageStyle3} 
                    alt={props.alt || null}
                    className={props.imagestyle || null}
                    ref={props.myref}
                    draggable="false"
                    onError={function(){
                        setfallback(ImageState.none)
                        setImageStyle3({display: 'none'})
                        props.onError && props.onError();
                    }} />
            }
        } 
        return <div {...props} />
    }

    return renderCom(); 
}