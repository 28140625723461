import Link from 'next/link';
import React from 'react';

export interface IBreadCrumbBar {
    breadcrumb: string[];
    locale: any;
    t: any;
}
export interface IBreadCrumbBarCustom {
    breadcrumb: {href: string, name: string}[];
    locale: any;
    t: any;
}

export const BreadcrumbBar = ({breadcrumb, locale, t} : IBreadCrumbBar) => {

    console.log('breadcrumb: ', breadcrumb)

    const  _braedcrumb = breadcrumb.filter( b => {
        return (b !== null) && (b !== 'null')
    });

    return (
        <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0px',  paddingBottom: '10px', flexWrap: 'wrap'}}>
            <div>
                <Link legacyBehavior href={`/`} locale={`${locale}`}>{`${t('app-home')}`}</Link>
                <span style={{paddingLeft: '10px', paddingRight: '10px'}}>/</span>
            </div>
        {
            _braedcrumb.map((l, index) => {
            let href = _braedcrumb.slice(0, index + 1).join('/'); 
            return (
                <div key={index} style={{whiteSpace: 'normal'}}>
                    <Link legacyBehavior href={`/${href}`} locale={`${locale}`} replace={true} >{`${l}`}</Link>
                    {
                        index != _braedcrumb.length - 1 ? 
                        <span style={{paddingLeft: '10px', paddingRight: '10px'}}>/</span> : undefined
                    }
                </div>
            )
            })
        }  
        </div>
    )
}

export const BreadcrumbBarCustom = ({breadcrumb, locale, t} : IBreadCrumbBarCustom) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', paddingTop: '0px',  paddingBottom: '10px', flexWrap: 'wrap'}}>
            <div>
                <Link legacyBehavior href={`/`} locale={`${locale}`}>{`${t('app-home')}`}</Link>
                <span style={{paddingLeft: '10px', paddingRight: '10px'}}>{" > "}</span>
            </div>
        {
            breadcrumb.map((l, index) => {
            return (
                <div key={index} style={{whiteSpace: 'normal'}}>
                    <Link legacyBehavior href={`/${l.href}`} locale={`${locale}`} replace={true} >{`${l.name}`}</Link>
                    {
                        index != breadcrumb.length - 1 ? 
                        <span style={{paddingLeft: '10px', paddingRight: '10px'}}>{" > "}</span> : undefined
                    }
                </div>
            )
            })
        }  
        </div>
    )
}