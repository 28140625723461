import get from 'lodash/get';
import { convertNameToURL } from './utils';

export const cardDataToUrl = (d, action: string, locale: string, t) => {
    let href: string;
    const propertyCategory = get(d.project.category, `name.${locale.toUpperCase()}`)
      || get(d.project.category, `name.TH`);
    
    if(d.property) {
      console.log('category: ', d.project.category,
        get(d.project.category, `name.${locale.toUpperCase()}`, 'name.EN'), 
        locale
      )
      const propertyNameCut = get(d.property, `name.${locale.toUpperCase()}`)
        || get(d.property, 'name.TH')

      href = `/${propertyCategory}/${d.property.id}/${convertNameToURL(propertyNameCut)}`
    } else {
      const projectNameCut = get(d.project, `name.${locale.toUpperCase()}`)
        || get(d.project, `name.TH`)
      href = `/${propertyCategory}/${d.project.id}/${convertNameToURL(projectNameCut)}` 
    }

    if(action.toLowerCase() === 'รวม' 
      || action.toLowerCase() === 'all'
    ) {
      href = `/${t('ขาย')}` + href;
    } else {
      href = `/${action}` + href; 
    }

    if(locale !== 'th') {
      href = `${locale}${href}`;
    }

    // console.log('href: ', href)

    return href;
}