// import { PropertyTypes } from '../components/recommended';
import { level1 } from './keywords';
import { unserialize } from './unserializephp'
import {
    ISearchParameters
} from './requests'
import type { Moment } from 'moment';
import moment from 'moment';
import firebase from './firebaseInit';
export enum PropertyTypes {
    Project, 
    Normal
}


const getToken = async () => await firebase.auth.currentUser?.getIdToken();
const getUserInfo = async () => {
    console.log(firebase)
    const token = await getToken();
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_USER_SERVICE_API_BASE}/users?source=fromFetcher`,
        {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}`}
        }
    );
    return await response.json();
};

export const getUser = () => {
    const user = JSON.parse(localStorage.getItem(`9asset.userinfo`) || '{}',);
    if(user?.id){
        return user;    
    }
    return null;
}

export const getUserSync = () => {
    return new Promise<any|null>(async (resolve) => {
        
        const user = JSON.parse(localStorage.getItem(`9asset.userinfo`) || 'null');
        if(user?.id){
            return resolve(user);    
        }

        let cnt = 0;
        const looper = setInterval(() => {
            const user = JSON.parse(localStorage.getItem(`9asset.userinfo`) || 'null');
            console.log('get user async: '+user);
            if(user?.id){
                clearInterval(looper);
                return resolve(user);    
            }

            cnt++;
            if (cnt > 50) {
                clearInterval(looper);
                return resolve(null);
            }
        }, 200);
    })
}

export const getFieldWithlocale = (obj, fieldname, locale) => {
    // console.log('ddddd: ', obj )
    if(!obj) {
        return '';
    }
    return obj[`${fieldname}_${locale.toUpperCase()}`];
}

export const unSerializePHP = (phpStr) => {

    const phpObj =  unserialize(phpStr);

    console.log(phpObj);
    return phpObj;
}

export const addFacilitiesName = (myfacilities, facilities) => {
    return myfacilities.map((f) => {

        // console.log('fac', f, facilities)
        const dd = facilities.find((d) => d.ID == f.id)  // ? facilities.find((d) => d.ID == f.id) : undefined;
        return {
            ...f,
            Name_EN: dd && dd.Name_EN || '',
            Name_TH: dd && dd.Name_TH || ''
        }
    })
}

export const convertUnitTypeToString = (unit_type, t) => {
    const list = [
        { id: '1br',  desc: t('1 bedroom', { ns : 'home'}) },
        { id: '2br',  desc: t('2 bedroom', { ns : 'home'})},
        { id: '3br',  desc: t('3 bedroom', { ns : 'home'})},
        { id: '4br',  desc: t('4 bedroom', { ns : 'home'})},
        { id: '5br',  desc: t('5 bedroom', { ns : 'home'})},
        { id: 'Commer',  desc: t('1 bedroom', { ns : 'home'})},
        { id: 'Detached',  desc: t('house', { ns : 'home'})},
        { id: 'Duplex',  desc: t('1 bedroom', { ns : 'home'})},
        { id: 'HOffice',  desc: t('1 bedroom', { ns : 'home'})},
        { id: 'HOthers',  desc: t('1 bedroom', { ns : 'home'})},
        { id: 'HTwin',  desc: t('1 bedroom', { ns : 'home'})},
        { id: 'Otherspro',  desc: t('1 bedroom', { ns : 'home'})},
        { id: 'Penthouse',  desc: t('Penthouse', { ns : 'home'})},
        { id: 'Pool',  desc: t('pool', { ns : 'home'})},
        { id: 'Shop',  desc: t('shop', { ns : 'home'})},
        { id: 'Sky',  desc: t('sky', { ns : 'home'})},
        { id: 'studio',  desc: t('studio', { ns : 'home'})},
        { id: 'Townhouse',  desc: t('townhouse', { ns : 'home'})},
        { id: 'Villa',  desc: t('1 bedroom', { ns : 'home'})},
    ]

    try {
        return list.find( l => l.id === unit_type).desc;
    } catch {
        return '-'; 
    }
}



export const convertcategoryTocategoryID = (categoryStr) => {

    let cateCut = categoryStr; 
    level1.map((l) => {
        cateCut = cateCut.replace(l, '');
    })
    if(categoryStr === 'คอนโดโครงการ' || categoryStr === 'Condo') {
        return { categoryid: 1, type: PropertyTypes.Project};
    } else if(cateCut === 'คอนโด' || cateCut === 'Condo') {
        return { categoryid: 1, type: PropertyTypes.Normal};
    } else if(cateCut === 'บ้านเดี่ยว' || cateCut === 'Condo') {
        return { categoryid: 3, type: PropertyTypes.Normal};
    }  
}

export const convertNameToURL = (name) => {

    return name?.replace(/ /g,'-').replace(/\//g, '-');
}

export const formatPrice = (price, locale = 'th', isCurrency = true) => {
    const currency = {
        th: 'บาท',
        en: 'THB',
        cn: '泰铢'
    }
    return `${new Intl.NumberFormat('th-TH').format(price)} ${isCurrency ? currency[locale] : ''}`;
}

export const formatPriceSearchNo00 = (price, lang = 'th') => {
    const d = new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(price);
    return d.replace('.00','')
}


export const formatPriceSearch = (price, lang = 'th') => {
    const d = new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(price);

    if(lang === 'th') {
        return d.replace(',000,000.00', 'ล้าน').replace('.00','')
    }

    return d.replace('.00','')
}

export const formatNumber = (value) => {
    return new Intl.NumberFormat('th-TH').format(value);
}

export const getImageUrl = (property_ID, imageName) => {
    // const cdnUrlFullTemplate = `https://cdn.9asset.com/download/property_gallery/${property_ID}/${name}_full.jpg`
    // const cdnUrlThumbTemplate = `https://cdn.9asset.com/download/property_gallery/100329/property_Images_099999_thumb.jpg`


    const imageNameAndExtension = imageName.split('.')

    return {
        full: `https://cdn.9asset.com/download/property_gallery/${property_ID}/${imageNameAndExtension[0]}.${imageNameAndExtension[1]}`,
        thumb: `https://cdn.9asset.com/download/property_gallery/${property_ID}/${imageNameAndExtension[0]}_thumb.${imageNameAndExtension[1]}`
    }
}
export const getProjectImageUrl = (property_ID, imageName) => {
    const imageNameAndExtension = imageName.split('.')

    console.log('getProjectImageUrl: ', imageName)
    return {
        full: `https://cdn.9asset.com/download/project_gallery/${property_ID}/${imageNameAndExtension[0]}_full.${imageNameAndExtension[1]}`,
        thumb: `https://cdn.9asset.com/download/project_gallery/${property_ID}/${imageNameAndExtension[0]}_thumb.${imageNameAndExtension[1]}`
    }
}
export const getProjectFloorPlanImageUrl = (property_ID, imageName) => {
    const imageNameAndExtension = imageName.split('.')

    console.log('getProjectImageUrl: ', imageName)
    return {
        full: `https://cdn.9asset.com/download/project_floorplan/${property_ID}/${imageNameAndExtension[0]}_full.${imageNameAndExtension[1]}`,
        thumb: `https://cdn.9asset.com/download/project_floorplan/${property_ID}/${imageNameAndExtension[0]}_thumb.${imageNameAndExtension[1]}`
    }
}

export const extractCategory = (level2path) => {
    const catstr = level2path.split('-');
    const postType = catstr[0];
    const category = catstr[1];


}  
export interface ISearchURLLevels {
    action: string;
    category: string;
    locationtype: string;
    location: string;
    location_branch: string;
}

export const convertActionToISearchParameters = (action, 
    categoryObj,
    provinceObj,
    locationTypeObj,
    locationObj,
    amphurObj,
    distinctObj,
    locationBranchObj,
    page,
    URLLevelts: ISearchURLLevels,
    startPrice: number,
    endPrice: number, actionsTable: any, locationtype: string, location: string,
    locationtypeid:number, locationid:number, locationbranchid:number): ISearchParameters => {

    // console.log('convertActionToISearchParameters: ', action, locationObj)


    let sell, rent;
    
    let searchParams: ISearchParameters = {
        startprice: startPrice ? startPrice : 0,
        endprice: endPrice ? endPrice : 10000000000,
        page: page,
        limit: 24,
        locationtypeid: locationtypeid,
        locationid: locationid,
        locationbranchid: locationbranchid || null
    }  

    if(action && action !== 'ประกาศทั้งหมด') {
        if(action === 'โครงการ' || action === 'Project' || action === '项目') {
            searchParams = {
                ...searchParams,
                // isProject: true,
                projectonly: true,
                projectstatus: true,
                actionid: 2
                // presalestatus:'NewCompleted'
            }  
        } else {
            // console.log('aaaaaa: ', actionsTable, action, actionsTable.data.find( a => a.id == 4).action)
            if(actionsTable.data.find( a => a.id == 3)?.action === action) {
                // sell
                sell = true;
                rent = false;
                searchParams = {
                    ...searchParams,
                    actionid: 3,
                    propertystatus: true
                } 
            } else if(actionsTable.data.find( a => a.id == 4)?.action === action) {
                // rent 
                sell = false;
                rent = true;
                searchParams = {
                    ...searchParams,
                    actionid: 4,
                    propertystatus: true
                } 
            } else if(actionsTable.data.find( a => a.id == 5)?.action === action) {
                // Lease
                searchParams = {
                    ...searchParams,
                    actionid: 5,
                    propertystatus: true
                } 
            } else if(actionsTable.data.find( a => a.id == 6)?.action === action) {
                // Mortgage
                searchParams = {
                    ...searchParams,
                    actionid: 6,
                    propertystatus: true
                } 
            } else if(actionsTable.data.find( a => a.id == 7)?.action === action) {
                // Sell-redeem
                searchParams = {
                    ...searchParams,
                    actionid: 7,
                    propertystatus: true
                } 
            }  else {
                // all
                searchParams = {
                    ...searchParams,
                    propertystatus: true
                } 

            }
        }
    } else {
        searchParams = {
            ...searchParams,
            propertystatus: true
        } 
    }

    if(categoryObj 
        && categoryObj.data
        && categoryObj.data.length > 0) {
        // console.log('update categoryid: ', categoryObj.data[0].category_ID)
        searchParams = {
            ...searchParams,
            categoryid: categoryObj.data[0].category_ID
        }
    }

    if(provinceObj 
        && provinceObj.data
        && provinceObj.data.length > 0) {
        searchParams = {
            ...searchParams,
            province: provinceObj.data[0].PROVINCE_NAME_TH 
        }
    }

    if(amphurObj 
        && amphurObj.data
        && amphurObj.data.length > 0) {
        searchParams = {
            ...searchParams,
            amphur: amphurObj.data[0].AMPHUR_NAME_TH
        }
    }

    if(locationObj 
        && locationObj.data
        && locationObj.data.length > 0) {
        searchParams = {
            ...searchParams,
            lat: locationObj.data[0].branch_lat,
            lng: locationObj.data[0].branch_lng
        }
    }

    if(locationtype === 'ชื่อโครงการ' 
        || locationtype === 'Project name' 
        || locationtype === '项目名称') {
        // search by project name
        delete searchParams.propertystatus;
        delete searchParams.projectstatus;

        return {
            ...searchParams, 
            projectname: `${location}`,
            page: page,
            // projectonly: true,
            limit: 24,    
        }
    }

    
    return searchParams;
}
  

export const getAddressFromProperty = (data: any, locale: string) => {
    const Language = locale.toUpperCase();

    if(data) {
        let address = `${data.House_No || ''}`
        address += ` ${data[`Address_${Language}`] || ''}`
        address += ` ${data[`Alley_${Language}`] || ''}`
        address += ` ${data[`Road_${Language}`] || ''}`
        address += ` ${data[`DISTRICT_NAME_${Language}`] || ''}`
        address += ` ${data[`AMPHUR_NAME_${Language}`] || ''}`
        address += ` ${data[`PROVINCE_NAME_${Language}`] || ''}`
        address += ` ${data.POSTCODE || ''}`

        return address.replace(/-/g,'');
    } 

    return undefined;
}

export const formatTextHeader = (action, category, location, countLocation, locale, t) => {
    const groupText = {
        'th': () => {
            switch(action) {
                case 'รวม': 
                    return {
                        h1: `รวมประกาศ${category ?? ''} ${category ?? '' }ใหม่ ${category ?? ''}มือสองราคาถูก`,
                        h2: `แหล่งรวม${category ?? ''}ใหม่ ${category ?? ''}มือสองราคาถูก ${countLocation}+ รายการ  ทุกระดับราคา มีทั้ง${category ?? ''}มือหนึ่ง ${category ?? ''}มือสอง ${category ?? ''}ราคาถูก`
                    }
                case 'ขาย':
                    return {
                        h1: `ขาย${category ?? ''}ใหม่ ขาย${category ?? ''}มือสองราคาถูก`,
                        h2: `แหล่งประกาศขาย${category ?? ''}ใหม่ ขาย${category ?? ''}มือสองราคาถูก ${countLocation}+ รายการ  ทุกระดับราคา มีทั้ง${category ?? ''}มือหนึ่ง ${category ?? ''}มือสอง ${category ?? ''}ราคาถูก`
                    }
                case 'เช่า': 
                    return {
                        h1: `เช่า${category ?? ''}ใหม่ เช่า${category ?? ''}มือสองราคาถูก`,
                        h2: `แหล่งประกาศเช่า${category ?? ''}ใหม่ เช่า${category ?? ''}มือสองราคาถูก ${countLocation}+ รายการ  ทุกระดับราคา มีทั้ง${category ?? ''}มือหนึ่ง ${category ?? ''}มือสอง ${category ?? ''}ราคาถูก`
                    }
                case 'โครงการ': 
                    return {
                        h1: `โครงการ${category ?? ''}ใหม่ โครงการ${category ?? ''}ราคาถูก`,
                        h2: `แหล่งโครงการ${category ?? ''}ใหม่ โครงการ${category ?? ''}ราคาถูก ${countLocation}+ รายการ  ทุกระดับราคา มีทั้ง${category ?? ''}มือหนึ่ง ${category ?? ''}มือสอง ${category ?? ''}ราคาถูก`
                    }
                default:
                    return {
                        h1: `รวมประกาศ${category ?? ''} ${category ?? '' }ใหม่ ${category ?? ''}มือสองราคาถูก`,
                        h2: `แหล่งรวม${category ?? ''}ใหม่ ${category ?? ''}มือสองราคาถูก ${countLocation}+ รายการ  ทุกระดับราคา มีทั้ง${category ?? ''}มือหนึ่ง ${category ?? ''}มือสอง ${category ?? ''}ราคาถูก`
                    } 
            }
        },
        'en': () => {
            switch(action.toLowerCase()) {
                case 'all': 
                    return {
                        h1: `All brand new, resale second hand with cheap price ${category ?? ''}, property listing nationwide.`,
                        h2: `Source of brand new, second hand, cheap price property and ${category ?? ''} ${countLocation} items, all price levels, including first-hand, second-hand and resale property and ${category ?? ''} nationwide.`
                    }
                case 'sell': 
                    return {
                        h1: `Sell brand new, resale second hand with cheap price ${category ?? ''}, property listing nationwide. `,
                        h2: `Source of brand new, second hand, cheap price property and ${category ?? ''} for sales ${countLocation} items, all price levels, including first-hand, second-hand and resale property and ${category ?? ''} nationwide.`
                    }

                case 'rent': 
                    return {
                        h1: `Rent brand new, resale second hand with cheap price ${category ?? ''}, property listing nationwide. `,
                        h2: `Source of brand new, second hand, cheap price property and ${category ?? ''} for rent ${countLocation} items, all price levels, including first-hand, second-hand and resale property and ${category ?? ''} nationwide.`
                    }

                case 'project': 
                    return {
                        h1: `Prime location brand new ${category ?? ''} property project with high quality nationwide. `,
                        h2: `Source of prime location brand new property and ${category ?? ''} project  with high quality ${countLocation} items, all price levels,  fully facilities property and ${category ?? ''} nationwide.`
                    }
                default: 
                    return {
                        h1: `All brand new, resale second hand with cheap price ${category ?? ''}, property listing nationwide.`,
                        h2: `Source of brand new, second hand, cheap price property and ${category ?? ''} ${countLocation} items, all price levels, including first-hand, second-hand and resale property and ${category ?? ''} nationwide.`
                    }   
            }
        },
        'cn': () => {
            switch(action) {
                case '全部': return {
                    h1: `汇集优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源，`,
                    h2: `汇集全国优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源 30,000 物件， 有高中低等价位不动产，${category ?? ''}。`
                }
                case '售':  return {
                    h1: `出售转售优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源，`,
                    h2: `出售转售全国优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源 30,000 物件， 有高中低等价位不动产，${category ?? ''}。`
                }
                case '租': return {
                    h1: `出租优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源，`,
                    h2: `出租转售全国优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源 30,000 物件， 有高中低等价位不动产，${category ?? ''}。`
                }
                case '项目': return {
                    h1: `全新优质不动产，${category ?? ''}项目， 位置好黄金地段， 新开盘项目，新竣工项目，即可入住 ，`,
                    h2: `全新全国优质不动产，${category ?? ''}， 位置好黄金地段， 新竣工项目，即可入住 30,000 个项目， 有高中低等价位不动产，${category ?? ''}项目。`
                }
                default:
                    return {
                        h1: `汇集优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源，`,
                        h2: `汇集全国优质不动产，${category ?? ''}， 位置好黄金地段， 新房源，二手转售${category ?? ''}房源 30,000 物件， 有高中低等价位不动产，${category ?? ''}。`
                    } 
            }
        }
    }
    
    return groupText[locale](action);
}

/**
 * create a new slots array of 1 hour start from 8am to 4pm with {start, end} format
 */
export const getInitialSlots = (current: Moment) => {
    return Array.from({ length: 9 }, (_, i) => i + 8).map((hour) => ({
        start: moment(current).hour(hour).minute(0).second(0).toDate(),
        end: moment(current)
            .hour(hour + 1)
            .minute(0)
            .second(0)
            .toDate(),
    }))
}  

export const formatDistance = (distance: number, locale: string) => {
    if (distance >= 1) {
        return distance.toFixed(1) + " km"
    } else  {
        return (distance * 1000).toFixed(0)  + " m"
    }
}
